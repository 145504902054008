import { Routes } from 'components/Routes'
import React, { useCallback, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import {
  calculateMobileState,
  calculateResponsiveState,
  getAllCurrencies,
  getRegionPolygons,
  saveUTM,
} from 'store/actions/app/creators'
import { SET_IN_MAP_VIEW } from 'store/actions/app/types'
import { getUserSession } from 'store/actions/user/creators'
import {
  allCurrenciesSelector,
  countryCodeSelector,
  getLastCurrencyFetchTimestamp,
  // regionPolygonsSelector,
} from 'store/selectors/app'
import { ThemeProvider } from 'styled-components'
import defaultTheme from 'styles/default-theme'
import GlobalStyle from 'styles/GlobalStyle'
import { debounce, getHoursDiff } from 'utils/helpers'

import ErrorBoundary from '../../pages/ErrorBoundary'
import Layout from '../Layout/Layout'
import ScriptInitializers from '../ScriptInitializers'

const App = () => {
  const allCurrencies = useSelector(allCurrenciesSelector)
  const currentCountry = useSelector(countryCodeSelector)
  const timestamp = useSelector(getLastCurrencyFetchTimestamp)
  const dispatch = useDispatch()
  const { pathname, search } = useLocation()

  const getUser = () => dispatch(getUserSession())

  const calcResponsive = debounce(() => dispatch(calculateResponsiveState()), 100)

  const calcMobile = debounce(() => dispatch(calculateMobileState()), 100)

  const onTabChange = useCallback(() => {
    const now = new Date()
    if (document.visibilityState === 'visible' && getHoursDiff(now, timestamp) > 24) dispatch(getAllCurrencies())
  }, [timestamp, dispatch])

  const getCurrencies = useCallback(() => {
    dispatch(getAllCurrencies())
  }, [dispatch])

  const fetchRegionPolygons = useCallback(
    countryCode => {
      dispatch(getRegionPolygons(countryCode))
    },
    [dispatch]
  )

  // Tries to retrieve current user by sending the corresponding cookie (on application first load)
  useEffect(() => {
    getUser()
    dispatch({ type: SET_IN_MAP_VIEW, payload: false })
  }, []) //eslint-disable-line

  // Gets every currency object on first load for price calculations inside app
  useEffect(() => {
    if (!allCurrencies.length) {
      getCurrencies()
    }
  }, [allCurrencies.length, getCurrencies])

  // Gets current country regions polygons for main-map calculations (determine in which region is current map center)
  useEffect(() => {
    if (currentCountry) {
      fetchRegionPolygons(currentCountry)
    }
  }, [currentCountry, fetchRegionPolygons])

  // Calculates client width on resize using a 100ms debounce for performance and updates redux state accordingly
  useEffect(() => {
    window.addEventListener('resize', calcResponsive)
    calcResponsive()
    return () => window.removeEventListener('resize', calcResponsive)
  }, []) //eslint-disable-line

  useEffect(() => {
    window.addEventListener('resize', calcMobile)
    calcMobile()
    return () => window.removeEventListener('resize', calcMobile)
  }, []) //eslint-disable-line

  useEffect(() => {
    document.addEventListener('visibilitychange', onTabChange)
    return () => document.removeEventListener('visibilitychange', onTabChange)
  }, [onTabChange])

  useEffect(() => {
    const source = new URLSearchParams(search).get('utm_source')
    const medium = new URLSearchParams(search).get('utm_medium')
    const campaign = new URLSearchParams(search).get('utm_campaign')
    dispatch(saveUTM({ source, medium, campaign }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Helmet>
        <link rel="canonical" href={`https://www.goplaceit.com${pathname}`} />
      </Helmet>
      <ThemeProvider theme={defaultTheme}>
        <GlobalStyle />
        <ScriptInitializers>
          <Layout>
            <ErrorBoundary>
              <Routes />
            </ErrorBoundary>
          </Layout>
        </ScriptInitializers>
      </ThemeProvider>
    </>
  )
}

export default App
