import { ReactComponent as Facebook } from 'assets/icons/footer-facebook.svg'
import { ReactComponent as Instagram } from 'assets/icons/footer-instagram.svg'
import { ReactComponent as LinkedIn } from 'assets/icons/footer-linkedin.svg'
import { ReactComponent as Twitter } from 'assets/icons/footer-twitter.svg'
import { ReactComponent as Youtube } from 'assets/icons/footer-youtube.svg'
import { capitalize } from 'utils/string'

export const socialNetworks = [
  {
    href: 'https://www.facebook.com/goplaceit/',
    icon: () => <Facebook />,
    label: 'Facebook',
  },
  {
    href: 'https://www.instagram.com/goplaceit_cl/',
    icon: () => <Instagram />,
    label: 'Instagram',
  },
  {
    href: 'https://www.linkedin.com/company/goplaceit/',
    icon: () => <LinkedIn />,
    label: 'LinkedIn',
  },
  {
    href: 'https://twitter.com/goplaceit',
    icon: () => <Twitter />,
    label: 'Twitter',
  },
  {
    href: 'https://www.youtube.com/channel/UCe338Baj1rUZoSBXfnVEBKA',
    icon: () => <Youtube />,
    label: 'Youtube',
  },
]

export const utilLinks = t => [
  {
    href: {
      pathname: '/mapa',
      search: '?id_modalidad=2&selectedTool=list',
      state: { referrer: window.location.href },
    },
    label: capitalize(t('arrienda')),
  },
  {
    href: {
      pathname: '/mapa',
      search: '?id_modalidad=1&selectedTool=list',
      state: { referrer: window.location.href },
    },
    label: capitalize(t('compra')),
  },
  {
    href: '/publicar',
    label: capitalize(t('publica')),
  },
]

export const enterpriseLinks = [
  { href: '/acerca-de-goplaceit', label: 'Sobre nosotros' },
  { href: '/terminos-y-condiciones', label: 'Términos y condiciones' },
  { href: '/terminos-y-condiciones/privacidad', label: 'Politicas de privacidad' },
  {
    href: 'https://flatfy.com/',
    label: 'Asociados con Flatfy',
    rel: 'noreferrer',
    external: true,
    showIn: ['cl'],
  },
]

export const resourcesLinks = [
  {
    href: 'https://medium.com/goplaceit-datos',
    label: 'Nuestro blog',
    external: true,
    rel: 'noreferrer',
  },
  {
    href: 'https://medium.com/goplaceit-datos/educacion/home',
    label: 'Aprende con nosotros',
    external: true,
    rel: 'noreferrer',
  },
  {
    href: '/publicar/preguntas-frecuentes',
    label: 'Dudas sobre planes',
    showIn: 'cl',
  },
  {
    href: '/preguntas-frecuentes',
    label: 'Preguntas frecuentes',
  },
]

export const countryLinks = (countryCode, t) => {
  switch (countryCode) {
    case 'ar':
      return [
        {
          href: `/${t('departamento')}/venta/localidad/7324-mar-del-plata`,
          label: `Venta ${t('departamento')} Mar del Plata`,
        },
        {
          href: `/${t('departamento')}/venta/provincia/77-capital-federal`,
          label: `Venta ${t('departamento')} Capital Federal`,
        },
        {
          href: `/${t('departamento')}/venta/localidad/10322-rosario`,
          label: `Venta ${t('departamento')} Rosario`,
        },
        {
          href: `/${t('departamento')}/venta/localidad/7440-la-plata`,
          label: `Venta ${t('departamento')} La Plata`,
        },
        {
          href: `/${t('departamento')}/${t('arriendo')}/localidad/7324-mar-del-plata`,
          label: `${capitalize(t('arriendo'))} ${t('departamento')} Mar del Plata`,
        },
        {
          href: `/${t('departamento')}/${t('arriendo')}/provincia/72-buenos-aires`,
          label: `${capitalize(t('arriendo'))} ${t('departamento')} Capital Federal`,
        },
        {
          href: `/${t('departamento')}/${t('arriendo')}/localidad/10322-rosario`,
          label: `${capitalize(t('arriendo'))} ${t('departamento')} Rosario`,
        },
        {
          href: `/${t('departamento')}/${t('arriendo')}/localidad/7440-la-plata`,
          label: `${capitalize(t('arriendo'))} ${t('departamento')} La Plata`,
        },
      ]
    case 'cl':
      return [
        {
          href: `/${t('departamento')}/${t('arriendo')}/comuna/297-providencia`,
          label: `${capitalize(t('arriendo'))} ${t('departamento')} Providencia`,
        },
        {
          href: `/${t('departamento')}/${t('arriendo')}/comuna/141-concepcion`,
          label: `${capitalize(t('arriendo'))} ${t('departamento')} Concepción`,
        },
        {
          href: `/${t('departamento')}/${t('arriendo')}/comuna/294-nunoa`,
          label: `${capitalize(t('arriendo'))} ${t('departamento')} Nuñoa`,
        },
        {
          href: `/${t('departamento')}/${t('arriendo')}/comuna/275-santiago`,
          label: `${capitalize(t('arriendo'))} ${t('departamento')} Santiago`,
        },
        {
          href: `/casa/${t('arriendo')}/comuna/284-la-florida`,
          label: `${capitalize(t('arriendo'))} casa La Florida`,
        },
        {
          href: `/casa/${t('arriendo')}/comuna/293-maipu`,
          label: `${capitalize(t('arriendo'))} casa Maipú`,
        },
        {
          href: `/casa/${t('arriendo')}/comuna/141-concepcion`,
          label: `${capitalize(t('arriendo'))} casa Concepción`,
        },
        {
          href: `/${t('departamento')}/${t('arriendo')}/comuna/7-antofagasta`,
          label: `${capitalize(t('arriendo'))} ${t('departamento')} Antofagasta`,
        },
      ]
    case 'pe':
      return [
        {
          href: `/${t('departamento')}/venta/departamento/131-arequipa`,
          label: `Venta ${t('departamento')} Arequipa`,
        },
        {
          href: `/${t('departamento')}/${t('arriendo')}/departamento/142-lima`,
          label: `Venta ${t('departamento')} Lima`,
        },
        {
          href: `/${t('departamento')}/venta/distrito/373294-trujillo`,
          label: `Venta ${t('departamento')} Trujillo`,
        },
        {
          href: `/${t('departamento')}/venta/distrito/373436-miraflores`,
          label: `Venta ${t('departamento')} Miraflores`,
        },
        {
          href: `/${t('departamento')}/${t('arriendo')}/distrito/373294-trujillo`,
          label: `${capitalize(t('arriendo'))} ${t('departamento')} Trujillo`,
        },
        {
          href: `/${t('departamento')}/${t('arriendo')}/distrito/373415-lima`,
          label: `${capitalize(t('arriendo'))} ${t('departamento')} Lima`,
        },
        {
          href: `/${t('departamento')}/${t('arriendo')}/distrito/373450-san-miguel`,
          label: `${capitalize(t('arriendo'))} ${t('departamento')} San Miguel`,
        },
        {
          href: `/${t('departamento')}/${t('arriendo')}/departamento/131-arequipa`,
          label: `${capitalize(t('arriendo'))} ${t('departamento')} Arequipa`,
        },
      ]
    case 'co':
      return [
        {
          href: `/${t('departamento')}/venta/localidad/722241-barranquilla`,
          label: `Venta ${t('departamento')} Barranquilla`,
        },
        {
          href: `/${t('departamento')}/venta/localidad/722953-cali`,
          label: `Venta ${t('departamento')} Cali`,
        },
        {
          href: `/${t('departamento')}/venta/municipio/6705-bogota`,
          label: `Venta ${t('departamento')} Bogota`,
        },
        {
          href: `/${t('departamento')}/venta/localidad/722954-medellin`,
          label: `Venta ${t('departamento')} Medellin`,
        },
        {
          href: `/${t('departamento')}/${t('arriendo')}/localidad/6007-bucaramanga`,
          label: `${capitalize(t('arriendo'))} ${t('departamento')} Bucaramanga`,
        },
        {
          href: `/${t('departamento')}/${t('arriendo')}/localidad/722953-cali`,
          label: `${capitalize(t('arriendo'))} ${t('departamento')} Cali`,
        },
        {
          href: `/${t('departamento')}/${t('arriendo')}/localidad/722954-medellin`,
          label: `${capitalize(t('arriendo'))} ${t('departamento')} Medellin`,
        },
        {
          href: `/${t('departamento')}/${t('arriendo')}/municipio/6705-bogota`,
          label: `${capitalize(t('arriendo'))} ${t('departamento')} Bogota`,
        },
      ]
    case 'mx':
      return [
        {
          href: `/${t('departamento')}/venta/colonia/496590-guadalajara`,
          label: `Venta ${t('departamento')} Guadalajara`,
        },
        {
          href: `/${t('departamento')}/venta/colonia/543612-monterrey`,
          label: `Venta ${t('departamento')} Monterrey`,
        },
        {
          href: `/${t('departamento')}/venta/estado/216-puebla`,
          label: `Venta ${t('departamento')} Puebla`,
        },
        {
          href: `/${t('departamento')}/venta/estado/204-distrito-federal`,
          label: `Venta ${t('departamento')} CDMX`,
        },
        {
          href: `/${t('departamento')}/${t('arriendo')}/estado/217-queretaro`,
          label: `${capitalize(t('arriendo'))} ${t('departamento')} Queretaro`,
        },
        {
          href: `/${t('departamento')}/${t('arriendo')}/estado/216-puebla`,
          label: `${capitalize(t('arriendo'))} ${t('departamento')} Puebla`,
        },
        {
          href: `/${t('departamento')}/${t('arriendo')}/estado/217-queretaro`,
          label: `${capitalize(t('arriendo'))} ${t('departamento')} Queretaro`,
        },
        {
          href: `/${t('departamento')}/${t('arriendo')}/colonia/496590-guadalajara`,
          label: `${capitalize(t('arriendo'))} ${t('departamento')} Guadalajara`,
        },
      ]
    case 'uy':
      return [
        {
          href: `/${t('departamento')}/venta/colonia/375173-piriapolis`,
          label: `Venta ${t('departamento')} Piriapolis`,
        },
        {
          href: `/${t('departamento')}/venta/colonia/721885-montevideo`,
          label: `Venta ${t('departamento')} Montevideo`,
        },
        {
          href: `/${t('departamento')}/venta/colonia/375174-punta-del-este`,
          label: `Venta ${t('departamento')} Punta del Este`,
        },
        {
          href: `/${t('departamento')}/venta/departamento/185-maldonado`,
          label: `Venta ${t('departamento')} Maldonado`,
        },
        {
          href: `/${t('departamento')}/${t('arriendo')}/colonia/375174-punta-del-este`,
          label: `${capitalize(t('arriendo'))} ${t('departamento')} Punta del Este`,
        },
        {
          href: `/${t('departamento')}/${t('arriendo')}/departamento/185-maldonado`,
          label: `${capitalize(t('arriendo'))} ${t('departamento')} Maldonado`,
        },
        {
          href: `/${t('departamento')}/${t('arriendo')}/colonia/721885-montevideo`,
          label: `${capitalize(t('arriendo'))} ${t('departamento')} Montevideo`,
        },
        {
          href: `/${t('departamento')}/${t('arriendo')}/colonia/375173-piriapolis`,
          label: `${capitalize(t('arriendo'))} ${t('departamento')} Piriapolis`,
        },
      ]

    default:
      return []
  }
}
