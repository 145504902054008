import { createAsyncTypes } from '../utils'

// property types
export const getPropertyTypes = createAsyncTypes('GET_PROPERTY')
export const getLayerDataTypes = createAsyncTypes('GET_LAYER_DATA')
export const getMarkersDataTypes = createAsyncTypes('GET_MARKERS_DATA')
export const getFavoriteDataTypes = createAsyncTypes('TOGGLE_FAVORITE')
export const getContactMeTypes = createAsyncTypes('CONTACT_ME')
export const SET_MODAL_SLIDER_INDEX = 'SET_MODAL_SLIDER_INDEX'
export const UPDATE_PROPERTY_NOTE = 'UPDATE_PROPERTY_NOTE'
export const SET_TAKEN_ACTIONS = 'SET_TAKEN_ACTIONS'
export const SET_HAS_CONTACT = 'SET_HAS_CONTACT'
export const SET_HAS_FAVORITE = 'SET_FAVORITE'
export const CLEAN_PROPERTY_DATA = 'CLEAN_PROPERTY_DATA'
export const SET_CONTACTED_ID = 'SET_CONTACTED_ID'
