import styled from 'styled-components'

export const Container = styled.div`
  height: 80vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
`

export const Wrapper = styled.div`
  > div:first-child {
    display: ${({ ready }) => (!ready ? 'flex' : 'none')};
  }

  > div:last-child {
    ${({ ready }) => (ready ? '' : 'display: none;')};
  }
`
