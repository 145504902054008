import fallbackImage from 'assets/images/gpi-missing-image.png'
import styled from 'styled-components'

import { TABLET_RESPONSIVE_BREAK } from './constants'
import { featureIdToIconName, featureIdToLabel, nonFeatureKeyToIconName, publicationTypes } from './dictionaries'
import { createUrl, urlCleanString } from './routes'

const ImageWrapper = styled.div`
  align-items: center;
  background-color: ${({ theme: { color } }) => color('night')};
  display: inline-flex;
  justify-content: center;
  max-height: 100%;
  min-width: 100%;
  overflow: hidden;
  text-align: center;
  width: 100%;
  ${({ isProject }) =>
    isProject &&
    `
    background-color: ${({ theme: { color } }) => color('white')};
  `}
  > img {
    width: 100%;
    height: 475px;
    object-fit: contain;
  }
  @media (max-width: ${TABLET_RESPONSIVE_BREAK}) {
    width: 100%;
    min-width: 100%;
    height: 100%;
    border-radius: 10px;
  }
`

export const extractFeatures = featuresCategories => featuresCategories ? featuresCategories.reduce((initial, cat) => [...initial, ...cat.features], []) : []

const convertFeaturesListToObject = features => {
  const result = {}
  features.forEach(f => {
    result[f.id] = f
  })
  return result
}

const prioritiesByModeApartment = {
  arriendo: ['byOwner', 'withFurniture', 'floor', 'orientation', 23, 'year', 1, 5],
  venta: ['byOwner', 'orientation', 'floor', 23, 1, 17, 22, 'withFurniture', 'year', 5],
}

const prioritiesByMode = {
  arriendo: ['byOwner', 'withFurniture', 'floor', 'orientation', 23, 'year', 1, 5, 'storage'],
  venta: ['byOwner', 'orientation', 'floor', 23, 1, 17, 22, 'withFurniture', 'year', 5, 'storage'],
}

const keyToIcon = { ...featureIdToIconName, ...nonFeatureKeyToIconName }

const processBooleanValue = ({ key, value, label, onlyTrue }) => {
  if (onlyTrue && value === false) return null
  if (value !== null) {
    return {
      label: label(value),
      iconPath: keyToIcon[key],
      value,
      isBoolean: true,
    }
  } else {
    return null
  }
}

const processNumericValue = ({ key, value, label, onlyTrue }) => {
  if (onlyTrue && value === 0) return null
  if (value !== null) {
    return {
      label: label(value),
      iconPath: keyToIcon[key],
      value,
      isBoolean: false,
    }
  } else {
    return null
  }
}

const processStringValue = ({ key, value, label }) => {
  if (value !== null) {
    return {
      label: label(value),
      iconPath: keyToIcon[key],
      value,
      isBoolean: false,
    }
  } else {
    return null
  }
}

const createBooleanCalculationsObject = idToName => {
  const ids = Object.keys(idToName)
  const result = {}
  ids.forEach(id => {
    result[id] = {
      type: 'boolean',
      key: id,
      label: () => idToName[id],
    }
  })
  return result
}

const processMethodsByType = {
  boolean: processBooleanValue,
  numeric: processNumericValue,
  string: processStringValue,
  custom: null,
}

const booleanCalculations = createBooleanCalculationsObject(featureIdToLabel)

const featuresCalculations = {
  byOwner: {
    type: 'boolean',
    key: 'byOwner',
    label: () => 'Dueño directo',
  },
  parkings: {
    type: 'numeric',
    key: 'parkings',
    label: n => `Estacionamiento${n > 1 ? 's' : ''}`,
  },
  storage: {
    type: 'boolean',
    key: 'storage',
    label: () => 'Bodega',
  },
  rentPerDay: {
    type: 'boolean',
    key: 'rentPerDay',
    label: v => `Arriendo ${v ? 'diario' : 'mensual'}`,
  },
  withFurniture: {
    type: 'boolean',
    key: 'withFurniture',
    label: () => 'Amoblado',
  },
  floor: {
    type: 'numeric',
    key: 'floor',
    label: () => 'Piso',
  },
  orientation: {
    type: 'string',
    key: 'orientation',
    label: () => 'Orientación',
  },
  year: {
    type: 'numeric',
    key: 'year',
    label: () => 'Año de construcción',
  },
  ...booleanCalculations,
}

const extractRemainingPriorities = (priorities, remainingToAdd) => {
  const numericKeys = []
  priorities.forEach(key => typeof key === 'number' && numericKeys.push(key))
  const featuresKeys = Object.keys(remainingToAdd)
  const result = []
  featuresKeys.forEach(k => {
    if (!numericKeys.includes(parseInt(k))) {
      result.push(parseInt(k))
    }
  })
  return result
}

const extractFeatValue = (obj, key, type) => {
  if (typeof key === 'number') return obj[key].value
  switch (type) {
    case 'boolean':
      return Boolean(obj[key])
    default:
      return obj[key]
  }
}

export const priorizeFeatures = ({ data, withLimit, includeFalse = false, sortTruesFirst = false }) => {
  const result = []
  let counter = 0
  const featuresList = extractFeatures(data.featuresCategories)
  const featuresObj = convertFeaturesListToObject(featuresList)
  const combinedObj = { ...featuresObj, ...data }
  const priorities =
    data.type.id === 2
      ? prioritiesByModeApartment[publicationTypes[data.mode.id]]
      : prioritiesByMode[publicationTypes[data.mode.id]]
  const allPriorities = [...priorities, ...extractRemainingPriorities(priorities, booleanCalculations)]

  allPriorities.forEach(key => {
    if (withLimit && counter === withLimit) return
    if (!combinedObj[key]) return
    const calculator = featuresCalculations[key]
    const processer = processMethodsByType[calculator.type]
    const processedFeature = processer
      ? processer({
          key: key,
          value: extractFeatValue(combinedObj, key, calculator.type),
          label: calculator.label,
          onlyTrue: !includeFalse,
        })
      : null
    if (processedFeature) {
      result.push(processedFeature)
      counter++
    }
  })
  return sortTruesFirst ? result.sort((a, b) => b.value - a.value) : result
}

const AltTextArray = (type, mode) => {
  if (mode === 1) return [`${type} en venta`, `${type}s en venta`, `${type}s en venta usados`]
  else return [`arriendo ${type}s`, `${type}s en arriendo`, `arriendo de ${type}s`]
}

export const wrapImagesForCarousel = (
  images,
  { commune, name, type, mode } = {},
  isProject = true,
  forPreview = false
) => images.map((image, i) => {
    const loadErrorHandler = e => {
      e.persist()
      e.target.setAttribute('src', fallbackImage)
    }

    return (
      <ImageWrapper isProject={isProject} image={image.id} key={`img${i}`}>
        <img
          loading="lazy"
          src={forPreview ? image : image['image_960x720']}
          alt={
            !forPreview
              ? isProject
                ? `Proyecto ${name}, ${commune.name}`
                : AltTextArray(type.name.toLowerCase(), mode.id)[i % 3]
              : 'preview-image'
          }
          className="carousel-image"
          onError={loadErrorHandler}
        />
      </ImageWrapper>
    )
  })

const processSingleProjectFeature = (from, to) => {
  if (!from) return { from: '', to: '' }
  if (from === to) return { from, to: '' }
  return { from, to: ` - ${to}` }
}

export const extractBedsBathsAndSizeForProject = ({ bedsFrom, bedsTo, bathsFrom, bathsTo, sizeFrom, sizeTo }) => ({
    beds: processSingleProjectFeature(bedsFrom, bedsTo),
    baths: processSingleProjectFeature(bathsFrom, bathsTo),
    size: processSingleProjectFeature(sizeFrom, sizeTo),
  })

export const parameterizePropertyId = (id, title) => encodeURIComponent(
    `${[
      id,
      ...urlCleanString(title)
        .split(',')[0]
        .replace(/[^\w\s]/g, '')
        .split(' ')
        .filter(Boolean),
    ]
      .join('-')
      .toLowerCase()}`
  )

export const addTrovitTrack = listener => () => {
  window.ta('send', 'lead')
  listener()
}

export const findLocationToRedirect = ({ commune, country, mode, region, type }) => {
  if (commune && commune.id) {
    return createUrl('locationLanding', {
      typeID: type.id,
      modeID: mode.id,
      communeID: commune.id,
      commune: commune.name,
      countryID: country.id,
      locationType: 'commune',
    })
  }
  if (region && region.id) {
    return createUrl('locationLanding', {
      modeID: mode.id,
      typeID: type.id,
      commune: region.name,
      countryID: country.id,
      communeID: region.id,
      locationType: 'region',
    })
  }
  if (country && country.id) {
    return createUrl('countryLanding', {
      modeID: mode.id,
      typeID: type.id,
      countryID: country.id,
    })
  }
}
