import {
  CLEAN_PROPERTY_DATA,
  getContactMeTypes,
  getFavoriteDataTypes,
  getLayerDataTypes,
  getMarkersDataTypes,
  getPropertyTypes,
  SET_HAS_CONTACT,
  SET_HAS_FAVORITE,
  SET_MODAL_SLIDER_INDEX,
  SET_TAKEN_ACTIONS,
  UPDATE_PROPERTY_NOTE,
} from '../actions/property/types'

const [GET_PROPERTY_REQUEST, GET_PROPERTY_SUCCESS, GET_PROPERTY_REJECT] = getPropertyTypes
const [GET_LAYER_DATA_REQUEST, GET_LAYER_DATA_SUCCESS, GET_LAYER_DATA_REJECT] = getLayerDataTypes
const [GET_MARKERS_DATA_REQUEST, GET_MARKERS_DATA_SUCCESS, GET_MARKERS_DATA_REJECT] = getMarkersDataTypes
const [TOGGLE_FAVORITE_REQUEST, TOGGLE_FAVORITE_SUCCESS, TOGGLE_FAVORITE_REJECT] = getFavoriteDataTypes
const [CONTACT_ME_REQUEST, CONTACT_ME_SUCCESS, CONTACT_ME_REJECT] = getContactMeTypes

const initialState = {
  [GET_PROPERTY_REQUEST]: false,
  [GET_PROPERTY_SUCCESS]: false,
  [GET_PROPERTY_REJECT]: false,
  [GET_LAYER_DATA_REQUEST]: false,
  [GET_LAYER_DATA_SUCCESS]: false,
  [GET_LAYER_DATA_REJECT]: false,
  [GET_MARKERS_DATA_REQUEST]: false,
  [GET_MARKERS_DATA_SUCCESS]: false,
  [GET_MARKERS_DATA_REJECT]: false,
  [TOGGLE_FAVORITE_REQUEST]: false,
  [TOGGLE_FAVORITE_SUCCESS]: false,
  [TOGGLE_FAVORITE_REJECT]: false,
  [CONTACT_ME_REQUEST]: false,
  [CONTACT_ME_SUCCESS]: false,
  [CONTACT_ME_REJECT]: false,
  modalSliderIndex: 0,
  data: {
    type: {},
    mode: {},
    currency: {},
    created_at: '10-10-19',
    property_images: [],
    property_notes: [],
    country: {},
  },
  dataLayer: [],
  markersLayer: [],
  similarProperties: { properties: [], projects: [] },
  accessibility: { servicios: [], consumo: [] },
  importantFeatures: [],
  neighborhoodData: {
    Tiempo: {},
    Superficie: {},
    Cantidad: {},
    UF_promedio: {},
    UF_min: {},
    UF_max: {},
    Entorno: [],
  },
  takenActions: { has_contact: true, has_favorite: false, property_notes: [] },
}

const property = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROPERTY_REQUEST:
      return { ...state, [action.type]: true }
    case GET_PROPERTY_SUCCESS:
      return {
        ...state,
        [action.type]: true,
        data: action.payload,
        GET_PROPERTY_REQUEST: false,
      }
    case GET_PROPERTY_REJECT:
      return {
        ...state,
        [action.type]: true,
        error: action.error,
        GET_PROPERTY_REQUEST: false,
      }
    case GET_LAYER_DATA_REQUEST:
      return { ...state, [action.type]: true }
    case GET_LAYER_DATA_SUCCESS:
      return {
        ...state,
        [action.type]: true,
        dataLayer: action.payload,
        GET_LAYER_DATA_REQUEST: false,
      }
    case GET_LAYER_DATA_REJECT:
      return {
        ...state,
        [action.type]: true,
        error: action.error,
        GET_LAYER_DATA_REQUEST: false,
      }
    case SET_MODAL_SLIDER_INDEX:
      return {
        ...state,
        modalSliderIndex: action.payload,
      }
    case GET_MARKERS_DATA_REQUEST:
      return { ...state, [action.type]: true }
    case GET_MARKERS_DATA_SUCCESS:
      return {
        ...state,
        [action.type]: true,
        markersLayer: action.payload,
        GET_MARKERS_DATA_REQUEST: false,
      }
    case GET_MARKERS_DATA_REJECT:
      return {
        ...state,
        [action.type]: true,
        error: action.error,
        GET_MARKERS_DATA_REQUEST: false,
      }
    case TOGGLE_FAVORITE_REQUEST:
      return { ...state, [action.type]: true }
    case TOGGLE_FAVORITE_SUCCESS:
      return {
        ...state,
        [action.type]: true,
        TOGGLE_FAVORITE_REQUEST: false,
      }
    case TOGGLE_FAVORITE_REJECT:
      return {
        ...state,
        [action.type]: true,
        error: action.error,
        TOGGLE_FAVORITE_REQUEST: false,
      }
    case CONTACT_ME_REQUEST:
      return { ...state, [action.type]: true }
    case CONTACT_ME_SUCCESS:
      return {
        ...state,
        [action.type]: true,
        CONTACT_ME_REQUEST: false,
      }
    case CONTACT_ME_REJECT:
      return {
        ...state,
        [action.type]: true,
        error: action.error,
        CONTACT_ME_REQUEST: false,
      }
    case 'SET_FIELD':
      return {
        ...state,
        [action.field]: action.payload,
      }
    case UPDATE_PROPERTY_NOTE:
      return {
        ...state,
        takenActions: {
          ...state.takenActions,
          property_notes: [action.payload],
        },
      }
    case SET_TAKEN_ACTIONS:
      return {
        ...state,
        takenActions: action.payload,
      }
    case SET_HAS_CONTACT:
      return {
        ...state,
        takenActions: { ...state.takenActions, has_contact: true },
      }
    case SET_HAS_FAVORITE:
      return {
        ...state,
        takenActions: { ...state.takenActions, has_favorite: action.payload },
      }
    case CLEAN_PROPERTY_DATA:
      return initialState
    default:
      return state
  }
}

export default property
