import { createSelector } from 'reselect'

export const getResponsiveState = createSelector(
  state => state.app.isResponsive,
  isResponsive => isResponsive
)

export const getMobileState = createSelector(
  state => state.app.isMobile,
  isMobile => isMobile
)

export const getWillPrint = createSelector(
  state => state.app.willPrint,
  id => id
)

export const getInMapView = createSelector(
  state => state.app.inMapView,
  inMapView => inMapView
)

export const countryCodeSelector = createSelector(
  state => state.app.country,
  code => code
)

export const allCurrenciesRequestStatusSelector = createSelector(
  state => state.app.GET_ALL_CURRENCIES_REQUEST,
  state => state.app.GET_ALL_CURRENCIES_SUCCESS,
  state => state.app.GET_ALL_CURRENCIES_REJECT,
  (req, suc, rej) => ({ req, suc, rej })
)

export const allCurrenciesSelector = createSelector(
  state => state.app.currencies,
  currencies => currencies
)

export const regionPolygonsSelector = createSelector(
  state => state.app.regionPolygons,
  polygons => polygons
)

export const navigateToSelector = createSelector(
  state => state.app.navigateTo,
  to => to
)

export const getLastCurrencyFetchTimestamp = createSelector(
  state => state.app.timestamp,
  timestamp => timestamp
)

export const getUTM = createSelector(
  state => state.app.utm,
  utm => utm
)
