import { createAsyncTypes } from '../utils'

export const getPropertyListTypes = createAsyncTypes('GET_MAIN_MAP_PROPERTY_LIST')
export const getPropertyMarkersTypes = createAsyncTypes('GET_MAIN_MAP_PROPERTY_MARKERS')
export const getLayerDataTypes = createAsyncTypes('GET_MAIN_MAP_LAYER_DATA')
export const getServicesDataTypes = createAsyncTypes('GET_MAIN_MAP_SERVICES')
export const RESET_MARKERS = 'RESET_MARKERS'
export const CLEAR_SERVICES = 'CLEAR_SERVICES'
export const SET_USER_ZONES = 'SET_USER_ZONES'
export const getAvailableLayersTypes = createAsyncTypes('GET_AVAILABLE_LAYERS')
