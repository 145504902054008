import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Box, Text } from '@chakra-ui/react'
import { ChevronLeft } from 'goplaceit-icons'
import React, { useState } from 'react'

import { CollapseWrapper } from './Collapse.Elements'

export const Collapse = ({ children, title, css, wrapperStyle, bordered, ...props }) => {
  const [isOpen, setIsOpen] = useState(false)
  const handleChange = e => {
    if (e === 0) setIsOpen(true)
    else setIsOpen(false)
  }
  return (
    <CollapseWrapper wrapperStyle={wrapperStyle}>
      <Accordion
        _active
        allowToggle
        onChange={handleChange}
        css={css}
        {...(bordered && { variant: 'bordered' })}
        {...props.accordionProps}>
        <AccordionItem {...props.accordionItemProps}>
          <AccordionButton {...props.accordionButtonProps}>
            <Text flex="1" textAlign="left">
              {title}
            </Text>
            <Box
              css={{
                transform: isOpen ? 'rotate(90deg)' : 'rotate(270deg)',
                transition: '0.3s ease-in-out',
              }}>
              <ChevronLeft />
            </Box>
          </AccordionButton>
          <AccordionPanel {...props.accordionPannelProps}>{children}</AccordionPanel>
        </AccordionItem>
      </Accordion>
    </CollapseWrapper>
  )
}
