import { SET_ACTION_DATA, SET_ASK_FOR_PHONE } from '../actions/contact/types'

const initialState = { askForPhone: false, actionData: undefined }

const contact = (state = initialState, action) => {
  switch (action.type) {
    case SET_ASK_FOR_PHONE:
      return { ...state, askForPhone: action.payload }
    case SET_ACTION_DATA:
      return { ...state, actionData: action.payload }
    default:
      return state
  }
}

export default contact
