import Spinner from '../Spinner'
import { Container } from './styled-components'

const Fallback = () => (
    <Container>
      <Spinner size="200px" />
    </Container>
  )

export default Fallback
