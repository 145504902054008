import Fallback from 'components/Fallback/Fallback'
import { commons } from 'components/Routes/routeList'
import React, { lazy, Suspense } from 'react'
import { useSelector } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import { countryCodeSelector } from 'store/selectors/app'
import { retry } from 'utils/helpers'

const Commune = lazy(() => retry(() => import('../../pages/Commune')))
const Landing = lazy(() => retry(() => import('../../pages/Landing')))
const ProfileLanding = lazy(() => retry(() => import('../../pages/ProfileLanding')))
const Enterprise = lazy(() => retry(() => import('../../pages/MiniSites/Enterprise')))
const PasswordRecovery = lazy(() => retry(() => import('../../pages/PasswordRecovery')))
const Publish = lazy(() => retry(() => import('../../pages/Publish/PublishLanding')))
const FAQ = lazy(() => retry(() => import('../../pages/Publish/PublishLanding/FAQ')))
const AboutUs = lazy(() => retry(() => import('../../pages/InfoSites/AboutUs')))
const FAQS = lazy(() => retry(() => import('../../pages/Faqs')))
const MainMap = lazy(() => retry(() => import('../../pages/MainMap')))
const TermsAndConditions = lazy(() => retry(() => import('../../pages/InfoSites/TermsAndConditions')))
const NotFound = lazy(() => retry(() => import('../../pages/NotFound')))
const Unsubscribe = lazy(() => retry(() => import('../../pages/Unsubscribe')))
const Financing = lazy(() => retry(() => import('../../pages/Financing')))
const GoodBye = lazy(() => retry(() => import('../../pages/GoodBye')))
const Paz = lazy(() => retry(() => import('../../pages/Paz')))

const UnauthenticatedApp = () => {
  const currentCountry = useSelector(countryCodeSelector)

  return (
    <Switch>
      <Suspense fallback={<Fallback />}>
        <Route exact path={commons.countryLanding} render={props => <Commune {...props} onlyCountry={true} />} />
        <Route exact path={commons.commune} render={props => <Commune {...props} onlyCountry={false} />} />
        <Route path={commons.propertyProfile} exact render={() => <ProfileLanding landingType="property" />} />
        <Route path={commons.projectProfile} exact render={() => <ProfileLanding landingType="project" />} />
        <Route path={commons.enterprise} exact component={Enterprise} />
        <Route path={commons.mainMap} exact component={MainMap} />
        <Route path={commons.landing} exact component={Landing} />
        <Route path={commons.passwordRecovery} exact component={PasswordRecovery} />
        <Route path={commons.publish} exact component={Publish} />
        <Route path={commons.publishFAQ} exact component={FAQ} />
        <Route path={commons.faqs} exact component={FAQS} />
        <Route path={commons.aboutUs} exact component={AboutUs} />
        <Route path={commons.termsAndConditions} exact component={TermsAndConditions} />
        <Route path={commons.termsAndConditionsPrivacy} exact component={TermsAndConditions} />
        <Route path={commons.unsubscribe} exact component={Unsubscribe} />
        <Route path={commons.deletedCountry} exact component={GoodBye} />
        {currentCountry === 'cl' && <Route path={commons.financing} exact component={Financing} />}
        {currentCountry === 'cl' && <Route path={commons.paz} exact component={Paz} />}
        <Route path={commons.notFound} exact component={NotFound} />
      </Suspense>
    </Switch>
  )
}

export default UnauthenticatedApp
