import { useSelector } from 'react-redux'
import { countryCodeSelector } from 'store/selectors/app'
import { allowedCountries } from 'utils/constants'

import Link from '../PrefixedLink'
import { StyledLinks } from './styled-components'

const Links = ({ className, links, id }) => {
  const currentCountry = useSelector(countryCodeSelector)
  return (
    <StyledLinks className={className}>
      {links.map(({ href, label, external, rel, showIn = allowedCountries }, i) => {
        if (!showIn.includes(currentCountry)) return null
        const props = { key: `${id}_${i}`, to: href, children: label, href }
        return external ? <a {...props} rel={rel} target="_blank" /> : <Link {...props} /> //eslint-disable-line
      })}
    </StyledLinks>
  )
}

export default Links
