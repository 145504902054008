import { createSelector } from 'reselect'

export const getLoginStatus = createSelector(
  state => state.user.profile.loginStatus,
  state => state.user.profile.GET_USER_REQUEST,
  (loginStatus, GET_USER_REQUEST) => ({
    loginStatus,
    isLoading: GET_USER_REQUEST,
  })
)

export const getUserAuthState = createSelector(
  state => state.user.profile.loginStatus,
  loginStatus => loginStatus === 'AUTHENTICATED'
)

export const getUserInfo = createSelector(
  state => state.user.profile.data,
  data => data
)

export const getUserName = createSelector(
  state => state.user.profile.data.nombre,
  name => name
)

export const getUserEmail = createSelector(
  state => state.user.profile.data.email,
  email => email
)

export const getUserPhone = createSelector(
  state => state.user.profile.data.telefono_formateado || state.user.profile.data.telefono,
  phone => phone
)

export const userRutSelector = createSelector(
  state => state.user.profile.rut,
  rut => rut
)

export const getUserData = createSelector(
  state => state.user.profile.data.id,
  state => state.user.profile.data.nombre,
  state => state.user.profile.data.email,
  state => state.user.profile.data.telefono_formateado || state.user.profile.data.telefono,
  state => state.user.profile.data.rut,
  state => state.user.profile.data.id_pais,
  state => state.user.profile.data.id_genero,
  state => state.user.profile.data.fecha_nacimiento,
  state => state.user.profile.data.ubicacion,
  state => state.user.profile.data.empresa,
  state => state.user.profile.data.imagen,

  (id, name, email, phone, rut, country, gender, birthdate, address, empresa, image) => ({
    id,
    name,
    email,
    phone,
    rut,
    country,
    gender,
    birthdate,
    address,
    empresa,
    image,
  })
)
export const getUserPreferences = createSelector(
  state => state.user.profile.data.sales_currency.id,
  state => state.user.profile.data.rents_currency.id,
  state => state.user.profile.data.hora_notificaciones,
  state => state.user.profile.data.newsletter,
  state => state.user.profile.data.oportunidades,
  state => state.user.profile.data.promociones,
  (saleCurrency, rentalCurrency, notifyNewProperties, notifyNews, notifyInvestment, offers) => ({
    saleCurrency,
    rentalCurrency,
    notifyNewProperties,
    notifyNews,
    notifyInvestment,
    offers,
  })
)

export const getUserContactData = createSelector(
  state => state.user.profile.data.nombre,
  state => state.user.profile.data.email,
  state => state.user.profile.data.telefono_formateado || state.user.profile.data.telefono,
  (name, email, phone) => ({ name, email, phone })
)

export const getUserSubmitStatus = createSelector(
  state => state.user.profile.USER_SUBMIT_REQUEST,
  req => req
)

export const getUserAvatar = createSelector(
  state => state.user.profile.data.imagen,
  avatar => avatar
)

export const getUserID = createSelector(
  state => state.user.profile.data.id,
  id => id
)

export const getUserFavorites = createSelector(
  state => state.user.profile.favorites,
  favorites => favorites
)
export const userProjectFavoritesSelector = createSelector(
  state => state.user.profile.projectFavorites,
  favorites => favorites
)
export const getUserContactedProperties = createSelector(
  state => state.user.profile.contactedProperties,
  contacted => contacted
)
export const getUserPersonalNotes = createSelector(
  state => state.user.profile.notes,
  notes => notes
)
export const getUserPublications = createSelector(
  state => state.user.profile.myPublications,
  publications => publications
)
export const getUserSeenProperties = createSelector(
  state => state.user.profile.seenProperties,
  seen => seen
)
export const getUserPricedProperties = createSelector(
  state => state.user.profile.pricedProperties,
  priced => priced
)
export const getUserZonesSelector = createSelector(
  state => state.user.profile.myZones,
  zones => zones
)
export const getProfileRequestStatus = createSelector(
  state => state.user.profile.PROFILE_SECTION_REQUEST,
  req => req
)

export const getUserCurrencies = createSelector(
  state => state.user.profile.data.sales_currency,
  state => state.user.profile.data.rents_currency,
  (sales, rents) => ({ saleCurrency: sales, rentCurrency: rents })
)

export const getUserCountryID = createSelector(
  state => state.user.profile.data.id_pais,
  id => id
)

export const getUserFavoriteIDs = createSelector(
  state => state.user.profile.data.favorite_properties,
  favs => favs
)

export const getUserContactedIDs = createSelector(
  state => state.user.profile.data.contact_requests,
  reqs => reqs
)

export const userFavoriteProjectsIDs = createSelector(
  state => state.user.profile.data.favorite_projects,
  projects => projects
)

export const forgotPasswordRequestSelector = createSelector(
  state => state.user.profile.FORGOT_PASSWORD_REQUEST,
  req => req
)
