import { createAdapter } from './utils'

const userInfoInterface = {
  address: 'ubicacion',
  birthdate: 'fecha_nacimiento',
  country: 'id_pais',
  email: 'email',
  gender: 'id_genero',
  name: 'nombre',
  notifyInvestment: 'oportunidades',
  notifyNewProperties: 'hora_notificaciones',
  notifyNews: 'newsletter',
  offers: 'promociones',
  phone: 'telefono',
  rut: 'rut',
  photo: 'imagen',
  password: 'password',
  rentalCurrency: 'id_moneda_arriendo',
  saleCurrency: 'id_moneda_venta',
}

export const userInfoAdapter = createAdapter(userInfoInterface)
