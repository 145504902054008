const black = {
  background: 'brand.notBlack',
  color: 'brand.white',
}

const blue = {
  background: 'brand.deepBlue',
  color: 'brand.white',
}

export const badgeTheme = {
  baseStyle: {
    marginTop: '0px!important',
    padding: '2px 20px',
    borderRadius: '5px',
    fontSize: '12px',
    lineHeight: '15px',
    fontWeight: '400',
    textTransform: 'capitalize',
  },
  variants: {
    black,
    blue,
  },
  defaultProps: {
    size: 'sm',
  },
}
