import { HStack, Text, VStack } from '@chakra-ui/react'
import colorSchema from 'styles/color-schema'

import { CountrySelector } from './CountrySelector'

export const FooterMenu = () => (
  <VStack w="full" gap="5px">
    <HStack>
      <Text fontSize="12px" lineHeight="15px" color={colorSchema.notBlack}>
        Estas en goplaceit.com
      </Text>
      <CountrySelector />
    </HStack>
    <Text fontSize="12px" lineHeight="15px" textAlign="center">
      2012-2024, goplaceit SpA. <br />
      Todos los derechos reservados
    </Text>
  </VStack>
)
