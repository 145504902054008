import 'mapbox-gl/dist/mapbox-gl.css'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'

import { ChakraProvider } from '@chakra-ui/react'
import * as Sentry from '@sentry/react'
import ReactDOM from 'react-dom'
import TagManager from 'react-gtm-module'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { chakraTheme } from 'styles/chakraStyles/chakraTheme'

import App from './components/App'
import store, { persistor } from './store'

Sentry.init({
  dsn: 'https://04c62660c10c42aca9f58ea494bd28f1@sentry.goplaceit.com/3',
  environment: process.env.NODE_ENV,
})

const tagManagerArgs = {
  gtmId: 'GTM-MJV988C',
}

TagManager.initialize(tagManagerArgs)

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ChakraProvider theme={chakraTheme}>
          <App />
        </ChakraProvider>
      </PersistGate>
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
)
