import {
  getEnterprisePropertiesTypes,
  getEnterpriseTypes,
  //  getSubsidyRentsTypes,
} from '../actions/miniSite/types'

const [GET_ENTERPRISE_REQUEST, GET_ENTERPRISE_SUCCESS, GET_ENTERPRISE_REJECT] = getEnterpriseTypes

const [GET_ENTERPRISE_PROPERTIES_REQUEST, GET_ENTERPRISE_PROPERTIES_SUCCESS, GET_ENTERPRISE_PROPERTIES_REJECT] =
  getEnterprisePropertiesTypes

const initialState = {
  GET_ENTERPRISE_REQUEST: false,
  GET_ENTERPRISE_SUCCESS: false,
  GET_ENTERPRISE_REJECT: false,
  GET_ENTERPRISE_PROPERTIES_REQUEST: false,
  GET_ENTERPRISE_PROPERTIES_SUCCESS: false,
  GET_ENTERPRISE_PROPERTIES_REJECT: false,
  data: undefined,
  list: undefined,
  pagination: {},
}

const miniSite = (state = initialState, action) => {
  switch (action.type) {
    case GET_ENTERPRISE_REQUEST:
      return {
        ...state,
        [action.type]: true,
        GET_ENTERPRISE_SUCCESS: false,
        GET_ENTERPRISE_REJECT: false,
      }
    case GET_ENTERPRISE_SUCCESS:
      return {
        ...state,
        [action.type]: true,
        GET_ENTERPRISE_REQUEST: false,
        data: action.payload,
      }
    case GET_ENTERPRISE_REJECT:
      return {
        ...state,
        [action.type]: true,
        GET_ENTERPRISE_REQUEST: false,
        error: action.error,
      }
    case GET_ENTERPRISE_PROPERTIES_REQUEST:
      return {
        ...state,
        [action.type]: true,
        GET_ENTERPRISE_PROPERTIES_SUCCESS: false,
        GET_ENTERPRISE_PROPERTIES_REJECT: false,
      }
    case GET_ENTERPRISE_PROPERTIES_SUCCESS:
      return {
        ...state,
        [action.type]: true,
        GET_ENTERPRISE_PROPERTIES_REQUEST: false,
        list: action.payload.response,
        pagination: action.payload.meta.pagination,
      }
    case GET_ENTERPRISE_PROPERTIES_REJECT:
      return {
        ...state,
        [action.type]: true,
        GET_ENTERPRISE_PROPERTIES_REQUEST: false,
        error: action.error,
      }
    default:
      return state
  }
}

export default miniSite
