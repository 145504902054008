import {
  CLEAN_PROJECT_DATA,
  getProjectPricingTypes,
  getProjectTypes,
  SET_MODAL_SLIDER_INDEX,
  SET_MODEL_IMAGE_FOR_MODAL,
  SET_PREVIOUS_MODEL,
  SET_PROJECT_IMAGES_FOR_MODAL,
} from '../actions/project/types'

const [GET_PROJECT_REQUEST, GET_PROJECT_SUCCESS, GET_PROJECT_REJECT] = getProjectTypes

const [GET_PROJECT_PRICING_REQUEST, GET_PROJECT_PRICING_SUCCESS, GET_PROJECT_PRICING_REJECT] = getProjectPricingTypes

const initialState = {
  GET_PROJECT_REQUEST: false,
  GET_PROJECT_SUCCESS: false,
  GET_PROJECT_REJECT: false,
  GET_PROJECT_PRICING_REQUEST: false,
  GET_PROJECT_PRICING_SUCCESS: false,
  GET_PROJECT_PRICING_REJECT: false,
  modalSliderIndex: 0,
  modelImageForModal: '',
  projectImagesForModal: [],
  previousModel: undefined,
  previousUnit: undefined,
  data: {
    currency: {},
    enterprise: {},
    project_models: {},
  },
  similarProjects: { properties: [], projects: [] },
}

const project = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROJECT_REQUEST:
      return { ...state, [action.type]: true }
    case GET_PROJECT_SUCCESS:
      return {
        ...state,
        [action.type]: true,
        data: action.payload,
        GET_PROJECT_REQUEST: false,
      }
    case GET_PROJECT_REJECT:
      return {
        ...state,
        [action.type]: true,
        error: action.error,
        GET_PROJECT_REQUEST: false,
      }
    case 'SET_FIELD':
      return {
        ...state,
        [action.field]: action.payload,
      }
    case GET_PROJECT_PRICING_REQUEST:
      return { ...state, [action.type]: true }
    case GET_PROJECT_PRICING_SUCCESS:
      const currentContactModels = state.data.contact_models
      let newContacts
      if (currentContactModels.map(m => m.id).includes(action.modelID)) {
        newContacts = currentContactModels.map(m => {
          if (m.id === action.modelID) {
            return {
              ...action.payload,
              created_at_message: action.payload.created_message,
              id: action.modelID,
            }
          }
          return m
        })
      } else {
        newContacts = [
          ...currentContactModels,
          {
            ...action.payload,
            created_at_message: action.payload.created_message,
            id: action.modelID,
          },
        ]
      }

      return {
        ...state,
        [action.type]: true,
        GET_PROJECT_PRICING_REQUEST: false,
        data: {
          ...state.data,
          contact_models: newContacts,
        },
      }
    case GET_PROJECT_PRICING_REJECT:
      return {
        ...state,
        [action.type]: true,
        error: action.error,
        GET_PROJECT_PRICING_REQUEST: false,
      }
    case SET_MODAL_SLIDER_INDEX:
      return {
        ...state,
        modalSliderIndex: action.payload,
      }
    case SET_MODEL_IMAGE_FOR_MODAL:
      return {
        ...state,
        modelImageForModal: action.payload,
      }
    case SET_PROJECT_IMAGES_FOR_MODAL:
      return {
        ...state,
        projectImagesForModal: action.payload,
      }
    case SET_PREVIOUS_MODEL:
      return {
        ...state,
        previousModel: action.payload.previousModel,
      }
    case CLEAN_PROJECT_DATA:
      return initialState
    default:
      return state
  }
}

export default project
