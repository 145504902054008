import Fallback from 'components/Fallback/Fallback'
import { authRoutes } from 'components/Routes/routeList'
import React, { lazy, Suspense } from 'react'
import { useSelector } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import { isEditingSelector } from 'store/selectors/publication'

import { retry } from '../../utils/helpers'
import SpinnerUntil from '../SpinnerUntil'

const UserProfile = lazy(() => retry(() => import('../../pages/UserProfile')))
const Publication = lazy(() => retry(() => import('../../pages/Publish')))

const AuthenticatedApp = () => {
  const isEditingPublication = useSelector(isEditingSelector)

  return (
    <Switch>
      <Suspense fallback={<Fallback />}>
        <Route path={authRoutes.profile} component={UserProfile} />
        <Route path={authRoutes.publish} exact component={Publication} />
        <Route
          path={authRoutes.editPublication}
          exact
          render={props => <SpinnerUntil until={isEditingPublication} Then={Publication} {...props} />}
        />
      </Suspense>
    </Switch>
  )
}

export default AuthenticatedApp
