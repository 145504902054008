import { Box, HStack, Popover, PopoverContent, PopoverTrigger, Text, useDisclosure, VStack } from '@chakra-ui/react'
import Link from 'components/PrefixedLink'
import useTranslation from 'i18n/useTranslation'
import { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { countryCodeSelector } from 'store/selectors/app'
import colorSchema from 'styles/color-schema'

import { getNavLinks } from '../navLinks'

export const Item = ({ label, to, external }) => {
  if (external) {
    return (
      <Text
        fontSize="15px"
        lineHeight="42px"
        as="button"
        textAlign="left"
        p="4px 8px"
        borderRadius="8px"
        w="full"
        onClick={() => window.open(to, '_blank')}
        _hover={{
          bg: colorSchema.artic,
        }}>
        {label}
      </Text>
    )
  }
  return (
    <Link to={to} style={{ marginTop: 0, width: '100%' }}>
      <Text
        fontSize="15px"
        lineHeight="42px"
        as="button"
        textAlign="left"
        p="4px 8px"
        borderRadius="8px"
        w="full"
        _hover={{
          bg: colorSchema.artic,
        }}>
        {label}
      </Text>
    </Link>
  )
}

const PopOver = ({ el }) => {
  const currentCountry = useSelector(countryCodeSelector)
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <Popover variant="navbar" isOpen={isOpen} onClose={onClose} isLazy>
      <PopoverTrigger>
        <Text
          as="button"
          key={el.label}
          fontSize="15px"
          lineHeight="20px"
          fontWeight={700}
          textAlign="center"
          w="full"
          maxW="140px"
          onClick={onOpen}>
          {el.label}
        </Text>
      </PopoverTrigger>
      <PopoverContent w="max-content">
        <VStack align="flex-start" gap="8px">
          {el.contents?.map(({ label, to, onlyForChile, external }) => (
            <Box key={label} onClick={onClose} w="full">
              {onlyForChile && currentCountry !== 'cl' ? null : <Item label={label} to={to} external={external} />}
            </Box>
          ))}
        </VStack>
      </PopoverContent>
    </Popover>
  )
}

export const OptionsMenu = () => {
  const t = useTranslation({})

  return (
    <HStack flex={1} justify="center" align="center">
      {getNavLinks(t).map(el => (
        <Fragment key={el.label}>
          {el.href ? (
            <Link to={el.href} style={{ marginTop: 0, width: '100%', maxWidth: '140px' }}>
              <Text
                as="button"
                key={el.label}
                fontSize="15px"
                lineHeight="20px"
                fontWeight={700}
                textAlign="center"
                w="full">
                {el.label}
              </Text>
            </Link>
          ) : (
            <PopOver el={el} />
          )}
        </Fragment>
      ))}
    </HStack>
  )
}
