import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { getInMapView, getResponsiveState } from 'store/selectors/app'

import Footer from '../Footer'
import { Navbar } from '../Navbar'
import { MainContainer } from './styled-components'

const withoutNavbarAndFooter = ['unsubscribe', 'financiamiento', 'despedida']
const withoutFooter = ['inversion']

const Layout = ({ children }) => {
  const location = useLocation()
  const isResponsive = useSelector(getResponsiveState)
  const isInMap = useSelector(getInMapView)
  const renderNavbarAndFooter = !withoutNavbarAndFooter.find(e => location.pathname.includes(e))
  const renderFooter = !withoutFooter.find(e => location.pathname.includes(e))
  const randomNumber = Math.random()
  const a = `${randomNumber} ${randomNumber * 10000000000000}`

  return renderNavbarAndFooter ? (
    <MainContainer>
      {isInMap ? isResponsive ? null : <Navbar /> : <Navbar />}
      {/*   <!-- Start of Floodlight Tag: Please do not remove. Creation Date: 11/24/2020 --> */}
      <iframe
        width="1"
        height="1"
        frameBorder="0"
        style={{ display: 'none' }}
        src={
          'https://10120542.fls.doubleclick.net/activityi;src=10120542;type=invmedia;cat=allpa0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=' +
          a +
          '?'
        }
      />
      {/*   <!-- End of Floodlight Tag: Please do not remove. Creation Date: 11/24/2020 --> */}
      <div className="content">{children}</div>
      {renderFooter && <Footer />}
    </MainContainer>
  ) : (
    <>{children}</>
  )
}

export default Layout
