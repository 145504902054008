import { HStack } from '@chakra-ui/react'

import { CountrySelector } from './CountrySelector'
import { LoggedMenu } from './LoggedMenu'
import { LoginButton } from './LoginButton'

export const UserMenu = ({ isLogged, isLoading, handleLoginModal }) => (
  <HStack gap="20px" spacing={0}>
    {!isLogged ? <LoginButton isLoading={!!isLoading} handleLoginModal={handleLoginModal} /> : <LoggedMenu />}
    <CountrySelector />
  </HStack>
)
