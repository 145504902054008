import capitalize from 'lodash.capitalize'

export const getNavLinks = t => [
  {
    label: capitalize(t('arrienda')),
    href: null,
    contents: [
      {
        label: capitalize(t('busca')) + ` en el mapa`,
        to: {
          pathname: '/mapa',
          search: '?id_modalidad=2&selectedTool=list',
          state: { referrer: window.location.href },
        },
      },
    ],
  },
  {
    label: capitalize(t('compra')),
    href: null,
    contents: [
      {
        label: capitalize(t('busca')) + ` en el mapa`,
        to: {
          pathname: '/mapa',
          search: '?id_modalidad=1&selectedTool=list',
          state: { referrer: window.location.href },
        },
      },
      {
        label: capitalize(t('proyecto') + 's inmobiliarios'),
        to: {
          pathname: '/mapa',
          search: '?id_modalidad=1&tipo_propiedad=2&proyectos=true&selectedTool=list',
          state: { referrer: window.location.href },
        },
      },
      {
        label: 'Propiedades para invertir',
        onlyForChile: true,
        to: {
          pathname: '/mapa',
          search: '?id_modalidad=1&tipo_propiedad=2&inversion=true&selectedTool=list',
          state: { referrer: window.location.href },
        },
      },
    ],
  },
  {
    label: `${capitalize(t('aprende'))}`,
    href: null,
    contents: [
      {
        label: 'Actualidad y mercado inmobiliario',
        to: 'https://medium.com/goplaceit-datos',
        external: true,
      },
      {
        label: 'Crédito hipotecario y financiamiento',
        to: 'https://medium.com/goplaceit-datos/educacion/home',
        external: true,
      },
    ],
  },

  {
    label: capitalize(t('publica')),
    href: '/publicar/',
  },
]
