// APP
export const bucketURL = 'https://d3ol89rt4kgjtl.cloudfront.net'

export const allowedCountries = ['cl', 'mx', 'ar', 'co', 'pe', 'uy']
export const allowedVideoProviders = [
  { name: 'youtube', regexp: /^https:\/\/w{0,3}\.?youtu\.?be/ },
  { name: 'vimeo', regexp: /^https:\/\/w{0,3}\.?vimeo.com\/\d+/ },
]

// MAIN MAP

export const MIN_BEDROOM_NUMBER = 0
export const MAX_BEDROOM_NUMBER = 4
export const MAX_BEDROOM_NUMBER_MOBILE = 3
export const MIN_BATHROOM_NUMBER = 1
export const MAX_BATHROOM_NUMBER = 5
export const MAX_BATHROOM_NUMBER_MOBILE = 4
export const DEFAULT_TILES_STYLE = 'mapbox://styles/mapbox/streets-v8'
export const SATELLITE_TILES_STYLE = 'mapbox://styles/mapbox/satellite-streets-v11'
export const SEEING_ZONE_ACTION = 'viendo'
export const EDITING_ZONE_ACTION = 'editando'
export const MINISITES_RESPONSIVE_BREAK = '1290px'
export const MAIN_MAP_RESPONSIVE_BREAK = '1199px'
export const TABLET_RESPONSIVE_BREAK = '1199px'
export const MEDIUM_RESPONSIVE_BREAK = '750px'
export const SMALL_CELLPHONE_BREAK = '400px'
export const MAIN_MAP_SMALL_SCREEN_BREAK = '1365px'

// PROPERTY PROFILES

export const MAX_FEATURES_NUMBER_PAGE_TOP = 4
export const MAX_FEATURES_NUMBER_DESCRIPTION = 5
export const MAX_FEATURES_NUMBER_APARTMENT = 2
export const MAX_FEATURES_NUMBER_HOUSE = 3
export const SQUARE_METERS = 'm2'
export const HECTARE = 'ha'

// SHARED

export const ALREADY_CONTACTED_TEXT = 'Te contactarán'
export const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN
// Verbosity level of console logging. Disabled in production.
// Available values ['DEBUG', null]
export const LOG_LEVEL = 'DEBUG'
export const ALLOWED_SOURCES = [
  'asistente',
  'compartir-email',
  'compartir-facebook',
  'compartir-link',
  'compartir-twitter',
  'compartir-whatsapp',
  'landing-bci',
  'landing-corredor-aliado',
  'landing-subsidio',
  'perfil-comuna',
  'perfil-empresa',
  'perfil-propiedad',
  'perfil-proyecto',
  'perfil-usuario',
]
