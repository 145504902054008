import { getMostVisitedCommunesTypes } from '../actions/landing/types'

const [GET_MOST_VISITED_COMMUNES_REQUEST, GET_MOST_VISITED_COMMUNES_SUCCESS, GET_MOST_VISITED_COMMUNES_REJECT] =
  getMostVisitedCommunesTypes

const initialState = {
  GET_MOST_VISITED_COMMUNES_REQUEST: false,
  GET_MOST_VISITED_COMMUNES_SUCCESS: false,
  GET_MOST_VISITED_COMMUNES_REJECT: false,
  mostVisitedCommunes: [],
}

const landing = (state = initialState, action) => {
  switch (action.type) {
    case GET_MOST_VISITED_COMMUNES_REQUEST:
      return {
        ...state,
        [action.type]: true,
        GET_MOST_VISITED_COMMUNES_SUCCESS: false,
        GET_MOST_VISITED_COMMUNES_REJECT: false,
      }
    case GET_MOST_VISITED_COMMUNES_SUCCESS:
      return {
        ...state,
        [action.type]: true,
        GET_MOST_VISITED_COMMUNES_REQUEST: false,
        mostVisitedCommunes: action.payload,
      }
    case GET_MOST_VISITED_COMMUNES_REJECT:
      return {
        ...state,
        [action.type]: true,
        GET_MOST_VISITED_COMMUNES_REQUEST: false,
        error: action.error,
      }
    default:
      return state
  }
}

export default landing
