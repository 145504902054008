import Spinner from '../Spinner/Spinner'
import { Container, Wrapper } from './styled-components'

const SpinnerUntil = ({ until, Then, ...props }) => (
    <Wrapper ready={until}>
      <Container>{!until && <Spinner size="200px" />}</Container>
      <Then {...props} />
    </Wrapper>
  )

export default SpinnerUntil
