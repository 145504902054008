import { createAsyncTypes } from '../utils'

// publication types
export const SET_SLICE = 'SET_SLICE'
export const SET_CURRENT_STEP = 'SET_CURRENT_STEP'
export const TOGGLE_PUBLISH_SYNC = 'TOGGLE_PUBLISH_SYNC'
export const TOGGLE_USE_CONTACT_INFO = 'TOGGLE_USE_CONTACT_INFO'
export const TOGGLE_USE_ADDRESS_AS_TITLE = 'TOGGLE_USE_ADDRESS_AS_TITLE'
export const TOGGLE_PUBLISH_CONFIRM_MODAL = 'TOGGLE_PUBLISH_CONFIRM_MODAL'
export const SET_INFO_STEP = 'SET_INFO_STEP'
export const RESET_PUBLICATION_FORM = 'RESET_PUBLICATION_FORM'
export const SET_AFTER_PUBLISHED_DATA = 'SET_AFTER_PUBLISHED_DATA'
export const SET_SCORE = 'SET_SCORE'
export const SET_REGIONS = 'SET_REGIONS'
export const SET_PROVINCES = 'SET_PROVINCES'
export const SET_COMMUNES = 'SET_COMMUNES'
export const RESET_PUBLICATION_STATE = 'RESET_PUBLICATION_STATE'
export const SET_NEIGHBORHOODS = 'SET_NEIGHBORHOODS'
export const SET_IS_EDITING_PUBLICATION = 'SET_IS_EDITING_PUBLICATION'
export const publishPropertyTypes = createAsyncTypes('PUBLISH_PROPERTY')
