import { useRoutesMiddleware } from 'hooks/useRoutesMiddleware'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, useHistory, useLocation } from 'react-router-dom'
import { toggleModal } from 'store/actions/app/creators'
import { CLEAR_NAVIGATION } from 'store/actions/app/types'
import { countryCodeSelector, navigateToSelector } from 'store/selectors/app'
import { getUserAuthState } from 'store/selectors/user'

import AuthenticatedApp from '../AuthenticatedApp'
import UnauthenticatedApp from '../UnauthenticatedApp'
import { RouteHelper } from './RouteHelper'
import { authRoutesRegex } from './routeList'

export const Routes = () => {
  const dispatch = useDispatch()
  const isLogged = useSelector(getUserAuthState)
  const currentCountry = useSelector(countryCodeSelector)
  const navigateTo = useSelector(navigateToSelector)
  const history = useHistory()
  const location = useLocation()

  useRoutesMiddleware()

  useEffect(() => {
    if (!isLogged) {
      Object.values(authRoutesRegex).forEach(route => {
        if (route.test(location.pathname)) {
          dispatch(toggleModal('LOGIN_MODAL', location.pathname))
        }
      })
    }
  }, [location, dispatch, isLogged])

  useEffect(() => {
    if (navigateTo) {
      history.push(`${currentCountry ? `/${currentCountry}` : ''}${navigateTo}`)
      dispatch({ type: CLEAR_NAVIGATION })
    }
  }, [currentCountry, dispatch, history, navigateTo])

  return (
    <>
      {isLogged && <AuthenticatedApp />}
      <UnauthenticatedApp />
      <Route path={'/*'} component={RouteHelper} />
    </>
  )
}
