import styled from 'styled-components'

import { MEDIUM_RESPONSIVE_BREAK } from '../../utils/constants'

export const Container = styled.div`
  width: 100vw;
`

export const Header = styled.div`
  width: 100%;
  padding: 50px 100px;
  min-height: 570px;
  display: flex;
  flex-direction: column;
  align-items: center;
  > div {
    display: flex;
    align-items: center;
  }

  & button {
    height: 50px;
    min-width: 140px;
    max-width: 300px;
    border-radius: 25px;
    border: 1px solid ${({ theme: { color } }) => color('salmon')};
    background-color: ${({ theme: { color } }) => color('salmon')};
    cursor: pointer;
    color: white;
    font-size: 15px;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
    transition: border-color 0.2s, background-color 0.2s;

    &:hover {
      border: 1px solid ${({ theme: { color } }) => color('salmonHover')};
      background-color: ${({ theme: { color } }) => color('salmonHover')};
    }

    &:focus {
      outline: none;
      border-color: ${({ theme: { color } }) => color('salmonHover')};
    }
  }
  @media (max-width: ${MEDIUM_RESPONSIVE_BREAK}) {
    padding: 70px 20px 40px;
  }
`

export const Image = styled.img`
  height: 200px;
  object-fit: contain;
  @media (max-width: ${MEDIUM_RESPONSIVE_BREAK}) {
    height: 100px;
  }
`

export const Title = styled.p`
  font-size: 60px;
  @media (max-width: ${MEDIUM_RESPONSIVE_BREAK}) {
    font-size: 30px;
  }
`

export const SubTitle = styled.p`
  font-size: 30px;
  text-align: center;
  @media (max-width: ${MEDIUM_RESPONSIVE_BREAK}) {
    font-size: 18px;
  }
`
