import {
  CLEAR_SERVICES,
  getAvailableLayersTypes,
  getLayerDataTypes,
  getPropertyListTypes,
  getPropertyMarkersTypes,
  getServicesDataTypes,
  RESET_MARKERS,
  SET_USER_ZONES,
} from '../actions/mainMap/types'

const [GET_PROPERTY_LIST_REQUEST, GET_PROPERTY_LIST_SUCCESS, GET_PROPERTY_LIST_REJECT] = getPropertyListTypes

const [GET_PROPERTY_MARKERS_REQUEST, GET_PROPERTY_MARKERS_SUCCESS, GET_PROPERTY_MARKERS_REJECT] =
  getPropertyMarkersTypes

const [GET_MAIN_MAP_LAYER_DATA_REQUEST, GET_MAIN_MAP_LAYER_DATA_SUCCESS, GET_MAIN_MAP_LAYER_DATA_REJECT] =
  getLayerDataTypes

const [GET_MAIN_MAP_SERVICES_REQUEST, GET_MAIN_MAP_SERVICES_SUCCESS, GET_MAIN_MAP_SERVICES_REJECT] =
  getServicesDataTypes

const [GET_AVAILABLE_LAYERS_REQUEST, GET_AVAILABLE_LAYERS_SUCCESS, GET_AVAILABLE_LAYERS_REJECT] =
  getAvailableLayersTypes

const initialState = {
  [GET_PROPERTY_LIST_REQUEST]: false,
  [GET_PROPERTY_LIST_SUCCESS]: false,
  [GET_PROPERTY_LIST_REJECT]: false,
  [GET_PROPERTY_MARKERS_REQUEST]: false,
  [GET_PROPERTY_MARKERS_SUCCESS]: false,
  [GET_PROPERTY_MARKERS_REJECT]: false,
  [GET_MAIN_MAP_LAYER_DATA_REQUEST]: false,
  [GET_MAIN_MAP_LAYER_DATA_SUCCESS]: false,
  [GET_MAIN_MAP_LAYER_DATA_REJECT]: false,
  [GET_MAIN_MAP_SERVICES_REQUEST]: false,
  [GET_MAIN_MAP_SERVICES_SUCCESS]: false,
  [GET_MAIN_MAP_SERVICES_REJECT]: false,
  [GET_AVAILABLE_LAYERS_REQUEST]: false,
  [GET_AVAILABLE_LAYERS_SUCCESS]: false,
  [GET_AVAILABLE_LAYERS_REJECT]: false,

  propertyList: { meta: {}, response: {} },
  propertyMarkers: [],
  activeDataLayer: [],
  selectedServices: [],
  userZones: { list: [], pagination: {} },
  availableLayers: [],
}

const mainMap = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROPERTY_LIST_REQUEST:
      return {
        ...state,
        [action.type]: true,
        [GET_PROPERTY_LIST_SUCCESS]: false,
        [GET_PROPERTY_LIST_REJECT]: false,
      }
    case GET_PROPERTY_LIST_SUCCESS:
      return {
        ...state,
        [action.type]: true,
        [GET_PROPERTY_LIST_REQUEST]: false,
        propertyList: action.payload,
      }
    case GET_PROPERTY_LIST_REJECT:
      return {
        ...state,
        [action.type]: true,
        [GET_PROPERTY_LIST_REQUEST]: false,
        error: action.error,
      }
    case GET_PROPERTY_MARKERS_REQUEST:
      return {
        ...state,
        [action.type]: true,
        [GET_PROPERTY_MARKERS_SUCCESS]: false,
        [GET_PROPERTY_MARKERS_REJECT]: false,
      }
    case GET_PROPERTY_MARKERS_SUCCESS:
      return {
        ...state,
        [action.type]: true,
        [GET_PROPERTY_MARKERS_REQUEST]: false,
        propertyMarkers: action.payload,
      }
    case GET_PROPERTY_MARKERS_REJECT:
      return {
        ...state,
        [action.type]: true,
        [GET_PROPERTY_MARKERS_REQUEST]: false,
        error: action.error,
      }
    case GET_MAIN_MAP_LAYER_DATA_REQUEST:
      return {
        ...state,
        [GET_MAIN_MAP_LAYER_DATA_REQUEST]: true,
        [GET_MAIN_MAP_LAYER_DATA_SUCCESS]: false,
        [GET_MAIN_MAP_LAYER_DATA_REJECT]: false,
      }
    case GET_MAIN_MAP_LAYER_DATA_SUCCESS:
      return {
        ...state,
        [GET_MAIN_MAP_LAYER_DATA_SUCCESS]: true,
        [GET_MAIN_MAP_LAYER_DATA_REQUEST]: false,
        activeDataLayer: action.payload,
      }
    case GET_MAIN_MAP_LAYER_DATA_REJECT:
      return {
        ...state,
        GET_MAIN_MAP_LAYER_DATA_REJECT: true,
        GET_MAIN_MAP_LAYER_DATA_REQUEST: false,
        error: action.error,
      }
    case RESET_MARKERS:
      return {
        ...state,
        [GET_PROPERTY_MARKERS_REQUEST]: false,
        [GET_PROPERTY_MARKERS_SUCCESS]: false,
        [GET_PROPERTY_MARKERS_REJECT]: false,
        propertyMarkers: [],
      }
    case GET_MAIN_MAP_SERVICES_REQUEST:
      return {
        ...state,
        [GET_MAIN_MAP_SERVICES_REQUEST]: true,
        [GET_MAIN_MAP_SERVICES_SUCCESS]: false,
        [GET_MAIN_MAP_SERVICES_REJECT]: false,
      }
    case GET_MAIN_MAP_SERVICES_SUCCESS:
      return {
        ...state,
        [GET_MAIN_MAP_SERVICES_SUCCESS]: true,
        [GET_MAIN_MAP_SERVICES_REQUEST]: false,
        selectedServices: action.payload,
      }
    case GET_MAIN_MAP_SERVICES_REJECT:
      return {
        ...state,
        GET_MAIN_MAP_SERVICES_REJECT: true,
        GET_MAIN_MAP_SERVICES_REQUEST: false,
        error: action.error,
      }
    case CLEAR_SERVICES:
      return {
        ...state,
        [GET_MAIN_MAP_SERVICES_REQUEST]: false,
        [GET_MAIN_MAP_SERVICES_SUCCESS]: false,
        [GET_MAIN_MAP_SERVICES_REJECT]: false,
        selectedServices: [],
      }
    case SET_USER_ZONES:
      return {
        ...state,
        userZones: {
          list: action.payload.response,
          pagination: action.payload.meta.pagination,
        },
      }
    case GET_AVAILABLE_LAYERS_REQUEST:
      return {
        ...state,
        [GET_AVAILABLE_LAYERS_REQUEST]: true,
        [GET_AVAILABLE_LAYERS_SUCCESS]: false,
        [GET_AVAILABLE_LAYERS_REJECT]: false,
      }
    case GET_AVAILABLE_LAYERS_SUCCESS:
      return {
        ...state,
        [GET_AVAILABLE_LAYERS_SUCCESS]: true,
        [GET_AVAILABLE_LAYERS_REQUEST]: false,
        availableLayers: action.payload,
      }
    case GET_AVAILABLE_LAYERS_REJECT:
      return {
        ...state,
        GET_AVAILABLE_LAYERS_REJECT: true,
        GET_AVAILABLE_LAYERS_REQUEST: false,
        error: action.error,
      }
    default:
      return state
  }
}

export default mainMap
