import { extendTheme } from '@chakra-ui/react'

import { accordionTheme } from './components/accordionTheme'
import { badgeTheme } from './components/badgeTheme'
import { drawerTheme } from './components/drawerTheme'
import { headingTheme } from './components/headingTheme'
import { menuTheme } from './components/menuTheme'
import { popoverTheme } from './components/popoverTheme'
import { radioTheme } from './components/radioTheme'
import { textTheme } from './components/textTheme'
import { tooltipTheme } from './components/tooltipTheme'

export const chakraTheme = extendTheme({
  colors: {
    brand: {
      pink: '#FF9B99',
      salmon: '#ff4b50',
      salmonHover: '#EC393E',
      gpiHover: '#E30007',
      gpi: '#FC0444',
      darkGpi: '#CF1117',
      night: '#444242',
      nightSoft: '#385B64',
      notBlack: '#202020',
      notBlackMedium: '#353535',
      white: '#fff',
      artic: '#F8F8F9',
      siberia: '#E7E7E7',
      graphite: '#C4C4C4',
      graphite2: '#AAA9AA',
      graphite3: '#D9D9D9',
      wolf: '#7C7C7C',
      leaf: '#00AD5A',
      forest: '#008D49',
      pine: '#0d7355',
      sea: '#4482fc',
      seaMedium: '#006DE9',
      blue: '#3066d1',
      deepBlue: '#0163D2',
      zaphire: '#114cc0',
      allyViolet: '#6454C2',
      purple: '#9534D1',
      navyBlue: '#114b5f',
    },
  },
  components: {
    Accordion: accordionTheme,
    Menu: menuTheme,
    Popover: popoverTheme,
    Drawer: drawerTheme,
    Text: textTheme,
    Heading: headingTheme,
    Radio: radioTheme,
    Badge: badgeTheme,
    Tooltip: tooltipTheme,
  },
})
