import { combineReducers } from 'redux'

import { SET_CONTACTED_ID } from '../actions/property/types'
import {
  forgotPasswordTypes,
  getProfileSectionTypes,
  getSubmitTypes,
  getUserTypes,
  SET_AVATAR,
  SET_FAVORITE_IDS,
  SET_PROJECT_FAVORITE_IDS,
  SET_USER_DATA,
  SET_USER_PAGINATED_RESOURCE,
  UPDATE_PASSWORD_STATUS,
} from '../actions/user/types'

const [GET_USER_REQUEST, GET_USER_SUCCESS, GET_USER_REJECT] = getUserTypes
const [FORGOT_PASSWORD_REQUEST, FORGOT_PASSWORD_SUCCESS, FORGOT_PASSWORD_REJECT] = forgotPasswordTypes
const [PROFILE_SECTION_REQUEST, PROFILE_SECTION_SUCCESS, PROFILE_SECTION_REJECT] = getProfileSectionTypes
const [USER_SUBMIT_REQUEST, USER_SUBMIT_SUCCESS, USER_SUBMIT_REJECT] = getSubmitTypes

const initialState = {
  data: {
    favorite_properties: [],
    contact_requests: [],
    favorite_projects: [],
  },
  favorites: { list: [], pagination: {} },
  projectFavorites: { list: [], pagination: {} },
  contactedProperties: { list: [], pagination: {} },
  notes: { list: [], pagination: {} },
  myPublications: { list: [], pagination: {} },
  seenProperties: { list: [], pagination: {} },
  pricedProperties: { list: [], pagination: {} },
  myZones: { list: [], pagination: {} },
  loginStatus: 'REQUEST_EMAIL',
  updatePasswordStatus: 'idle',
  GET_USER_REQUEST: false,
  GET_USER_SUCCESS: false,
  GET_USER_REJECT: false,
  PROFILE_SECTION_REQUEST: null,
  PROFILE_SECTION_SUCCESS: null,
  PROFILE_SECTION_REJECT: null,
  USER_SUBMIT_REQUEST: false,
  USER_SUBMIT_SUCCESS: false,
  USER_SUBMIT_REJECT: false,
}

const profile = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_REQUEST:
      return {
        ...state,
        [action.type]: true,
        loginStatus: state.loginStatus,
      }
    case GET_USER_SUCCESS:
      return {
        ...state,
        GET_USER_REQUEST: false,
        [action.type]: true,
        data: action.payload,
        loginStatus: 'AUTHENTICATED',
      }
    case GET_USER_REJECT:
      return {
        ...state,
        GET_USER_REQUEST: null,
        loginStatus: 'NOT_AUTHENTICATED',
        [action.type]: true,
      }
    case FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        [action.type]: true,
      }
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        FORGOT_PASSWORD_REQUEST: false,
        [action.type]: true,
      }
    case FORGOT_PASSWORD_REJECT:
      return {
        ...state,
        FORGOT_PASSWORD_REQUEST: false,
        [action.type]: true,
      }
    case PROFILE_SECTION_REQUEST:
      return {
        PROFILE_SECTION_SUCCESS: null,
        PROFILE_SECTION_REJECT: null,
        ...state,
        [action.type]: action.section,
      }
    case PROFILE_SECTION_SUCCESS:
      return {
        ...state,
        PROFILE_SECTION_REQUEST: null,
        [action.type]: action.section,
      }
    case PROFILE_SECTION_REJECT:
      return {
        ...state,
        PROFILE_SECTION_REQUEST: null,
        [action.type]: action.section,
        error: action.error,
      }
    case SET_AVATAR:
      return {
        ...state,
        data: { ...state.data, avatar: action.payload },
      }
    case UPDATE_PASSWORD_STATUS:
      return {
        ...state,
        updatePasswordStatus: action.payload,
      }
    case USER_SUBMIT_REQUEST:
      return {
        ...state,
        [action.type]: true,
      }
    case USER_SUBMIT_SUCCESS:
      return {
        ...state,
        data: action.payload,
        USER_SUBMIT_REQUEST: false,
        [action.type]: true,
      }
    case USER_SUBMIT_REJECT:
      return {
        ...state,
        error: action.error,
        USER_SUBMIT_REQUEST: false,
        [action.type]: true,
      }
    case SET_USER_DATA:
      return {
        ...state,
        data: action.payload,
        loginStatus: 'AUTHENTICATED',
      }
    case SET_USER_PAGINATED_RESOURCE:
      return {
        ...state,
        [action.field]: action.payload,
      }
    case SET_CONTACTED_ID:
      return {
        ...state,
        data: {
          ...state.data,
          contact_requests: [...state.data.contact_requests, action.payload],
        },
      }
    case SET_FAVORITE_IDS:
      return {
        ...state,
        data: {
          ...state.data,
          favorite_properties: action.payload,
        },
      }
    case SET_PROJECT_FAVORITE_IDS:
      return {
        ...state,
        data: {
          ...state.data,
          favorite_projects: action.payload,
        },
      }
    default:
      return state
  }
}

export default combineReducers({ profile })
