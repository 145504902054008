import { batch } from 'react-redux'

import { userInfoAdapter } from '../../../adapters/user'
import { countryIdToCode } from '../../../utils/dictionaries'
import { SET_COUNTRY_CODE } from '../app/types'
import { catchHandler, handleError } from '../handlers'
import { baseUrl, getOptions, paginationQuery, postOptions, queryfy } from '../utils'
import {
  forgotPasswordTypes,
  getAvatarTypes,
  getProfileSectionTypes,
  getSubmitTypes,
  getUserTypes,
  requestUnsubscribeTypes,
  SET_USER_PAGINATED_RESOURCE,
  SIGN_OUT_USER,
  UPDATE_PASSWORD_STATUS,
} from './types'

export const getUser =
  ({ email, password, GA }) =>
  (dispatch, getState) => {
    const [request, success, reject] = getUserTypes
    const currentCountry = getState().app.country
    dispatch({ type: request })

    const url = new URL(`${baseUrl}users/signin`)
    const params = { email, password }

    return fetch(url, {
      ...postOptions,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    })
      .then(handleError)
      .then(res => res.json())
      .then(json => {
        GA.event({
          category: 'boton',
          action: 'clic',
          label: 'iniciar sesion',
        })
        const userCountry = countryIdToCode[json.id_pais]
        if (userCountry !== currentCountry) {
          batch(() => {
            dispatch({ type: success, payload: json })
            dispatch({ type: SET_COUNTRY_CODE, payload: userCountry })
          })
        } else {
          dispatch({ type: success, payload: json })
        }
        return json
      })
      .catch(catchHandler(dispatch, reject))
  }

export const registerUser =
  ({ email, password, name, GA }) =>
  dispatch => {
    const [request, success, reject] = getUserTypes
    dispatch({ type: request })
    return fetch(`${baseUrl}users/signup`, {
      ...postOptions,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user: userInfoAdapter({ email, password, name }),
        signin: true,
      }),
    })
      .then(handleError)
      .then(res => res.json())
      .then(json => {
        GA.event({
          category: 'boton',
          action: 'clic',
          label: 'registrar',
        })
        return dispatch({ type: success, payload: json })
      })
      .catch(catchHandler(dispatch, reject))
  }

export const authUserWithFacebook =
  ({ access_token, uid_facebook, GA }) =>
  dispatch => {
    const [, success, reject] = getUserTypes
    return fetch(`${baseUrl}facebook_connect`, {
      ...postOptions,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        access_token,
        uid_facebook,
      }),
    })
      .then(handleError)
      .then(res => res.json())
      .then(json => {
        GA.event({
          category: 'boton',
          action: 'clic',
          label: 'iniciar sesion',
        })
        dispatch({ type: success, payload: json })
        return json
      })
      .catch(catchHandler(dispatch, reject))
  }

export const submitUserData = data => dispatch => {
  const [request, success, reject] = getSubmitTypes

  dispatch({ type: request })
  return fetch(`${baseUrl}users/me`, {
    method: 'PUT',
    mode: 'cors',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ user: userInfoAdapter(data) }),
  })
    .then(handleError)
    .then(res => res.json())
    .then(json => {
      dispatch({ type: success, payload: json })
      return json
    })
    .catch(catchHandler(dispatch, reject))
}

export const uploadAvatar = data => dispatch => {
  const [request, success, reject] = getAvatarTypes //eslint-disable-line
  const formData = new FormData()
  formData.append('imagen', data)
  dispatch({ type: request })
  return fetch(`${baseUrl}users/me/image_upload`, {
    ...postOptions,
    body: formData,
  })
    .then(handleError)
    .then(res => res.json())
    .then(json => json)
    .catch(catchHandler(dispatch, reject))
}

export const getUserSession = () => dispatch => {
  const [request, success, reject] = getUserTypes
  dispatch({ type: request })

  return fetch(`${baseUrl}users/me`, getOptions)
    .then(handleError)
    .then(res => res.json())
    .then(json => {
      dispatch({ type: success, payload: json })
      return json
    })
    .catch(error => {
      // TODO: replace with catchHandler(dispatch, reject) and test
      dispatch({ type: reject, error })
      return error
    })
}

export const signOutUser = () => dispatch =>
  fetch(`${baseUrl}users/signout`, getOptions)
    .then(handleError)
    .then(res => res.json())
    .then(() => {
      dispatch({ type: SIGN_OUT_USER })
    })
    .catch(catchHandler(dispatch))

const handleProfileSectionRequest = (fetch, section, dispatch) => {
  const [req, suc, rej] = getProfileSectionTypes
  dispatch({ type: req, section })
  return fetch
    .then(handleError)
    .then(res => res.json())
    .then(json => {
      dispatch({
        type: suc,
        section,
      })
      dispatch({
        type: SET_USER_PAGINATED_RESOURCE,
        field: section,
        section,
        payload: { pagination: json.meta.pagination, list: json.response },
      })
    })
    .catch(error => dispatch({ type: rej, error, section })) // TODO: replace with catchHandler(dispatch, reject) and test
}

export const getFavoriteProperties =
  (page = 1, per_page = 10000) =>
  dispatch =>
    handleProfileSectionRequest(
      fetch(`${baseUrl}users/me/favorites${paginationQuery(page, per_page)}`, getOptions),
      'favorites',
      dispatch
    )

export const getFavoriteProjects =
  (page = 1, per_page = 10000) =>
  dispatch =>
    handleProfileSectionRequest(
      fetch(`${baseUrl}users/me/favorites_project${paginationQuery(page, per_page)}`, getOptions),
      'projectFavorites',
      dispatch
    )

export const getContactedProperties =
  (page = 1, per_page = 10000) =>
  dispatch =>
    handleProfileSectionRequest(
      fetch(`${baseUrl}users/me/contacted${paginationQuery(page, per_page)}`, getOptions),
      'contactedProperties',
      dispatch
    )

export const getPersonalNotes =
  (page = 1) =>
  dispatch =>
    handleProfileSectionRequest(
      fetch(`${baseUrl}users/me/notes${paginationQuery(page)}`, getOptions),
      'notes',
      dispatch
    )

export const getMyPublications =
  (page = 1) =>
  dispatch =>
    handleProfileSectionRequest(
      fetch(`${baseUrl}users/me/listings${paginationQuery(page)}`, getOptions),
      'myPublications',
      dispatch
    )

export const getSeenProperties =
  (page = 1) =>
  dispatch =>
    handleProfileSectionRequest(
      fetch(`${baseUrl}users/me/visited${paginationQuery(page)}`, getOptions),
      'seenProperties',
      dispatch
    )

export const getPricedProperties =
  (page = 1) =>
  dispatch =>
    handleProfileSectionRequest(
      fetch(`${baseUrl}users/me/quoted${paginationQuery(page)}`, getOptions),
      'pricedProperties',
      dispatch
    )

export const getUserZones =
  (page = 1) =>
  dispatch =>
    handleProfileSectionRequest(
      fetch(`${baseUrl}users/me/zones${paginationQuery(page)}`, getOptions),
      'myZones',
      dispatch
    )

export const renewUserPublication = propertyID => dispatch =>
  fetch(`${baseUrl}properties/${propertyID}/renew`, {
    method: 'PUT',
    mode: 'cors',
    credentials: 'include',
  })
    .then(handleError)
    .then(res => res.json())
    .then(json => json)
    .catch(catchHandler(dispatch))
export const deleteUserPublication = propertyID => dispatch =>
  fetch(`${baseUrl}properties/${propertyID}`, {
    method: 'DELETE',
    mode: 'cors',
    credentials: 'include',
  })
    .then(res => handleError(res))
    .catch(catchHandler(dispatch))

export const recoverUserPassword = userMail => dispatch => {
  const [req, suc, rej] = forgotPasswordTypes
  dispatch({ type: req })
  return fetch(`${baseUrl}users/forgot_password`, {
    ...postOptions,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ user: { email: userMail } }),
  })
    .then(handleError)
    .then(res => res.json())
    .then(json => {
      dispatch({ type: suc, payload: json })
      return json
    })
    .catch(catchHandler(dispatch, rej))
}

export const updateUserPassword = (token, new_password, password_confirmation) => dispatch => {
  const [success, reject] = getUserTypes
  dispatch({ type: UPDATE_PASSWORD_STATUS, payload: 'requesting' })
  return fetch(`${baseUrl}users/me/recover_password`, {
    method: 'PUT',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      reset_password_token: token,
      new_password,
      password_confirmation,
    }),
  })
    .then(handleError)
    .then(res => res.json())
    .then(json => {
      dispatch({ type: UPDATE_PASSWORD_STATUS, payload: 'success' })
      dispatch({ type: success, payload: json })
      return json
    })
    .catch(() => {
      dispatch({ type: UPDATE_PASSWORD_STATUS, payload: 'reject' })
      catchHandler(dispatch, reject)
    })
}

export const unsubscribe = (token, id_seleccion, descripcion) => dispatch => {
  const [req] = requestUnsubscribeTypes
  dispatch({ type: req })
  const url = new URL(
    `${baseUrl}users/me/disable_notifications${
      descripcion
        ? queryfy({
            token,
            id_seleccion,
            descripcion,
          })
        : queryfy({
            token,
            id_seleccion,
          })
    }`
  )
  return fetch(url, postOptions)
    .then(res => res.json())
    .catch(err => console.log(err))
}
