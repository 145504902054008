import { postInversion } from 'store/actions/inversion/types'

const initialState = {
  loading: false,
  success: false,
  error: false,
}

const inversion = (state = initialState, action) => {
  const [REQUEST, SUCCESS, REJECT] = postInversion

  switch (action.type) {
    case REQUEST:
      return { ...state, loading: true, success: false, error: false }
    case SUCCESS:
      return { ...state, loading: false, success: true, error: false }
    case REJECT:
      return { ...state, loading: false, success: false, error: true }
    default:
      return state
  }
}

export default inversion
