import colorSchema from '../../color-schema'

const classic = {
  button: {
    width: '100%',
    borderRadius: '20px',
    border: `1px solid ${colorSchema.siberia} !important`,
    background: colorSchema.white,
    boxShadow: 'none',
    heigth: '40px',
    _focus: {
      boxShadow: `inset 0px 0px 0px 2px ${colorSchema.siberia}`,
    },
  },
}

export const menuTheme = {
  parts: ['button', 'list', 'item', 'groupTitle', 'command', 'divider'],
  baseStyle: {
    button: {
      bg: colorSchema.white,
      padding: '10px 20px',
      borderRadius: '20px',
      border: '1px solid',
      borderColor: colorSchema.graphite3,
      boxShadow: '0px 5px 15px 0px rgba(32, 32, 32, 0.20)',
      minWidth: '160px',
      fontSize: '12px',
      letterSpacing: '0.048px',
      _active: {
        borderColor: colorSchema.notBlack,
      },
      _disabled: {
        opacity: 0.5,
        cursor: 'not-allowed',
      },
    },
    list: {
      p: '10px ',
      borderRadius: '20px',
      border: '1px solid #000',
      boxShadow: '0px 5px 15px 0px rgba(32, 32, 32, 0.20)',
    },
    item: {
      fontSize: '12px',
      borderRadius: '20px',
    },
  },
  variants: {
    classic,
  },
}
