import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { setCountry, toggleModal } from 'store/actions/app/creators'
import { getOptions } from 'store/actions/utils'
import { countryCodeSelector } from 'store/selectors/app'
import { getUserAuthState } from 'store/selectors/user'
import {
  buildRedirectURLBasedOnEnvironment,
  cleanURL,
  communeWithLocationTypePattern,
  communeWithoutLocationTypePattern,
  deletedCountries,
  enterpriseAssociatesPattern,
  enterprisePattern,
  getCommuneWithLocationTypeRequestURL,
  getCommuneWithoutLocationTypeRequestURL,
  getEnterpriseAssociatesRequestURL,
  getEnterpriseRequestURL,
  getProjectRequestURL,
  getPropertyRequestURL,
  getSuperEnterpriseAssociatesRequestURL,
  projectPattern,
  propertyPattern,
  superEnterpriseAssociatesPattern,
  wellknownLocations,
} from 'utils/routes'

export const useRoutesMiddleware = () => {
  const { pathname } = useLocation()
  const { push } = useHistory()
  const isLoggedIn = useSelector(getUserAuthState)
  const currentCountry = useSelector(countryCodeSelector)
  const dispatch = useDispatch()

  const withAuthentication = useCallback(
    to => {
      if (!isLoggedIn) {
        dispatch(toggleModal('LOGIN_MODAL', '/'))
      } else {
        push(to)
      }
    },
    [dispatch, isLoggedIn, push]
  )

  const fetchAndRedirect = useCallback(
    async getEndpointFn => {
      const endpoint = getEndpointFn(pathname)
      const redirectURL = await fetch(endpoint, getOptions)
        .then(res => {
          if (res?.status === 404) return push('/404')
          return res.json()
        })
        .then(json => buildRedirectURLBasedOnEnvironment(json.url))
      if (redirectURL !== cleanURL) window.location.replace(redirectURL)
    },
    [pathname, push]
  )

  const handleLocationPaths = useCallback(() => {
    const parts = pathname.split('/')
    const lastPart = parts[parts.length - 1]
    if (currentCountry === 'cl' && wellknownLocations['cl'].includes(lastPart)) return
    fetchAndRedirect(getCommuneWithLocationTypeRequestURL)
  }, [currentCountry, fetchAndRedirect, pathname])

  const handleDynamicPaths = useCallback(() => {
    if (communeWithLocationTypePattern.test(pathname)) handleLocationPaths()
    if (communeWithoutLocationTypePattern.test(pathname)) fetchAndRedirect(getCommuneWithoutLocationTypeRequestURL)
    if (propertyPattern.test(pathname)) fetchAndRedirect(getPropertyRequestURL)
    if (projectPattern.test(pathname)) fetchAndRedirect(getProjectRequestURL)
    if (enterprisePattern.test(pathname)) fetchAndRedirect(getEnterpriseRequestURL)
    if (enterpriseAssociatesPattern.test(pathname)) fetchAndRedirect(getEnterpriseAssociatesRequestURL)
    if (superEnterpriseAssociatesPattern.test(pathname)) fetchAndRedirect(getSuperEnterpriseAssociatesRequestURL)
  }, [fetchAndRedirect, handleLocationPaths, pathname])

  const handleDeletedCountries = useCallback(() => {
    if (currentCountry && deletedCountries.includes(currentCountry)) push('/despedida')
  }, [currentCountry, push])

  useEffect(() => {
    handleDeletedCountries()
    handleDynamicPaths()
    if (pathname === '/') {
      if (currentCountry) {
        push(`/${currentCountry}`)
      } else {
        dispatch(setCountry())
      }
    }
    // Redirections
    if (pathname === '/perfil') withAuthentication(`/${currentCountry}/perfil`)
  }, [
    currentCountry,
    dispatch,
    handleDynamicPaths,
    isLoggedIn,
    push,
    pathname,
    withAuthentication,
    handleDeletedCountries,
  ])
}
