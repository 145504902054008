import colorSchema from '../../color-schema'

export const textTheme = {
  baseStyle: {
    fontFamily: 'Red Hat Text, sans-serif',
    color: colorSchema.notBlack,
    margin: '0 !important',
    fontWeight: 400,
  },
}
