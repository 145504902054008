import colorSchema from '../../color-schema'

export const tooltipTheme = {
  baseStyle: {
    borderRadius: '20px',
    border: '1px solid #000!important',
    background: colorSchema.white,
    boxShadow: ' 0px 5px 15px 0px rgba(32, 32, 32, 0.20)',
    color: colorSchema.notBlack,
    padding: '10px 15px',
    fontFamily: 'Red Hat Text',
  },
}
