import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'

import reducers from './reducers'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['app'],
  blacklist: ['app/isMapView'],
}

const withDevTools = process.env.NODE_ENV === 'development' ? composeWithDevTools : m => m

const persistedReducer = persistReducer(persistConfig, reducers)
const store = createStore(persistedReducer, withDevTools(applyMiddleware(thunk)))

export const dispatch = store.dispatch
export default store
export const persistor = persistStore(store)
