// Common words dictionary to be merged with each component's own dictionary

// Note that due to spanish grammar, keys for this dictionary are in spanish
// so that the meaning of the word we are translating is clear

const commons = {
  // RENT/SALE
  arrendar: {
    cl: 'arrendar',
    mx: 'rentar',
    ar: 'alquilar',
    co: 'arrendar',
    pe: 'alquilar',
    uy: 'alquilar',
    ve: 'arrendar',
  },
  arrienda: {
    cl: 'arrienda',
    mx: 'renta',
    ar: 'alquilá',
    co: 'arrienda',
    pe: 'alquila',
    uy: 'alquilá',
    ve: 'alquila',
  },
  arriendo: {
    cl: 'arriendo',
    mx: 'renta',
    ar: 'alquiler',
    co: 'arriendo',
    pe: 'alquiler',
    uy: 'alquiler',
    ve: 'alquiler',
  },
  venta: {
    cl: 'venta',
    mx: 'venta',
    ar: 'venta',
    co: 'venta',
    pe: 'venta',
    uy: 'venta',
    ve: 'venta',
  },

  //CURRENCIES NAMES
  currencyName: {
    cl: 'pesos chilenos',
    mx: 'pesos mexicanos',
    ar: 'pesos argentinos',
    co: 'pesos colombianos',
    pe: 'soles',
    uy: 'pesos uruguayos',
    ve: 'bolívares',
  },

  //PRICE SECTION
  commonExpensesArticle: {
    cl: 'los',
    mx: 'la',
    ar: 'las',
    co: 'los',
    pe: 'la',
    uy: 'los',
    ve: 'los',
  },
  commonExpenses: {
    cl: 'Gastos comunes',
    mx: 'Cuota de mantenimiento',
    ar: 'Expensas comunes',
    co: 'Gastos de administración',
    pe: 'Cuota de mantenimiento',
    uy: 'Gastos/expensas comunes',
    ve: 'Gastos de condominio',
  },
  contributions: {
    cl: 'Contribuciones',
    mx: 'Impuesto predial',
    ar: 'Impuesto inmobiliario',
    co: 'Impuesto predial unificado',
    pe: 'Impuesto predial',
    uy: 'Contribución inmobiliaria',
    ve: 'Impuesto inmobiliario',
  },

  // PROPERTY TYPES and Abbreviations
  casa: {
    cl: 'casa',
    mx: 'casa',
    ar: 'casa',
    co: 'casa',
    pe: 'casa',
    uy: 'casa',
    ve: 'casa',
  },
  privados: {
    cl: 'privados',
    mx: 'privados',
    ar: 'privados',
    co: 'privados',
    pe: 'privados',
    uy: 'privados',
    ve: 'privados',
  },
  corredora: {
    cl: 'corredora',
    mx: 'inmobiliaria',
    ar: 'inmobiliaria',
    co: 'inmobiliaria',
    pe: 'agente inmobiliario',
    uy: 'inmobiliaria',
    ve: 'inmobiliaria',
  },
  corredoras: {
    cl: 'corredoras',
    mx: 'inmobiliarias',
    ar: 'inmobiliarias',
    co: 'inmobiliarias',
    pe: 'agentes inmobiliarios',
    uy: 'inmobiliarias',
    ve: 'inmobiliarias',
  },
  corredores: {
    cl: 'corredores',
    mx: 'inmobiliarias',
    ar: 'inmobiliarias',
    co: 'inmobiliarias',
    pe: 'agentes inmobiliarios',
    uy: 'inmobiliarias',
    ve: 'inmobiliarias',
  },

  departamento: {
    cl: 'departamento',
    mx: 'departamento',
    ar: 'departamento',
    co: 'apartamento',
    pe: 'departamento',
    uy: 'apartamento',
    ve: 'apartamento',
  },
  depto: {
    cl: 'depto',
    mx: 'depto',
    ar: 'depto',
    co: 'apto',
    pe: 'depto',
    uy: 'apto',
    ve: 'apto',
  },
  dpto: {
    cl: 'Depto',
    mx: 'Depto',
    ar: 'Depto',
    co: 'apto',
    pe: 'dpto',
    uy: 'apto',
    ve: 'apto',
  },
  inmobiliaria: {
    cl: 'inmobiliaria',
    mx: 'constructora',
    ar: 'constructora',
    co: 'constructora',
    pe: 'constructora',
    uy: 'constructora',
    ve: 'constructora',
  },
  inmobiliario: {
    cl: 'inmobiliario',
    mx: 'constructora',
    ar: 'constructora',
    co: 'constructora',
    pe: 'constructora',
    uy: 'constructora',
    ve: 'constructora',
  },
  oficina: {
    cl: 'oficina',
    mx: 'oficina',
    ar: 'oficina',
    co: 'oficina',
    pe: 'oficina',
    uy: 'oficina',
    ve: 'oficina',
  },
  propiedad: {
    cl: 'propiedad',
    mx: 'inmueble',
    ar: 'propiedad',
    co: 'inmueble',
    pe: 'inmueble',
    uy: 'propiedad',
    ve: 'inmueble',
  },
  propiedades: {
    cl: 'propiedades',
    mx: 'inmuebles',
    ar: 'propiedades',
    co: 'inmuebles',
    pe: 'inmuebles',
    uy: 'propiedades',
    ve: 'inmuebles',
  },
  proyecto: {
    cl: 'proyecto',
    mx: 'desarrollo',
    ar: 'emprendimiento',
    co: 'proyecto',
    pe: 'proyecto',
    uy: 'emprendimiento',
    ve: 'desarrollo',
  },

  terreno: {
    cl: 'terreno',
    mx: 'terreno',
    ar: 'terreno',
    co: 'terreno',
    pe: 'terreno',
    uy: 'terreno',
    ve: 'terreno',
  },

  estacionamiento: {
    cl: 'estacionamiento',
    mx: 'estacionamiento',
    ar: 'estacionamiento',
    co: 'estacionamiento',
    pe: 'estacionamiento',
    uy: 'estacionamiento',
    ve: 'estacionamiento',
  },

  bodega: {
    cl: 'bodega',
    mx: 'bodega',
    ar: 'bodega',
    co: 'bodega',
    pe: 'bodega',
    uy: 'bodega',
    ve: 'bodega',
  },

  // REGION
  region: {
    cl: 'región',
    mx: 'estado',
    ar: 'provincia',
    co: 'departamento',
    pe: 'región',
    uy: 'departamento',
    ve: 'estado',
  },

  // COMMUNE
  comuna: {
    cl: 'comuna',
    mx: 'colonia',
    ar: 'localidad',
    co: 'localidad',
    pe: 'distrito',
    uy: 'colonia',
    ve: 'municipio',
  },
  comunas: {
    cl: 'comunas',
    mx: 'colonias',
    ar: 'localidades',
    co: 'localidades',
    pe: 'distritos',
    uy: 'colonias',
    ve: 'municipios',
  },

  // ROOM
  habitacion: {
    cl: 'habitación',
    mx: 'recámara',
    ar: 'habitación',
    co: 'habitación',
    pe: 'dormitorio',
    uy: 'habitación',
    ve: 'habitación',
  },
  habitaciones: {
    cl: 'habitaciones',
    mx: 'recámaras',
    ar: 'habitaciones',
    co: 'habitaciones',
    pe: 'dormitorios',
    uy: 'habitaciones',
    ve: 'habitaciones',
  },
  hab: {
    cl: 'hab',
    mx: 'rec',
    ar: 'hab',
    co: 'hab',
    pe: 'dorm',
    uy: 'hab',
    ve: 'hab',
  },

  estudio: {
    cl: 'estudio',
    mx: 'estudio',
    ar: 'monoambiente',
    co: 'apartestudio',
    pe: 'estudio',
    uy: 'monoambiente',
  },

  //VERBS
  accede: {
    cl: 'accede',
    mx: 'accede',
    ar: 'accedé',
    co: 'accede',
    pe: 'accede',
    uy: 'accedé',
    ve: 'accede',
  },
  analiza: {
    cl: 'analiza',
    mx: 'analiza',
    ar: 'analizá',
    co: 'analiza',
    pe: 'analiza',
    uy: 'analizá',
    ve: 'analiza',
  },
  aumenta: {
    cl: 'aumenta',
    mx: 'aumenta',
    ar: 'aumentá',
    co: 'aumenta',
    pe: 'aumenta',
    uy: 'aumentá',
    ve: 'aumenta',
  },
  busca: {
    cl: 'busca',
    mx: 'busca',
    ar: 'buscá',
    co: 'busca',
    pe: 'busca',
    uy: 'buscá',
    ve: 'busca',
  },
  segui: {
    cl: 'sigue',
    mx: 'sigue',
    ar: 'seguí',
    co: 'sigue',
    pe: 'sigue',
    uy: 'seguí',
    ve: 'sigue',
  },
  colocas: {
    cl: 'colocas',
    mx: 'colocas',
    ar: 'colocás',
    co: 'colocas',
    pe: 'colocas',
    uy: 'colocás',
    ve: 'colocas',
  },
  compara: {
    cl: 'compara',
    mx: 'compara',
    ar: 'compará',
    co: 'compara',
    pe: 'compara',
    uy: 'compará',
    ve: 'compara',
  },
  comparte: {
    cl: 'comparte',
    mx: 'comparte',
    ar: 'compartí',
    co: 'comparte',
    pe: 'comparte',
    uy: 'compartí',
    ve: 'comparte',
  },

  compra: {
    cl: 'compra',
    mx: 'compra',
    ar: 'comprá',
    co: 'compra',
    pe: 'compra',
    uy: 'comprá',
    ve: 'compra',
  },
  comprar: {
    cl: 'comprar',
    mx: 'comprar',
    ar: 'comprar',
    co: 'comprar',
    pe: 'comprar',
    uy: 'comprar',
    ve: 'comprar',
  },
  conecta: {
    cl: 'conecta',
    mx: 'conecta',
    ar: 'conectá',
    co: 'conecta',
    pe: 'conecta',
    uy: 'conectá',
    ve: 'conecta',
  },
  aprende: {
    cl: 'aprende',
    mx: 'aprende',
    ar: 'aprendé',
    co: 'aprende',
    pe: 'aprende',
    uy: 'aprendé',
    ve: 'aprende',
  },
  conoce: {
    cl: 'conoce',
    mx: 'conoce',
    ar: 'conocé',
    co: 'conoce',
    pe: 'conoce',
    uy: 'conocé',
    ve: 'conoce',
  },
  consideras: {
    cl: 'consideras',
    mx: 'consideras',
    ar: 'considerás',
    co: 'consideras',
    pe: 'consideras',
    uy: 'considerás',
    ve: 'consideras',
  },
  crea: {
    cl: 'crea',
    mx: 'crea',
    ar: 'crea',
    co: 'crea',
    pe: 'crea',
    uy: 'creá',
    ve: 'crea',
  },
  cuentanos: {
    cl: 'cuéntanos',
    mx: 'cuéntanos',
    ar: 'contanos',
    co: 'cuéntanos',
    pe: 'cuéntanos',
    uy: 'contanos',
    ve: 'cuéntanos',
  },
  descarga: {
    cl: 'descarga',
    mx: 'descarga',
    ar: 'descargá',
    co: 'descarga',
    pe: 'descarga',
    uy: 'descargá',
    ve: 'descarga',
  },
  explora: {
    cl: 'explora',
    mx: 'explora',
    ar: 'explorá',
    co: 'explora',
    pe: 'explora',
    uy: 'explorá',
    ve: 'explora',
  },
  descubre: {
    cl: 'descubre',
    mx: 'descubre',
    ar: 'descubrí',
    co: 'descubre',
    pe: 'descubre',
    uy: 'descubrí',
    ve: 'descubre',
  },
  deja: {
    cl: 'deja',
    mx: 'deja',
    ar: 'deja',
    co: 'deja',
    pe: 'deja',
    uy: 'dejá',
    ve: 'deja',
  },
  destaca: {
    cl: 'destaca',
    mx: 'destaca',
    ar: 'destaca',
    co: 'destaca',
    pe: 'destaca',
    uy: 'destacá',
    ve: 'destaca',
  },
  dibuja: {
    cl: 'dibuja',
    mx: 'dibuja',
    ar: 'dibujá',
    co: 'dibuja',
    pe: 'dibuja',
    uy: 'dibujá',
    ve: 'dibuja',
  },
  elige: {
    cl: 'elige',
    mx: 'elige',
    ar: 'elegí',
    co: 'elige',
    pe: 'elige',
    uy: 'elegí',
    ve: 'elige',
  },
  escribe: {
    cl: 'escribe',
    mx: 'escribe',
    ar: 'escribí',
    co: 'escribe',
    pe: 'escribe',
    uy: 'escribí',
    ve: 'escribe',
  },
  encuentres: {
    cl: 'encuentres',
    mx: 'encuentres',
    ar: 'encontrés',
    co: 'encuentres',
    pe: 'encuentres',
    uy: 'encontrés',
    ve: 'encuentres',
  },
  guardala: {
    cl: 'guárdala',
    mx: 'guárdala',
    ar: 'guardala',
    co: 'guárdala',
    pe: 'guárdala',
    uy: 'guardala',
    ve: 'guárdala',
  },
  inicia: {
    cl: 'inicia',
    mx: 'inicia',
    ar: 'iniciá',
    co: 'inicia',
    pe: 'inicia',
    uy: 'iniciá',
    ve: 'inicia',
  },
  ingresa: {
    cl: 'ingresa',
    mx: 'ingresa',
    ar: 'ingresá',
    co: 'ingresa',
    pe: 'ingresa',
    uy: 'ingresá',
    ve: 'ingresa',
  },
  intenta: {
    cl: 'intenta',
    mx: 'intenta',
    ar: 'intentá',
    co: 'intenta',
    pe: 'intenta',
    uy: 'intentá',
    ve: 'intenta',
  },
  mira: {
    cl: 'mira',
    mx: 'mira',
    ar: 'mirá',
    co: 'mira',
    pe: 'mira',
    uy: 'mirá',
    ve: 'mira',
  },
  muestranos: {
    cl: 'muéstranos',
    mx: 'muéstranos',
    ar: 'mostranos',
    co: 'muéstranos',
    pe: 'muéstranos',
    uy: 'mostranos',
    ve: 'muéstranos',
  },
  necesitas: {
    cl: 'necesitas',
    mx: 'necesitas',
    ar: 'necesitás',
    co: 'necesitas',
    pe: 'necesitas',
    uy: 'necesitás',
    ve: 'necesitas',
  },
  obten: {
    cl: 'obtén',
    mx: 'obtén',
    ar: 'obtené',
    co: 'obtén',
    pe: 'obtén',
    uy: 'obtené',
    ve: 'obtén',
  },
  personaliza: {
    cl: 'personaliza',
    mx: 'personaliza',
    ar: 'personalizá',
    co: 'personaliza',
    pe: 'personaliza',
    uy: 'personalizá',
    ve: 'personaliza',
  },
  podras: {
    cl: 'podrás',
    mx: 'podrás',
    ar: 'podés',
    co: 'puedás',
    pe: 'podrás',
    uy: 'podés',
    ve: 'podrás',
  },
  publica: {
    cl: 'publica',
    mx: 'publica',
    ar: 'publicá',
    co: 'publica',
    pe: 'publica',
    uy: 'publicá',
    ve: 'publica',
  },
  puedes: {
    cl: 'puedes',
    mx: 'puedes',
    ar: 'podés',
    co: 'puedes',
    pe: 'puedes',
    uy: 'podés',
    ve: 'puedes',
  },
  recibe: {
    cl: 'recibe',
    mx: 'recibe',
    ar: 'recibí',
    co: 'recibe',
    pe: 'recibe',
    uy: 'recibí',
    ve: 'recibe',
  },
  registrate: {
    cl: 'regístrate',
    mx: 'regístrate',
    ar: 'registrate',
    co: 'regístrate',
    pe: 'regístrate',
    uy: 'registrate',
    ve: 'regístrate',
  },
  revisa: {
    cl: 'revisa',
    mx: 'revisa',
    ar: 'revisá',
    co: 'revisa',
    pe: 'revisa',
    uy: 'revisá',
    ve: 'revisa',
  },
  sabes: {
    cl: 'sabes',
    mx: 'sabes',
    ar: 'sábes',
    co: 'sabes',
    pe: 'sabes',
    uy: 'sábes',
    ve: 'sabes',
  },
  selecciona: {
    cl: 'selecciona',
    mx: 'selecciona',
    ar: 'seleccioná',
    co: 'selecciona',
    pe: 'selecciona',
    uy: 'seleccioná',
    ve: 'selecciona',
  },
  siguenos: {
    cl: 'síguenos',
    mx: 'síguenos',
    ar: 'seguinos',
    co: 'síguenos',
    pe: 'síguenos',
    uy: 'seguinos',
    ve: 'síguenos',
  },
  simula: {
    cl: 'simula',
    mx: 'simula',
    ar: 'simulá',
    co: 'simula',
    pe: 'simula',
    uy: 'simulá',
    ve: 'simula',
  },
  tienes: {
    cl: 'tienes',
    mx: 'tienes',
    ar: 'tenés',
    co: 'tienes',
    pe: 'tienes',
    uy: 'tenés',
    ve: 'tienes',
  },
  unete: {
    cl: 'únete',
    mx: 'únete',
    ar: 'unite',
    co: 'únete',
    pe: 'únete',
    uy: 'unite',
    ve: 'únete',
  },
  utiliza: {
    cl: 'utiliza',
    mx: 'utiliza',
    ar: 'utilizá',
    co: 'utiliza',
    pe: 'utiliza',
    uy: 'utilizá',
    ve: 'utiliza',
  },
  vende: {
    cl: 'vende',
    mx: 'vende',
    ar: 'vendé',
    co: 'vende',
    pe: 'vende',
    uy: 'vendé',
    ve: 'vende',
  },
  vendela: {
    cl: 'véndela',
    mx: 'véndela',
    ar: 'vendela',
    co: 'véndela',
    pe: 'véndela',
    uy: 'vendela',
    ve: 'véndela',
  },
  visualiza: {
    cl: 'visualiza',
    mx: 'visualiza',
    ar: 'visualizá',
    co: 'visualiza',
    pe: 'visualiza',
    uy: 'visualizá',
    ve: 'visualiza',
  },
  vendenos: {
    cl: 'véndenos',
    mx: 'véndenos',
    ar: 'vendenos',
    co: 'véndenos',
    pe: 'véndenos',
    uy: 'vendenos',
    ve: 'véndenos',
  },
  vender: {
    cl: 'vender',
    mx: 'vender',
    ar: 'vender',
    co: 'vender',
    pe: 'vender',
    uy: 'vender',
    ve: 'vender',
  },
  quieres: {
    cl: 'quieres',
    mx: 'quieres',
    ar: 'querés',
    co: 'quieres',
    pe: 'quieres',
    uy: 'querés',
    ve: 'quieres',
  },

  // OTHERS
  ti: {
    cl: 'tí',
    mx: 'tí',
    ar: 'vos',
    co: 'tí',
    pe: 'tí',
    uy: 'vos',
    ve: 'tí',
  },
  tu: {
    cl: 'tú',
    mx: 'tú',
    ar: 'vos',
    co: 'tú',
    pe: 'tú',
    uy: 'vos',
    ve: 'tú',
  },

  // FEATURES
  piscina: {
    cl: 'piscina',
    mx: 'piscina',
    ar: 'pileta',
    co: 'piscina',
    pe: 'piscina',
    uy: 'pileta',
    ve: 'piscina',
  },

  //PHONE PREFIXES
  phonePrefixes: {
    cl: '+56 9',
    ar: '+54',
    co: '+57',
    mx: '+52',
    pe: '+51',
    uy: '+598',
    ve: '+58',
  },
}

export default commons
