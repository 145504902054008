import React from 'react'

import { isEmpty } from './helpers'

export const EMAIL_REGEX = new RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
)
export const USER_REGEX = new RegExp(/([a-zA-Z]+\s){1,}([a-zA-Z]+)/)
export const ROL_REGEX = new RegExp(/^\d+-\d+$/) //eslint-disable-line
export const DATE_REGEX = new RegExp(/([0-2][0-9]|3[0-1])(\/|-)(0[1-9]|1[0-2])\2(\d{4})$/)
// eslint-disable-next-line no-useless-escape
export const ADDRESS_REGEX = new RegExp(/([A-Za-z]\s[0-9])+/)
export const validateEmail = email => EMAIL_REGEX.test(email)
export const validateUser = name => USER_REGEX.test(name)
export const validateDate = birthdate => DATE_REGEX.test(birthdate)
export const validateAddress = address => ADDRESS_REGEX.test(address)
export const validateDateRange = birthdate => {
  const dateYear = new Date(birthdate).getFullYear()
  const currentYear = new Date().getFullYear()
  return dateYear > 1920 && dateYear < currentYear
}
export const validateRolNumber = rol => ROL_REGEX.test(rol)

export const trimPath = pathname => {
  if (pathname[pathname.length - 1] === '/') {
    const trimmedPath = pathname.substring(0, pathname.length - 1)
    return trimmedPath
  } else return pathname
}

export const truncateString = (string, maxLength) => {
  if (string.length > maxLength) {
    return string.slice(0, maxLength).concat('...')
  }
  return string
}

export const capitalize = string => (string ? `${string[0].toUpperCase()}${string.slice(1)}` : '')

export const formatPrice = (price, options, asObject) => {
  const defaultOptions = {
    symbol: '$',
    separator: '.',
    decimalPlaces: 0,
    decimalSeparator: ',',
    prefix: '',
    suffix: '',
  }
  const { symbol, separator, suffix, decimalSeparator } = {
    ...defaultOptions,
    ...options,
  }
  if (typeof price === 'string') {
    price = price.replace(/,/g, '.')
  }
  const stringPrice = symbol === 'UF' ? `${Math.ceil(price)}` : `${price}`
  const splittedPrice = stringPrice.split('.')
  let result = []
  const priceLength = splittedPrice[0].length
  for (let i = 0; i < priceLength; i++) {
    i % 3 === 0 && i !== 0
      ? result.unshift(`${splittedPrice[0][priceLength - 1 - i]}${separator}`)
      : result.unshift(splittedPrice[0][priceLength - 1 - i])
  }

  const UFPrice =
    suffix === 'UF' && splittedPrice[0] && splittedPrice[1] && Number(splittedPrice[1]) > 0
      ? `${splittedPrice[0]}${decimalSeparator}${splittedPrice[1].slice(0, 2)}`
      : undefined

  if (asObject) {
    return {
      amount: UFPrice ? UFPrice : result.join(''),
      code: suffix !== symbol ? suffix : '',
      suffix,
      symbol: symbol === 'UF' || symbol === 'USD' ? '' : symbol,
      unformattedAmount: price,
    }
  }
  suffix !== symbol && result.push(suffix)

  return result.join('')
}

export const formatDate = (date, { withTime = false }) => {
  if (!date) return ''
  const d = date.split(/[-T]/)
  const newDate = `${d[2]}-${d[1]}-${d[0]}`

  return withTime ? [newDate, d[3]] : newDate
}

export const changeImageResolution = (url, newResolution) => {
  if (!url) return url
  const detectRegexp = /^https?:\/\/goplaceit\.s3\.amazonaws\.com/
  if (url.match(detectRegexp)) {
    return url.replace(/64x64\./i, `${newResolution}.`)
  }
  return url
}

export const pluralize = (string, number) => {
  if (number > 1) {
    return `${string}s`
  }
  return string
}

export const convertCurrency = (price, fromCurrency, toCurrency) => {
  // id dolar = 7
  const inDollars = price / fromCurrency.conversion_dolar
  if (toCurrency.id === 7) {
    return inDollars
  }
  return inDollars * toCurrency.conversion_dolar
}

export const convertAndFormatFromDollars = (price, toCurrency, asObject) => {
  const newPrice = price * toCurrency.conversion_dolar
  return formatPrice(newPrice, toCurrency, asObject)
}

export const convertAndFormatCurrency = (price, fromCurrency, toCurrency, asObject) => {
  if (!toCurrency || toCurrency.id === fromCurrency.id || Object.keys(toCurrency).length < 1) {
    return formatPrice(price, fromCurrency, asObject)
  }
  return formatPrice(convertCurrency(price, fromCurrency, toCurrency), toCurrency, asObject)
}

export const redactField = (text, replacementString) => {
  const email = /([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})/gi
  const phone =
    /\b((Dir||Tel||Nxt||movistar||Cel||y||t||c||\$||CELL||PBX||Telf||rpm||RPC||OF||mls||MLS N.||oficina||Celular de contacto||comunicarse al||al||Contacto||Fono(s)? de contacto||para visitas contactar||casa||fono(s)?||celular(es)?||m(ó||o)vil||Mov||nextel||next||tel(e||é)fono(s)?||–)?:?#?\.?\s?\(?\+?[\d\*]{1,3}\)?\s?\-?\.?\s?\(?[\d\*]{1,3}\)?\s?\-?\.?\s?\(?[\d\*]{1,4}\)?\s?\-?\.?\s?[\d\*]{4,12}\)?\s?,?\/?\/?\y?;?(o||ó)?)/gi //eslint-disable-line
  return text.replace(email, replacementString).replace(phone, replacementString)
}

export const addLineBreaks = string => {
  const permittedBreaks = ['<br />', '<br/>', '<br>']
  const splitted = string.split(/(<br \/>|<br\/>)/g)
  return splitted.map((slice, index) => (permittedBreaks.includes(slice) ? <br key={index} /> : slice))
}

export const countCharsWithBreaks = str => {
  const size = str.length
  return size
}

export const truncateStringWithBreaks = (str, maxLength, breakValue = 60, withFinalDots = true) => {
  if (countCharsWithBreaks(str) > maxLength) {
    return str
      .replace(
        new RegExp('\n\n', 'g'), //eslint-disable-line
        new Array(breakValue * 2).fill(' ').join('')
      )
      .slice(0, maxLength - 5)
      .trim()
      .concat(withFinalDots ? '...' : '')
  }
  return str
}

export const processTextObject = desc => {
  const allowedModifiers = ['bold', 'lineBreak', 'uppercase', 'italicBold', 'italic']

  return desc.map((text, index) => {
    if (text.modifier && !allowedModifiers.includes(text.modifier)) {
      throw new Error(
        `Invalid modifier "${text.modifier}". Must be one of [${allowedModifiers.join(', ')}] or undefined.`
      )
    }
    switch (text.modifier) {
      case 'bold':
        return <strong key={index}>{text.content}</strong>
      case 'lineBreak':
        return <br key={index} />
      case 'uppercase':
        return <React.Fragment key={index}>{text.content.toUpperCase()}</React.Fragment>
      case 'italicBold':
        return (
          <strong key={index}>
            <em>{text.content}</em>
          </strong>
        )
      case 'italic':
        return <em key={index}>{text.content}</em>
      default:
        return <React.Fragment key={index}>{text.content}</React.Fragment>
    }
  })
}

export const isAValidInputNumberValue = (str, regex) => {
  if (isEmpty(str)) {
    return true
  }
  return regex.test(str)
}

export const reduceNumber = number => {
  if (!number) {
    return '0'
  }
  if (typeof number === 'string') {
    number = parseInt(number)
  }
  if (number >= 1e6) {
    const formattedNumber = number / 1e6
    if (formattedNumber % 1 === 0) {
      return formattedNumber.toFixed(0) + 'M'
    } else {
      return formattedNumber + 'M'
    }
  }
  return number.toLocaleString('ES-cl')
}
