import { ALLOWED_SOURCES, LOG_LEVEL } from './constants'

export const debounce = (func, delay) =>
  (function () {
    let timerId
    return () => {
      clearTimeout(timerId)
      timerId = setTimeout(func, delay)
    }
  })()

export const groupArray = (array, groupSize) => {
  const result = []

  array.forEach((ele, i) => {
    if (i % groupSize !== 0) return
    const subArray = []
    for (let j = 0; j < groupSize; j++) {
      if (array[i + j] === undefined) continue
      subArray.push(array[i + j])
    }
    result.push(subArray)
  })
  return result
}

export const processStylesObject = styles => {
  if (!styles) return ''
  const keys = Object.keys(styles)
  const mapped = keys.map(k => `${k}: ${styles[k]};`)
  return mapped.join('\n')
}

export const syncShallowStringify = obj => {
  const keys = Object.keys(obj)
  let result = []
  keys.forEach(k => result.push(`"${k}":"${obj[k]}"`))

  return `{${result.join(',')}}`
}

export const retry = (fn, retriesLeft = 3, interval = 1000) =>
  new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch(error => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            reject(error)
            return
          }

          retry(fn, retriesLeft - 1, interval).then(resolve, reject)
        }, interval)
      })
  })

export const debugLog = (msgs, logLevel = LOG_LEVEL) => {
  if (process.env.NODE_ENV === 'development') {
    if (logLevel === LOG_LEVEL) {
      console.log(...msgs)
    }
  }
}

export const convertHa = value => {
  const convertedValue = parseInt(value)
  if (parseInt(value) > 20000) {
    return convertedValue / 10000
  } else {
    return convertedValue
  }
}

export const checkRut = rut => {
  if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rut)) return false
  let [numbers, verifier] = rut.split('-')
  let sum = 0
  for (let i = numbers.length - 1; i >= 0; i--) {
    const multiplier = ((numbers.length - 1 - i) % 6) + 2
    sum += parseInt(numbers[i]) * multiplier
  }
  const result = 11 - (sum % 11)
  switch (result) {
    case 11:
      return '0' === verifier
    case 10:
      return 'k' === verifier.toLowerCase()
    default:
      return `${result}` === verifier
  }
}

export const polyfillScrollTo = options => {
  try {
    window.scrollTo(options)
  } catch (error) {
    window.scroll(options.left || 0, options.top || 0)
  }
}

export const detectBrowser = () => (window.navigator ? window.navigator.userAgent : 'unknown')

export const isNumber = number => {
  let result = 0
  if (number !== null && number !== undefined && number !== '') {
    result = number
  }
  return result
}

export const createInternalUtm = (medium, campaign = null) =>
  `utm_source=goplaceit&utm_medium=${medium}${campaign ? `&utm_campaign=${campaign}` : ''}`

export const createExternalUtm = ({ source, medium, campaign, prev }) =>
  `${source ? `utm_source=${source}` : ''}${medium ? `&utm_medium=${medium}` : ''}${
    campaign ? `&utm_campaign=${campaign}` : ''
  }${prev ? `&prev=${prev}` : ''}`

export const getUtmFromSearch = search => {
  const source = new URLSearchParams(search).get('utm_source')
  const medium = new URLSearchParams(search).get('utm_medium')
  const campaign = new URLSearchParams(search).get('utm_campaign')
  return createExternalUtm({ source, medium, campaign })
}

export const getSourceAndPrev = search => {
  const prev = new URLSearchParams(search).get('prev')
  const source = new URLSearchParams(search).get('source')
  return { source, prev }
}

export const setInternalUtm = (search, setter, type = 'propiedad') => {
  if (!search) return
  const { source, prev } = getSourceAndPrev(search)
  if (source && source.startsWith('mapa')) {
    const [map, ...clicked] = source.split('-')
    const utm = createInternalUtm(map, `perfil-${type}-${clicked.join('-')}`)
    return setter ? setter(utm) : utm
  }
  if (ALLOWED_SOURCES.includes(source)) {
    return setter ? setter(createInternalUtm(source, prev)) : createInternalUtm(source, prev)
  }
  return setter ? setter(search.substring(1)) : search.substring(1)
}

export const uniqBy = (arr, predicate) => {
  const cb = typeof predicate === 'function' ? predicate : o => o[predicate]
  return [
    ...arr
      .reduce((map, item) => {
        const key = item === null || item === undefined ? item : cb(item)
        map.has(key) || map.set(key, item)
        return map
      }, new Map())
      .values(),
  ]
}

export const orderBy = (arr, callback, order = 'asc') => {
  if (order === 'asc') {
    return arr.sort((a, b) => {
      if (callback(a) < callback(b)) {
        return -1
      }
      if (callback(a) > callback(b)) {
        return 1
      }
      return 0
    })
  }
  if (order === 'desc') {
    return arr.sort((a, b) => {
      if (callback(a) > callback(b)) {
        return -1
      }
      if (callback(a) < callback(b)) {
        return 1
      }
      return 0
    })
  }
}

export const pickBy = (obj, callback) => {
  const keys = Object.keys(obj)
  const values = Object.values(obj)
  return values.reduce((acc, curr, idx) => {
    if (callback(curr)) {
      return { ...acc, [keys[idx]]: curr }
    } else return acc
  }, {})
}

export const uniq = arr => [...new Set(arr)]

export const getProjectBathsAndBeds = (from, to) => {
  if (!from && !to) return null
  return from === to ? from : `${from} - ${to}`
}

export const isEmpty = obj => Object.keys(obj).length === 0

export const isNil = obj => obj === undefined || obj === null

export const getHoursDiff = (startDate, endDate) => {
  if (!startDate || !endDate) return null
  const msInHour = 1000 * 60 * 60
  return Math.round(Math.abs(endDate - startDate) / msInHour)
}
