export const accordionTheme = {
  parts: ['root', 'container', 'button', 'panel', 'icon'],
  baseStyle: {
    button: {
      padding: '20px 0px',
      fontFamily: 'Red Hat Text',
      fontSize: '15px',
      border: 0,
      outline: 0,
      _hover: {
        bg: 'none',
      },
      _focus: {
        boxShadow: 'none',
      },
    },
    container: {
      border: 0,
    },
    panel: {
      padding: '0px 0px 20px 0px',
      fontFamily: 'Red Hat Text',
      fontSize: '15px',
      border: 0,
    },
  },
  variants: {
    bordered: {
      container: {
        border: '1px solid #E2E8F0',
        borderRadius: '14px',
        padding: '0 20px',
      },
    },
  },
}
