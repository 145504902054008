import colorSchema from '../../color-schema'

export const radioTheme = {
  parts: ['control', 'label', 'container'],
  baseStyle: {
    label: {
      fontFamily: 'Red Hat Text, sans-serif',
      lineHeight: '20px',
      color: colorSchema.notBlack,
    },
    control: {
      border: '1px solid',
      borderColor: colorSchema.graphite,
      position: 'relative',
      _checked: {
        bg: colorSchema.white,
        borderColor: colorSchema.gpi,
        _before: {
          position: 'absolute',
          background: colorSchema.gpi,
          width: '10px',
          height: '10px',
        },
        _hover: {
          background: colorSchema.white,
          borderColor: colorSchema.gpi,
        },
        _focusVisible: {
          outline: 'none',
        },
      },
      _focus: {
        boxShadow: `0 0 0 3px ${colorSchema.pink}`,
      },
    },
  },
  sizes: {
    lg: {
      label: {
        fontSize: '15px !important',
      },
    },
  },
  defaultProps: {
    size: 'lg',
    colorSchema: 'red',
  },
}
