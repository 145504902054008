import styled, { keyframes } from 'styled-components'

import mundo from '../../assets/images/spinner-illustration-world.svg'

export const bounce = (rotation, coords, sizeUnit) => keyframes`
  0% {
    transform: translateY(0px) translateX(0px) rotate(${rotation * 45}deg);
    opacity: 0;
  }
  5% {
    transform: translateY(${coords[rotation].y}${sizeUnit}) translateX(${
  coords[rotation].x
}${sizeUnit}) rotate(${rotation * 45}deg) scale(1.2);
opacity: 1;
  }
  8%, 50% {
    transform: translateY(${coords[rotation].y}${sizeUnit}) translateX(${
  coords[rotation].x
}${sizeUnit}) rotate(${rotation * 45}deg) scale(1);
opacity: 1;
  }

  55%, 100% {
    transform: translateY(0px) translateX(0px) rotate(${rotation * 45}deg);
    opacity: 0;
  }
  
`

export const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`

export const Wrapper = styled.div`
  position: relative;
  height: ${({ size }) => size};
  width: ${({ size }) => size};
  margin: ${({ margin }) => margin};
  transform-origin: center center;
  border-radius: 100%;
  animation: ${rotate} 3s linear infinite;
`

export const World = styled.div`
  height: ${({ size }) => size};
  width: ${({ size }) => size};
  position: absolute;
  z-index: 10;
  border-radius: 100%;
  background-image: url(${mundo});
  background-repeat: no-repeat;
  background-size: contain;
`

export const House = styled.div`
  z-index: 1;
  height: ${({ height }) => height};
  width: ${({ width, sizeUnit }) => `${width}${sizeUnit}`};
  position: absolute;
  top: 0px;
  left: calc(50% - ${({ width, sizeUnit }) => `${width / 2}${sizeUnit}`});
  transform-origin: center bottom;
  animation: ${({ index, coordinates, sizeUnit }) =>
      bounce(index, coordinates, sizeUnit)}
    3s infinite linear ${({ index }) => 0.2 * index}s;
  > img {
    width: 100%;
  }
`
