import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { countryCodeSelector } from 'store/selectors/app'

const PrefixedLink = ({ to, disabled, children, omitPrefix = false, ...props }) => {
  const getFilteredProps = () => {
    const { ...rest } = props
    return rest
  }
  const countryCode = useSelector(countryCodeSelector)
  if (!to) {
    throw new Error(`Link "to" prop must be a string or an object with pathname. Instead it received: ${to}`)
  }
  const target = typeof to === 'object' ? to : { pathname: to }
  return disabled ? (
    children
  ) : (
    <Link
      to={{
        ...target,
        pathname: `${omitPrefix ? '' : '/' + countryCode}${target.pathname}`,
      }}
      className={props.className}
      {...getFilteredProps()}>
      {children}
    </Link>
  )
}

export default PrefixedLink
