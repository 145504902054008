import { combineReducers } from 'redux'

import {
  publishPropertyTypes,
  RESET_PUBLICATION_STATE,
  SET_AFTER_PUBLISHED_DATA,
  SET_COMMUNES,
  SET_CURRENT_STEP,
  SET_INFO_STEP,
  SET_IS_EDITING_PUBLICATION,
  SET_NEIGHBORHOODS,
  SET_PROVINCES,
  SET_REGIONS,
  SET_SCORE,
  TOGGLE_PUBLISH_CONFIRM_MODAL,
  TOGGLE_PUBLISH_SYNC,
  TOGGLE_USE_ADDRESS_AS_TITLE,
  TOGGLE_USE_CONTACT_INFO,
} from '../../actions/publication/types'
import formSlices from './formSlices'

const [PUBLISH_PROPERTY_REQUEST, PUBLISH_PROPERTY_SUCCESS, PUBLISH_PROPERTY_REJECT] = publishPropertyTypes
const initialState = {
  PUBLISH_PROPERTY_REQUEST: false,
  PUBLISH_PROPERTY_SUCCESS: false,
  PUBLISH_PROPERTY_REJECT: false,
  isEditing: false,
  isModalOpen: false,
  currentStep: 0,
  infoStep: 0,
  useContactInfo: false,
  useAddressAsTitle: true,
  syncWithLocalStorage: false,
  scorePercentages: { description: 0, features: 0, info: 0, photos: 0 },
  regions: [{}],
  communes: [{}],
  provinces: [{}],
  neighborhoods: [{}],
  afterPublishedData: {
    id: '',
    requiresConfirmation: undefined,
  },
}

const status = (state = initialState, action) => {
  switch (action.type) {
    case PUBLISH_PROPERTY_REQUEST:
      return { ...state, [action.type]: true }
    case PUBLISH_PROPERTY_SUCCESS:
      return { ...state, [action.type]: true, PUBLISH_PROPERTY_REQUEST: false }
    case PUBLISH_PROPERTY_REJECT:
      return { ...state, [action.type]: true, PUBLISH_PROPERTY_REQUEST: false }
    case SET_CURRENT_STEP:
      return { ...state, currentStep: action.payload }
    case TOGGLE_USE_CONTACT_INFO:
      return { ...state, useContactInfo: action.payload }
    case TOGGLE_PUBLISH_SYNC:
      return { ...state, syncWithLocalStorage: !state.syncWithLocalStorage }
    case TOGGLE_USE_ADDRESS_AS_TITLE:
      return { ...state, useAddressAsTitle: !state.useAddressAsTitle }
    case TOGGLE_PUBLISH_CONFIRM_MODAL:
      return { ...state, isModalOpen: !state.isModalOpen }
    case SET_AFTER_PUBLISHED_DATA:
      return { ...state, afterPublishedData: action.payload }
    case SET_INFO_STEP:
      return { ...state, infoStep: action.payload }
    case SET_IS_EDITING_PUBLICATION:
      return { ...state, isEditing: action.payload }

    case SET_SCORE:
      return {
        ...state,
        scorePercentages: {
          ...state.scorePercentages,
          [action.field]: action.payload,
        },
      }
    case SET_REGIONS:
      return {
        ...state,
        regions: action.payload,
        provinces: [{}],
        communes: [{}],
        neighborhoods: [{}],
      }
    case SET_PROVINCES:
      return {
        ...state,
        provinces: action.payload,
        communes: [{}],
        neighborhoods: [{}],
      }
    case SET_COMMUNES:
      return {
        ...state,
        communes: action.payload,
        neighborhoods: [{}],
      }
    case SET_NEIGHBORHOODS:
      return {
        ...state,
        neighborhoods: action.payload,
      }
    case RESET_PUBLICATION_STATE:
      return initialState
    default:
      return state
  }
}

export default combineReducers({
  status,
  formSlices,
})
