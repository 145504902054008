import styled from 'styled-components'
import colorSchema from 'styles/color-schema'
import { TABLET_RESPONSIVE_BREAK } from 'utils/constants'

export const SearchBar = styled.div`
  .mapboxgl-ctrl-geocoder {
    border-radius: 30px;
    border: 1px solid ${colorSchema.graphite3};
    box-shadow: 0px 5px 15px 0px rgba(32, 32, 32, 0.04);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding: 8px 20px;
    height: 40px;
    gap: 4px;
    width: 300px;
    z-index: 1300;
    & > svg {
      position: relative;
      left: unset;
      top: unset;
      width: 20px;
      height: 20px;
    }
    > input {
      outline: none;
      padding: 0;
      height: 20px;
    }
    > .mapboxgl-ctrl-geocoder--pin-right {
      > button {
        position: relative;
        top: unset;
        right: unset;
        > svg {
          margin-top: 0px;
        }
      }
    }
  }
  @media (max-width: ${TABLET_RESPONSIVE_BREAK}) {
    .mapboxgl-ctrl-geocoder {
      width: 100%;
    }
  }
`
