import { TABLET_RESPONSIVE_BREAK } from '../utils/constants'
import colorSchema from './color-schema'
import fonts from './fonts'

const defaultTheme = {
  colorSchema,
  fonts,
  color(colorKey) {
    return colorSchema[colorKey] || colorSchema.night
  },
  fontSize(fontKey, media = true) {
    const font = `
      font-size: ${fonts.regular[fontKey] || fonts.regular.h1}!important;
    `
    const mediaQuery = `@media (max-width: ${TABLET_RESPONSIVE_BREAK}) {
      font-size: ${fonts.mobile[fontKey] || fonts.mobile.h1}!important;
    }`
    return media ? font.concat(mediaQuery) : font
  },
}

export default defaultTheme
