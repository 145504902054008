import React from 'react'

import confused from '../../assets/images/error-boundary-image.png'
import { detectBrowser } from '../../utils/helpers'
import { Container, Header, Image, SubTitle, Title } from './styled-components'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      error: null,
      catched: null,
      catchedInfo: null,
    }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ catched: error, catchedInfo: errorInfo })
  }

  render() {
    if (this.state.hasError) {
      fetch(
        process.env.NODE_ENV === 'development'
          ? 'http://localhost:3030/errors'
          : 'https://stark-journey-99181.herokuapp.com/errors',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          mode: 'cors',
          body: JSON.stringify({
            url: window.location.href,
            type: this.state.error ? this.state.error.name : 'null',
            message: this.state.catched
              ? this.state.catched.toString()
              : this.state.error
              ? this.state.error.message
              : 'null',
            browser: detectBrowser(),
          }),
        }
      )
        .then(() => {})
        .catch(() => {})
      if (this.state.error.name === 'ChunkLoadError') {
        window.location.reload()
        return null
      }
      return (
        <Container>
          <Header>
            <div>
              <Image src={confused} />
              <Title>Ha ocurrido un error</Title>
            </div>
            <SubTitle>Hemos notificado a nuestro equipo. Por favor vuelve a intentarlo en unos minutos!</SubTitle>
            <button
              onClick={() => {
                window.location.href = 'https://www.goplaceit.com'
              }}>
              Volver
            </button>
          </Header>
        </Container>
      )
    } else {
      return this.props.children
    }
  }
}

export default ErrorBoundary
