import { countryIdToCode, invertDictionary } from '../../../utils/dictionaries'
import { handleError } from '../handlers'
import { baseUrl, getOptions } from '../utils'
import {
  getAllCurrenciesTypes,
  getRegionPolygonsTypes,
  SAVE_UTM,
  SET_COUNTRY_CODE,
  SET_IN_MAP_VIEW,
  SET_MOBILE_STATE,
  SET_RESPONSIVE_STATE,
  TOGGLE_MODAL,
} from './types'

export const saveUTM = utm => ({ type: SAVE_UTM, payload: utm })

export const calculateResponsiveState = () => (dispatch, getState) => {
  if (window.innerWidth <= 1199) {
    const {
      app: { isResponsive },
    } = getState()
    !isResponsive && dispatch({ type: SET_RESPONSIVE_STATE, payload: true })
  } else {
    const {
      app: { isResponsive },
    } = getState()
    isResponsive && dispatch({ type: SET_RESPONSIVE_STATE, payload: false })
  }
}

export const calculateMobileState = () => (dispatch, getState) => {
  if (window.innerWidth <= 450) {
    const {
      app: { isMobile },
    } = getState()
    !isMobile && dispatch({ type: SET_MOBILE_STATE, payload: true })
  } else {
    const {
      app: { isMobile },
    } = getState()
    isMobile && dispatch({ type: SET_MOBILE_STATE, payload: false })
  }
}

export const toggleModal = (id, to) => ({ type: TOGGLE_MODAL, id, to })

export const getAllCurrencies = () => dispatch => {
  const [req, suc, rej] = getAllCurrenciesTypes
  dispatch({ type: req })
  const timestamp = new Date()
  return fetch(`${baseUrl}currencies`, getOptions)
    .then(handleError)
    .then(res => res.json())
    .then(json => dispatch({ type: suc, payload: json, timestamp }))
    .catch(error => dispatch({ type: rej, error }))
}

export const getRegionPolygons = countryCode => dispatch => {
  const [req, suc, rej] = getRegionPolygonsTypes
  dispatch({ type: req })
  const codeToId = invertDictionary(countryIdToCode)
  return fetch(`${baseUrl}environment/regions/${codeToId[countryCode]}`)
    .then(handleError)
    .then(res => res.json())
    .then(json => dispatch({ type: suc, payload: json.regions }))
    .catch(error => dispatch({ type: rej, error }))
}

export const toggleInMapView = value => ({ type: SET_IN_MAP_VIEW, payload: value })

export const setCountry = countryCode => ({ type: SET_COUNTRY_CODE, payload: countryCode })
