import { HStack, Spinner } from '@chakra-ui/react'
import GPIButton from 'goplaceit-components-library/lib/GPIButton'
import useTranslation from 'i18n/useTranslation'
import capitalize from 'lodash.capitalize'
import styled from 'styled-components'

export const ButtonWrapper = styled.div`
  > button {
    height: 30px;
    padding: 0 20px;
    font-family: 'Poppins';
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    min-width: 165px;
  }
`

export const LoginButton = ({ isLoading, handleLoginModal }) => {
  const t = useTranslation({})
  return (
    <HStack>
      <ButtonWrapper>
        <GPIButton
          variant="secondaryBlack"
          text={isLoading ? <Spinner color="red" size="sm" /> : `${capitalize(t('registrate'))} o ${t('ingresa')}`}
          onClick={handleLoginModal}
        />
      </ButtonWrapper>
    </HStack>
  )
}
