import { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { SET_COUNTRY_CODE } from 'store/actions/app/types'
import { allowedCountries } from 'utils/constants'

export const RouteHelper = () => {
  const dispatch = useDispatch()
  const match = useRouteMatch()
  const history = useHistory()

  const allowedStarts = useMemo(
    () => ['propiedad', 'inmueble', 'proyecto', 'empresa', 'emprendimiento', 'desarrollo', 'asociados', '404'],
    []
  )

  useEffect(() => {
    const urlStart = match.url.split('/')[1]
    if (allowedStarts.includes(urlStart)) return
    if (urlStart && allowedCountries.includes(urlStart)) {
      dispatch({ type: SET_COUNTRY_CODE, payload: urlStart })
    } else {
      history.push('/404')
    }
  }, [allowedStarts, dispatch, history, match.url])

  return null
}
