import colorSchema from '../../color-schema'

export const drawerTheme = {
  parts: ['dialog', 'body'],
  variants: {
    rounded: {
      dialog: {
        borderRadius: '20px 0 0 0',
        maxWidth: '400px',
      },
      body: {
        mr: '4px!important',
        '&::-webkit-scrollbar': {
          width: '6px',
        },
        '&::-webkit-scrollbar-track': {
          background: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
          background: colorSchema.siberia,
          borderRadius: '8px',
        },
      },
    },
  },
}
