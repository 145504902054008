import { MAIN_MAP_RESPONSIVE_BREAK } from 'utils/constants'

const popupCommonStyles = `
  box-shadow: 0 2px 2px rgba(0,0,0,.5);
`

const mapStyle = color => `
@keyframes animate-pin {
  100% {
    transform: translateY(-20px);
  }
}

@keyframes grow-pin {
  100% {
    transform: scale(2);
  }
}

@keyframes hide-pointer {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@keyframes grow-map-pin {
  100% {
    transform: scale(1.5);
  }
}

.location-highlight {
  padding-bottom: 36px;
    > img {
      animation: 0.3s grow-map-pin ease-in 4 alternate;
      height: 24px;
      width: 24px;
    }
}

.mapboxgl-ctrl-logo {
    display: none !important;
}

.mapboxgl-ctrl-attrib-inner{
  display: none !important;
}

.marker {
  > div {
    ${popupCommonStyles}
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 12px;
    background-color: ${color('gpi')};
    height: 24px;
    width: 24px;
    &.single-property-marker {
      border: 4px solid ${color('gpi')};
      
      &.project-marker {
        border: 4px solid #3454d1;
        &:before {
          border-top: 4px solid #3454d1;
        }  
      }
      &.featured-marker {
        z-index: 1000;
        background-color: white;
        animation: 0.3s grow-pin ease-in 6 alternate;
        border: 4px solid ${color('notBlack')};
        &:before {
          animation: 0.3s hide-pointer ease-in 6 alternate;
          z-index: 0;
          border-top: 4px solid ${color('notBlack')};
        }  
      }
      border-radius: 100%;
      background-color: white;
      height: 12px;
      width: 12px;
    }

    &.grouped-marker-with-project {
      background-color: #3454d1;
      &:before {
        border-top: 4px solid #3454d1;
      }  
    }

    &.grouped-marker-with-featured {
      background-color: ${color('notBlack')};
      &:before {
        border-top: 4px solid ${color('notBlack')};
      }  
    }

    > p {
      color: white;
      font-weight: 600;
      margin: 0;
      font-size: 14px;
      font-family: 'Red Hat Text';
    }
    
    &:before {
      content: '';
      border: 4px solid transparent;
      border-top: 4px solid ${color('gpi')};
      bottom: -6px;
      left: calc(50% - 4px);
      position: absolute;
    }

    @media (max-width: ${MAIN_MAP_RESPONSIVE_BREAK}) {
      height: 30px;
      width: 30px;
      border-radius: 15px;
      &.single-property-marker {
        height: 18px;
        width: 18px;
        border-width: 6px;
        &.project-marker {
          border-width: 6px;
        }
        &.featured-marker {
          border-width: 6px;
        }
      }
    }
  }
}

.cluster {
  padding: 0;
  > div {
    ${popupCommonStyles}
    z-index: 2;
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
    cursor: pointer;
    padding: 0 15px;
    border-radius: 10px;
    border: 1px solid ${color('salmon')};
    background-color: ${color('artic')};
    color: ${color('salmon')};
  > p {
    text-align: center;
    font-weight: bold;
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Red Hat Text';
  }
  &:before {
    content: '';
    border: 4px solid transparent;
    border-top: 4px solid ${color('salmon')};
    bottom: -8px;
    left: calc(50% - 4px);
    position: absolute;
  }
  }
}

.mapboxgl-popup mapboxgl-popup-anchor-bottom {
  overflow: hidden;
}

.mapboxgl-popup {
  @media (max-width: ${MAIN_MAP_RESPONSIVE_BREAK}) {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 9999;
    padding-bottom: 10px;
    transform: translateX(0)!important;
  }
}

@keyframes slide-up {
  to {
    transform: translateY(0);
  }
}

.mapboxgl-popup-content {
  border-radius: 20px!important;
  padding: 0!important;
  overflow: auto!important;
  margin-bottom: 10px;
  box-shadow: none!important;
  display: flex;
  gap: 4px;
  width: 240px;
  max-width: 280px;
  scroll-snap-type: x mandatory;
  max-height: unset!important;
  @media (max-width: ${MAIN_MAP_RESPONSIVE_BREAK}) {
    width: 90vw;
    max-width: 350px;
    transform: translateY(100%);
    animation: 0.3s slide-up ease-in-out forwards;
  }
}
.mapboxgl-popup-content::-webkit-scrollbar {
  background-color: transparent;
  height: 14px;
}

.mapboxgl-popup-content::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 0 0 20px 20px;
  height: 7px; 
}

.mapboxgl-popup-content::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: #aaa;
  border: 2px solid #fff;
  height: 7px; 
  width: 40px; 
}

.mapboxgl-popup-tip {
  display: none;
}

.gpi-marker-popup-card-container {
  box-shadow: 0 0 1.5px rgba(0,0,0,.3) inset;
  scroll-snap-align: center;
  z-index: 300;
  position: relative;
  font-family: 'Red Hat Text';
  border-radius: 20px;
  background-color: ${color('white')};
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-width: 250px;
  min-height: 250px;

  @media (max-width: ${MAIN_MAP_RESPONSIVE_BREAK}) {
    width: 100%;
    min-width: 300px;
  }

  > .gpi-marker-popup-card-image {
    min-width: 100%;
    min-height: 150px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background-color: ${color('graphite')};
    > img {
      width: 250px;
      height: 150px;
      object-fit: scale-down;
      cursor: pointer;
        @media (max-width: ${MAIN_MAP_RESPONSIVE_BREAK}) {
          width: auto;
        }
    }
  }
  
  & .gpi-hidden-container {
    position: relative;
    width: 120px;
    height: 100%;
  }

  & .gpi-popup-card-hidden {
    position: absolute;
    border-radius: 5px;
    font-family: 'Red Hat Text';
    top: 10px;
    left: 10px;
    padding: 2px 10px;
    font-size: 12px;
    line-height: 15px;
    color: white;
    @media (max-width: ${MAIN_MAP_RESPONSIVE_BREAK}) {
      top: 12px;
      left: 12px;
      border-radius: 43px;
      padding: 8px 10px;
      font-size: 14px;
    }
  }

  & .popup-card-project {
    background-color: #3454d1;
  }
  & .popup-card-featured {
    background-color: #000;
  }



  &:last-child {
    margin-bottom: 0;
  }
  
  > .gpi-marker-popup-card-details {
    width: 100%;
    height: 100%;
    padding: 10px 12px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
    font-family: 'Red Hat Text';
    margin-bottom: 10px;

    > .gpi-marker-popup-card-type-and-mode {
      font-size: 12px;
      line-height: 15px;
      color: #AAA;
      @media (max-width: ${MAIN_MAP_RESPONSIVE_BREAK}) {
        color: ${color('notBlack')};
      }
    }

    > .gpi-marker-popup-card-price {
      color: ${color('notBlack')};
      font-size: 18px;
      font-weight: bold;
      line-height: 22px;
      @media (max-width: ${MAIN_MAP_RESPONSIVE_BREAK}) {
        font-size: 23px;
        line-height: 28px; 
        color: ${color('notBlack')};
      }
    }

    > .gpi-marker-popup-card-features {
      > span {
      font-size: 15px;
      line-height: 20px;
        > strong {
          font-weight: bold;
        }
      }
    }

    > .gpi-marker-responsive-popup-card-features {
      display: flex;
      gap: 15px;
      margin-top: 10px;
      margin-bottom: 20px;
      >.gpi-marker-feature {
        display: flex;
        gap: 5px;
        align-items: center;
        > img {
          height: 22px;
          width: 22px;
        }
        > span {
          font-size: 15px;
          line-height: 20px;
          color: ${color('notBlack')};
          font-weight: bold;
        }
      }
    }

  }
}

.gpi-marker-popup-card-contact-me-btn {
  width: 120px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border-radius: 32px;
  border: 1px solid #000;
  background: #FFF;
  padding: 4px 16px;
  font-weight: 600;
  font-size: 12px;
  align-self: flex-end;
  box-shadow: 0px 3px 5px 0px rgba(153, 153, 153, 0.20), 0px 1px 18px 0px rgba(153, 153, 153, 0.12), 0px 6px 10px 0px rgba(153, 153, 153, 0.14);
}

.gpi-main-map__neighbourhood-popup--container {
  width: 250px;
  height: 86px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  @media (max-width: ${MAIN_MAP_RESPONSIVE_BREAK}) {
    width: 100%;
    padding: 20px;
    background-color: white;
  }
  & .popup-title {
    margin: 0;
    margin-bottom: 10px;
  }
  & .popup-action-buttons-container {
    width: 100%;
    display: flex;
    justify-content: space-around;
    gap: 10px;
    
    > button {
      cursor: pointer;
      height: 30px;
      width: 100px;
      border-radius: 15px;
      outline: none;
      &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
      &.popup-draw-button {
        background-color: ${color('gpi')};
        border: 1px solid ${color('gpi')};
        color: white;
      }
      &.popup-zoom-in-button {
        color: ${color('night')};
        background-color: white;
        border: 1px solid ${color('siberia')};
      }
    }
  }
}

.service {
  > div {
    ${popupCommonStyles}
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 12px;
    background-color: ${color('purple')};
    height: 24px;
    width: 24px;
    color: white;
    > img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 100%;
    }
    &:before {
      content: '';
      border: 4px solid transparent;
      border-top: 4px solid ${color('purple')};
      bottom: -6px;
      left: calc(50% - 4px);
      position: absolute;
    }
  }
}

.gpi-main-map__service-popup--container {
  padding: 10px 24px;
  > .popup-title {
    margin: 7px 0;
  }
  > p {
    margin: 5px 0;
  }

  > small {
    margin: 5px 0;
  }
}

.mapboxgl-popup-content {
  border-radius: 10px;
  z-index: 300;
  width: fit-content;
  max-height: 235px;
  overflow-y: auto;
  position: relative;
  box-shadow: 1px 4px 6px rgba(0,0,0,.5);
  @media (max-width: ${MAIN_MAP_RESPONSIVE_BREAK}) {
    max-width: 90vw; 
    overflowX: auto;
    overflowY: hidden;
    box-shadow: none;
    background: transparent;
    display: flex;
    max-height: 306px;
    justify-content: flex-start;
  }
}

@media (max-width: ${MAIN_MAP_RESPONSIVE_BREAK}) {
  & .mapboxgl-ctrl-logo {
    display: none !important;
  }
  & .mapboxgl-ctrl-attrib {
    display: none !important;
  }
}
`

export default mapStyle
