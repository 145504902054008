const h1 = '30px'
const h2 = '24px'
const h3 = '20px'
const p = '15px'
const small = '12px'
const giant = '50px'
const family = "'Poppins', sans-serif"

const mobileH1 = '18px'
const mobileH2 = '16px'
const mobileh3 = '15px'
const mobileGiant = '35px'

const h1Healine = '70px'
const h2Healine = '57px'
const h3Healine = '46px'
const h4Healine = '30px'
const h5Healine = '23px'
const h6Healine = '18px'
const buttonMd = '15px'
const buttonSm = '12px'

const fonts = {
  regular: {
    h1,
    h2,
    h3,
    p,
    small,
    giant,
    family,
  },
  mobile: {
    h1: mobileH1,
    h2: mobileH2,
    h3: mobileh3,
    p,
    small,
    giant: mobileGiant,
    family,
  },
  sizes: {
    h1: h1Healine,
    h2: h2Healine,
    h3: h3Healine,
    h4: h4Healine,
    h5: h5Healine,
    h6: h6Healine,
    buttonMd,
    buttonSm,
  },
}

export default fonts
