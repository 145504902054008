import { createAsyncTypes } from '../utils'

export const SET_AVATAR = 'SET_AVATAR'
export const SET_USER_DATA = 'SET_USER_DATA'
export const SIGN_OUT_USER = 'SIGN_OUT_USER'
export const SET_USER_PAGINATED_RESOURCE = 'SET_USER_PAGINATED_RESOURCE'
export const SET_FAVORITE_IDS = 'SET_FAVORITE_IDS'
export const SET_PROJECT_FAVORITE_IDS = 'SET_PROJECT_FAVORITE_IDS'
export const UPDATE_PASSWORD_STATUS = 'UPDATE_PASSWORD_STATUS'

export const getUserTypes = createAsyncTypes('GET_USER')
export const getSubmitTypes = createAsyncTypes('USER_SUBMIT')
export const getAvatarTypes = createAsyncTypes('SUBMIT')
export const getProfileSectionTypes = createAsyncTypes('PROFILE_SECTION')
export const forgotPasswordTypes = createAsyncTypes('FORGOT_PASSWORD')
export const requestUnsubscribeTypes = createAsyncTypes('REQUEST_UNSUBSCRIBE')
