import { handleError } from './handlers'

export const baseUrl =
  process.env.REACT_APP_USE_API === 'local'
    ? process.env.REACT_APP_API_LOCAL_URL
    : process.env.REACT_APP_API_URL
// const baseUrl = 'http://10.10.120.134:3002/api/v4/'

export const createAsyncTypes = action => [
  `${action}_REQUEST`,
  `${action}_SUCCESS`,
  `${action}_REJECT`,
]

export const paginationQuery = (page = 1, perpage = 5) =>
  `?per_page=${perpage}&page=${page}`

export const queryfy = (paramsObject, withoutQuestionMark = false) => {
  const params = new URLSearchParams(paramsObject)
  return `${withoutQuestionMark ? '' : '?'}${params.toString()}`
}

export const recursiveQueryfy = (
  paramsObject,
  prefix = [],
  result = [],
  fromArray = false
) => {
  const keys = Object.keys(paramsObject)
  keys.forEach(key => {
    if (paramsObject[key] instanceof Array) {
      paramsObject[key] = recursiveQueryfy(
        paramsObject[key],
        prefix.length > 0
          ? [...prefix, `[${encodeURI(key)}]`]
          : [encodeURI(key)],
        result,
        true
      )
    } else if (
      typeof paramsObject[key] === 'object' &&
      paramsObject[key] !== null
    ) {
      paramsObject[key] = recursiveQueryfy(
        paramsObject[key],
        prefix.length > 0
          ? [...prefix, `[${encodeURI(key)}]`]
          : [encodeURI(key)],
        result
      )
    } else {
      result.push(
        prefix.length > 0
          ? `${prefix.join('')}[${fromArray ? '' : encodeURI(key)}]=${encodeURI(
              paramsObject[key]
            )}`
          : `${fromArray ? '' : encodeURI(key)}=${encodeURI(paramsObject[key])}`
      )
    }
  })
  return result
}

export const getOptions = {
  method: 'GET',
  mode: 'cors',
  credentials: 'include',
}

export const postOptions = {
  method: 'POST',
  mode: 'cors',
  credentials: 'include',
}

export const putOptions = {
  method: 'PUT',
  mode: 'cors',
  credentials: 'include',
}

export const deleteOptions = {
  method: 'DELETE',
  mode: 'cors',
  credentials: 'include',
}

export const getRequest = (
  endPoint,
  filters,
  asyncTypes = Array(3).fill(null),
  setPath = null,
  withAbort = false
) => dispatch => {
  const [req, suc, rej] = asyncTypes
  dispatch({ type: req })
  const query = filters ? `?${recursiveQueryfy(filters).join('&')}` : ''
  const options = {
    method: 'GET',
    mode: 'cors',
    credentials: 'include',
  }
  const hasAbortController = typeof AbortController !== 'undefined'
  let controller = { abort: () => {} }
  if (withAbort && hasAbortController) {
    controller = new AbortController()
    options.signal = controller.signal
  }
  const fetchPromise = fetch(`${baseUrl}${endPoint}${query}`, options)
    .then(handleError)
    .then(res => res.json())
    .then(json =>
      dispatch({ type: suc, payload: setPath ? json[setPath] : json })
    )
    .catch(error => dispatch({ type: rej, error }))
  if (withAbort) {
    return { fetchPromise, controller }
  }
  return fetchPromise
}

export const allowedBciPostTypes = {
  simulation: 'simulate_bci_widget',
  approval: 'approval_bci_widget',
}

export const bciSimulatorPost = (type, details) => {
  if (!allowedBciPostTypes[type]) {
    throw new Error(
      `type must be one of [${Object.keys(
        allowedBciPostTypes
      )}]. Received ${type} instead.`
    )
  }
  return fetch(`${baseUrl}credits/${allowedBciPostTypes[type]}`, {
    ...postOptions,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(details),
  }).then(handleError)
}
