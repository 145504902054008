import { createGlobalStyle } from 'styled-components'

import { TABLET_RESPONSIVE_BREAK } from '../utils/constants'
import mainMapStyle from './mainMapStyles'

const GlobalStyle = createGlobalStyle`
  *, *:before, *:after{
    box-sizing: border-box;
  }

  ${({
    theme: {
      colorSchema,
      fonts: { regular, mobile },
      color,
    },
  }) => `
  body {
    background: #fff;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    font-family: ${regular.family}!important;
    font-size: ${regular.p};
    color: ${color('notBlack')};
    display: flex;
    flex-direction: column;
      > #root{
      min-height: 100vh;
      display:flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
      }
  }

  a {
    text-decoration: none;
    color: ${colorSchema.night};
    &:hover {
      color: ${colorSchema.darkGpi};
    }
  }

  h1 {
    font-size: ${regular.h1};
    font-weight: bold;
    line-height: 1.2;
  }

  h2 {
    font-size: ${regular.h2};
    font-weight: bold;
    line-height: 1.2;
  }

  h3 {
    font-size: ${regular.h3};
    font-weight: bold;
    line-height: 1.2;
  }

  ::placeholder {
    font-family: ${regular.family};
    opacity: 0.5;
  }

  ${mainMapStyle(color, regular)}

  @media (max-width: ${TABLET_RESPONSIVE_BREAK}) {
    h1 {
      font-size: ${mobile.h1};
    }
  
    h2 {
      font-size: ${mobile.h2};
    }
  
    h3 {
      font-size: ${mobile.h3};
    }
  }
  @media (max-width: 768px) {
    .full-page-responsive {
      width: 95vw;
      border-radius: 0;
    }
  }
  `}
`

export default GlobalStyle
