import { createAsyncTypes } from '../utils'

// app types
export const SAVE_UTM = 'SAVE_UTM'
export const SET_RESPONSIVE_STATE = 'SET_RESPONSIVE_STATE'
export const SET_MOBILE_STATE = 'SET_MOBILE_STATE'
export const TOGGLE_MODAL = 'TOGGLE_MODAL'
export const SET_WILL_PRINT = 'SET_WILL_PRINT'
export const SET_IN_MAP_VIEW = 'SET_IN_MAP_VIEW'
export const SET_COUNTRY_CODE = 'SET_COUNTRY_CODE'
export const COUNTRY_CHANGE_FROM_NAVBAR = 'COUNTRY_CHANGE_FROM_NAVBAR'
export const TOGGLE_NAVIGATION = 'TOGGLE_NAVIGATION'
export const CLEAR_NAVIGATION = 'CLEAR_NAVIGATION'
export const CLEAR_REDIRECTION = 'CLEAR_REDIRECTION'
export const getAllCurrenciesTypes = createAsyncTypes('GET_ALL_CURRENCIES')
export const getRegionPolygonsTypes = createAsyncTypes('GET_REGION_POLYGONS')
