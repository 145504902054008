import { ReactComponent as BrandLogo } from 'assets/icons/gpi-icon-navbar-brand-logo.svg'
import { Collapse } from 'components/Collapse'
import useTranslation from 'i18n/useTranslation'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { getInMapView, getResponsiveState } from 'store/selectors/app'

import Links from './Links'
import {
  Block,
  Bottom,
  Line,
  LinksGroup,
  MainContent,
  PopularLinks,
  SocialNetworks,
  StyledFooter,
} from './styled-components'
import { countryLinks, enterpriseLinks, resourcesLinks, socialNetworks, utilLinks } from './utils'

const currentYear = new Date().getFullYear()

const Footer = () => {
  const isInMap = useSelector(getInMapView)
  const isResponsive = useSelector(getResponsiveState)
  const t = useTranslation({})
  const { pathname } = useLocation()
  const countryCode = pathname.split('/')[1]
  const notShow = ['/404'].includes(pathname) || !countryCode || isInMap

  return (
    !notShow && (
      <StyledFooter id="gpi-footer">
        <MainContent>
          <Block>
            <BrandLogo className="logo" />
            <span className="titles">Nuestras redes</span>
            <SocialNetworks>
              {socialNetworks.map(({ href, icon: Icon, label }) => (
                <a key={label} href={href} target="_blank" rel="noopener noreferrer" aria-label={label}>
                  <Icon />
                </a>
              ))}
            </SocialNetworks>
          </Block>
          {isResponsive ? (
            <>
              <Collapse title="Enlaces útiles">
                <Links id="footer-links-1" links={utilLinks(t)} />
              </Collapse>
              <Collapse title="Nuestra empresa">
                <Links id="footer-links-2" links={enterpriseLinks} />
              </Collapse>
              <Collapse title="Recursos">
                <Links id="footer-links-3" links={resourcesLinks} />
              </Collapse>
            </>
          ) : (
            <LinksGroup>
              <Block>
                <span className="titles">Enlaces útiles</span>
                <Links id="footer-links-1" links={utilLinks(t)} />
              </Block>
              <Block>
                <span className="titles">Nuestra empresa</span>
                <Links id="footer-links-2" links={enterpriseLinks} />
              </Block>
              <Block>
                <span className="titles">Recursos</span>
                <Links id="footer-links-3" links={resourcesLinks} />
              </Block>
            </LinksGroup>
          )}
          <LinksGroup>
            <Block>
              <span className="titles">Búsquedas populares</span>
              <PopularLinks>
                <Links id="footer-links-4" links={countryLinks(countryCode, t).slice(0, 4)} />
                <Links id="footer-links-4" links={countryLinks(countryCode, t).slice(4)} />
              </PopularLinks>
            </Block>
          </LinksGroup>
        </MainContent>
        <Line />
        <Bottom>
          <span>Dudas a hello@goplaceit.com</span>
          <span>© 2012 - {currentYear}, goplaceit SpA. Todos los derechos reservados.</span>
        </Bottom>
      </StyledFooter>
    )
  )
}

export default Footer
