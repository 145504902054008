import { createSelector } from 'reselect'

export const getPublicationStatus = createSelector(
  state => state.publication.status,
  status => status
)

export const getCurrentStep = createSelector(
  state => state.publication.status.currentStep,
  step => step
)

export const getSlice = slice =>
  createSelector(
    state => state.publication.formSlices[slice],
    requiredSlice => requiredSlice
  )

export const getPathKeys = createSelector(
  state => state.publication.formSlices.publicationType.type,
  state => state.publication.formSlices.propertyType.type,
  (publicationType, propertyType) => ({ publicationType, propertyType })
)

export const getInfoStep = createSelector(
  state => state.publication.status.infoStep,
  infoStep => infoStep
)

export const getAllSlices = createSelector(
  state => state.publication.formSlices,
  slices => slices
)

export const getScores = createSelector(
  state => state.publication.status.scorePercentages,
  percentages => percentages
)

export const getRegions = createSelector(
  state => state.publication.status.regions,
  regions => regions
)
export const getCommunes = createSelector(
  state => state.publication.status.communes,
  communes => communes
)

export const getProvinces = createSelector(
  state => state.publication.status.provinces,
  provinces => provinces
)
export const getNeighborhoods = createSelector(
  state => state.publication.status.neighborhoods,
  neighborhoods => neighborhoods
)
export const getPublishRequestStatus = createSelector(
  state => state.publication.status.PUBLISH_PROPERTY_REQUEST,
  state => state.publication.status.PUBLISH_PROPERTY_SUCCESS,
  state => state.publication.status.PUBLISH_PROPERTY_REJECT,
  (req, suc, rej) => ({ req, suc, rej })
)

export const isEditingSelector = createSelector(
  state => state.publication.status.isEditing,
  isEditing => isEditing
)
