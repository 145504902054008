import { batch } from 'react-redux'

// Fetch error handler:
export const handleError = (response, with404Redirection = false) => {
  if (with404Redirection && response.status === 404) {
    window.location.replace('/404')
    return response
  }
  if (!response.ok) {
    throw new Error(`{"hasError":true,"status":${response.status},"url":"${response.url}"}`)
  }
  return response
}

export const catchHandler = (dispatch, rejectType) => error => {
  let response
  try {
    response = JSON.parse(error.message)
  } catch (err) {
    response = { hasError: true, status: 'unknown', url: 'unknown' }
    console.log(error)
  }

  // If request failed due to missing authentication, unauthenticate stale user
  // unless request url is users/me (initial cookie authentication attempt)
  if (response.status === 401 && !response.url.match(/\/users\/me$/)) {
    batch(() => {
      dispatch({ type: 'TOGGLE_NAVIGATION', payload: '/' })
      dispatch({ type: 'SIGN_OUT_USER' })
    })
  }

  if (rejectType) {
    dispatch({ type: rejectType, status: response.status })
  }
  return response
}
