import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Text } from '@chakra-ui/react'
import colorSchema from 'styles/color-schema'

export const ResponsiveAccordion = ({ title, options, ItemComponent, onClose }) => (
  <Accordion w="full" allowToggle>
    <AccordionItem>
      {({ isExpanded }) => (
        <>
          <AccordionButton p="10px 0">
            <Text
              w="full"
              textAlign="left"
              fontSize="15px"
              lineHeight="20px"
              fontWeight={700}
              color={colorSchema.notBlack}>
              {title}
            </Text>
            <AccordionIcon
              boxSize="30px"
              color={colorSchema.gpi}
              transform={isExpanded ? 'rotate(0deg)' : 'rotate(90deg)'}
            />
          </AccordionButton>
          <AccordionPanel p="0px!important">
            {options.map(({ label, to, action }) => (
              <Box key={label} onClick={onClose}>
                <ItemComponent key={label} label={label} to={to} action={action} />
              </Box>
            ))}
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  </Accordion>
)
