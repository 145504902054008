import { createAsyncTypes } from '../utils'

export const SET_CONTACTED_PROJECT = 'SET_CONTACTED_PROJECT'
export const SET_MODAL_SLIDER_INDEX = 'SET_MODAL_SLIDER_INDEX'
export const SET_MODEL_IMAGE_FOR_MODAL = 'SET_MODEL_IMAGE_FOR_MODAL'
export const SET_PROJECT_IMAGES_FOR_MODAL = 'SET_PROJECT_IMAGES_FOR_MODAL'
export const SET_PREVIOUS_MODEL = 'SET_PREVIOUS_MODEL'
export const CLEAN_PROJECT_DATA = 'CLEAN_PROJECT_DATA'
export const getProjectPricingTypes = createAsyncTypes('GET_PROJECT_PRICING')
export const getProjectTypes = createAsyncTypes('GET_PROJECT')
