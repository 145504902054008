import { addPrefixArray, addRegexpPrefix } from 'utils/routes'

export const authRoutes = {
  profile: addRegexpPrefix('/perfil'),
  publish: addRegexpPrefix('/publicacion'),
  editPublication: addRegexpPrefix('/publicacion/:propertyID/editar'),
}

export const authRoutesRegex = {
  profile: /^\/(ar|cl|pe|uy|co|mx)\/perfil/,
  publish: /^\/(ar|cl|pe|uy|co|mx)\/publicacion/,
}

export const commons = {
  countryLanding: addPrefixArray([
    '/:type(casa|departamento|apartamento|oficina|propiedad|inmueble|terreno|estacionamiento|bodega)/:mode(venta|arriendo|renta|alquiler)',
  ]),
  commune: addPrefixArray([
    '/:type(casa|departamento|apartamento|oficina|propiedad|inmueble|terreno|estacionamiento|bodega)/:mode(venta|arriendo|renta|alquiler)/:location(departamento|depto|region|provincia|comuna|barrio|colonia|propiedad|estado|municipio|inmueble|localidad|distrito|urbanizacion|zona|parroquia|ciudad)/:id',
  ]),
  propertyProfile: addPrefixArray([
    '/:property(propiedad|inmueble)/:mode(venta|arriendo|alquiler|renta)/:type(casa|departamento|apartamento|oficina|terreno|estacionamiento|bodega)/:commune([\\w-\\dñ]+)?/:id',
  ]),
  projectProfile: addRegexpPrefix('/:project(proyecto|desarrollo|emprendimiento)/:commune([\\w-\\dñ]+)/:id'),
  enterprise: addRegexpPrefix('/empresa/:id'),
  mainMap: addRegexpPrefix('/mapa'),
  landing: addRegexpPrefix('/'),
  passwordRecovery: addRegexpPrefix('/recuperar_password'),
  publish: addRegexpPrefix('/publicar'),
  publishFAQ: addRegexpPrefix('/publicar/preguntas-frecuentes'),
  faqs: addRegexpPrefix('/preguntas-frecuentes'),
  aboutUs: addRegexpPrefix('/acerca-de-goplaceit'),
  termsAndConditions: addRegexpPrefix('/terminos-y-condiciones'),
  termsAndConditionsPrivacy: addRegexpPrefix('/terminos-y-condiciones/privacidad'),
  unsubscribe: addRegexpPrefix('/unsubscribe/:hash'),
  notFound: '/404',
  deletedCountry: '/despedida',
  financing: addRegexpPrefix('/financiamiento/:enterprise'),
  paz: addRegexpPrefix('/inversion'),
}
