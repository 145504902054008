import {
  getClosestLocationsTypes,
  getLocationInfoTypes,
  getLocationPropertiesTypes,
} from '../actions/locationLanding/types'

const [GET_LOCATION_INFO_REQUEST, GET_LOCATION_INFO_SUCCESS, GET_LOCATION_INFO_REJECT] = getLocationInfoTypes
const [GET_LOCATION_PROPERTIES_REQUEST, GET_LOCATION_PROPERTIES_SUCCESS, GET_LOCATION_PROPERTIES_REJECT] =
  getLocationPropertiesTypes

const [GET_CLOSEST_LOCATIONS_REQUEST, GET_CLOSEST_LOCATIONS_SUCCESS, GET_CLOSEST_LOCATIONS_REJECT] =
  getClosestLocationsTypes

const initialState = {
  GET_LOCATION_INFO_REQUEST: false,
  GET_LOCATION_INFO_SUCCESS: false,
  GET_LOCATION_INFO_REJECT: false,
  GET_LOCATION_PROPERTIES_REQUEST: false,
  GET_LOCATION_PROPERTIES_SUCCESS: false,
  GET_LOCATION_PROPERTIES_REJECT: false,
  GET_CLOSEST_LOCATIONS_REQUEST: false,
  GET_CLOSEST_LOCATIONS_SUCCESS: false,
  GET_CLOSEST_LOCATIONS_REJECT: false,
  locationInfo: { ubicacion: {} },
  locationProperties: [],
  pagination: {},
  closestLocations: [],
  communePrice: undefined,
}

const locationLanding = (state = initialState, action) => {
  switch (action.type) {
    /*LOCATION INFO REDUCERS*/
    case GET_LOCATION_INFO_REQUEST:
      return {
        ...state,
        [action.type]: true,
        GET_LOCATION_INFO_SUCCESS: false,
        GET_LOCATION_INFO_REJECT: false,
      }
    case GET_LOCATION_INFO_SUCCESS:
      return {
        ...state,
        [action.type]: true,
        GET_LOCATION_INFO_REQUEST: false,
        locationInfo: action.payload,
        communePrice: action.payload.precio_comuna,
      }
    case GET_LOCATION_INFO_REJECT:
      return {
        ...state,
        [action.type]: true,
        GET_LOCATION_INFO_REQUEST: false,
        error: action.error,
      }

    /*LOCATION PROPERTIES REDUCERS*/
    case GET_LOCATION_PROPERTIES_REQUEST:
      return {
        ...state,
        [action.type]: true,
        GET_LOCATION_PROPERTIES_SUCCESS: false,
        GET_LOCATION_PROPERTIES_REJECT: false,
      }
    case GET_LOCATION_PROPERTIES_SUCCESS:
      return {
        ...state,
        [action.type]: true,
        GET_LOCATION_PROPERTIES_REQUEST: false,
        locationProperties: action.payload.response,
        pagination: action.payload.meta.pagination,
      }
    case GET_LOCATION_PROPERTIES_REJECT:
      return {
        ...state,
        [action.type]: true,
        GET_LOCATION_PROPERTIES_REQUEST: false,
        error: action.error,
      }

    /*CLOSEST LOCATIONS REDUCERS*/
    case GET_CLOSEST_LOCATIONS_REQUEST:
      return {
        ...state,
        [action.type]: true,
        GET_CLOSEST_LOCATIONS_SUCCESS: false,
        GET_CLOSEST_LOCATIONS_REJECT: false,
      }
    case GET_CLOSEST_LOCATIONS_SUCCESS:
      return {
        ...state,
        [action.type]: true,
        GET_CLOSEST_LOCATIONS_REQUEST: false,
        closestLocations: action.payload,
      }
    case GET_CLOSEST_LOCATIONS_REJECT:
      return {
        ...state,
        [action.type]: true,
        GET_CLOSEST_LOCATIONS_REQUEST: false,
        error: action.error,
      }
    default:
      return state
  }
}

export default locationLanding
