import { Button, HStack, Popover, PopoverContent, PopoverTrigger, Text, useDisclosure, VStack } from '@chakra-ui/react'
import { ChevronAbajo, Chile, Mexico } from 'goplaceit-icons'
import { Fragment } from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { COUNTRY_CHANGE_FROM_NAVBAR, SET_COUNTRY_CODE } from 'store/actions/app/types'
import { countryCodeSelector } from 'store/selectors/app'
import colorSchema from 'styles/color-schema'

const options = [
  { label: 'Chile', code: 'cl', icon: <Chile width="18px" /> },
  { label: 'México', code: 'mx', icon: <Mexico width="18px" /> },
]

const Item = ({ option, external, selected, onClick }) => (
  <Text
    fontSize="15px"
    lineHeight="20px"
    as="button"
    onClick={() => (external ? onClick(option.href) : onClick(option.code))}
    fontWeight={selected ? 700 : 400}
    textAlign="left"
    p="8px 16px"
    borderRadius="16px"
    w="full"
    _hover={{
      bg: colorSchema.artic,
    }}>
    {option.label}
  </Text>
)

export const CountrySelector = () => {
  const currentCountry = useSelector(countryCodeSelector)
  const history = useHistory()
  const dispatch = useDispatch()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const externalLinks = [
    {
      label: 'Estados Unidos',
      href: `https://${currentCountry}-international.goplaceit.com/estados-unidos/comprar`,
    },
    {
      label: 'España',
      href: `https://${currentCountry}-international.goplaceit.com/espana/comprar`,
    },
    {
      label: 'Otros países',
      href: `https://${currentCountry}-international.goplaceit.com/`,
    },
  ]

  const handleChangeCountry = code => {
    history.push(`/${code}/`)
    batch(() => {
      dispatch({ type: SET_COUNTRY_CODE, payload: code })
      dispatch({ type: COUNTRY_CHANGE_FROM_NAVBAR, payload: true })
    })
    onClose()
  }

  const handleExternalLink = link => {
    window.open(link, '_blank')
    onClose()
  }

  return (
    <Popover variant="navbar" isOpen={isOpen} onClose={onClose}>
      <PopoverTrigger>
        <Button
          variant="unstyled"
          w="50px"
          h="30px"
          border={`1px solid ${colorSchema.siberia}`}
          bg={colorSchema.artic}
          borderRadius="30px"
          onClick={onOpen}
          _focus={{
            outline: 'none',
          }}>
          <HStack align="center" justify="center" gap="4px" spacing={0}>
            {options.find(option => option.code === currentCountry)?.icon}
            <ChevronAbajo color={colorSchema.notBlack} width="8px" strokeWidth={3} />
          </HStack>
        </Button>
      </PopoverTrigger>
      <PopoverContent width="max-content">
        <VStack align="flex-start">
          {options.map(option => (
            <Fragment key={option.label}>
              <Item option={option} onClick={handleChangeCountry} selected={option.code === currentCountry} />
            </Fragment>
          ))}
          {externalLinks.map(option => (
            <Fragment key={option.label}>
              <Item option={option} onClick={handleExternalLink} external />
            </Fragment>
          ))}
        </VStack>
      </PopoverContent>
    </Popover>
  )
}
