import colorSchema from 'styles/color-schema'

const navbar = {
  content: {
    p: '16px 12px',
    borderRadius: '8px',
    border: `1px solid ${colorSchema.graphite}!important`,
    boxShadow: '0px 5px 15px 0px rgba(32, 32, 32, 0.20)',
  },
}

export const popoverTheme = {
  parts: ['content', 'body', 'popper'],
  variants: {
    navbar,
  },
  baseStyle: {
    content: {
      p: '10px ',
      borderRadius: '20px',
      border: '1px solid #000!important',
      boxShadow: '0px 5px 15px 0px rgba(32, 32, 32, 0.20)',
      transition: 'all 0.2s ease',
      _focus: {
        boxShadow: 'none',
      },
    },
  },
}
