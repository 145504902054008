import { Text, VStack } from '@chakra-ui/react'
import Link from 'components/PrefixedLink'
import useTranslation from 'i18n/useTranslation'
import { Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { signOutUser } from 'store/actions/user/creators'
import { getUserName } from 'store/selectors/user'
import colorSchema from 'styles/color-schema'

import { getNavLinks } from '../navLinks'
import { Item as UserItem } from './LoggedMenu'
import { Item as MenuItem } from './OptionsMenu'
import { ResponsiveAccordion } from './ResponsiveAccordion'

export const ResponsiveMenu = ({ isLogged, handleLoginModal, onClose }) => {
  const userName = useSelector(getUserName)
  const dispatch = useDispatch()
  const t = useTranslation({})

  const userOptions = [
    { label: 'Mi cuenta', to: '/perfil' },
    {
      label: 'Mis zonas',
      to: {
        pathname: '/mapa',
        state: {
          actionType: 'selectUserFirstZone',
          referrer: window.location.href,
        },
        search: '',
      },
    },
    { label: 'Propiedades favoritas', to: '/perfil/mis-favoritos' },
    { label: 'Cerrar sesión', action: () => dispatch(signOutUser()) },
  ]

  const firstName = userName?.split(' ')[0]

  return (
    <VStack justify="flex-start" align="start" p="0">
      {isLogged ? (
        <ResponsiveAccordion
          title={`Hola, ${firstName}`}
          options={userOptions}
          ItemComponent={UserItem}
          onClose={onClose}
        />
      ) : (
        <Text
          fontSize="15px"
          lineHeight="20px"
          fontWeight={700}
          color={colorSchema.notBlack}
          as="button"
          outline="none"
          onClick={handleLoginModal}>
          Regístrate o ingresa
        </Text>
      )}
      {getNavLinks(t).map(({ label, href, contents }) => (
        <Fragment key={label}>
          {!href ? (
            <ResponsiveAccordion
              key={label}
              title={label}
              options={contents}
              ItemComponent={MenuItem}
              onClose={onClose}
            />
          ) : (
            <Link to={href} style={{ marginTop: 0, width: '100%' }}>
              <Text
                as="button"
                key={label}
                fontSize="15px"
                lineHeight="20px"
                fontWeight={700}
                textAlign="left"
                pt="25px"
                onClick={onClose}
                w="full">
                {label}
              </Text>
            </Link>
          )}
        </Fragment>
      ))}
    </VStack>
  )
}
