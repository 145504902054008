import { bucketURL } from 'utils/constants'

import { House, World, Wrapper } from './styled-components'

const spinner_building = `${bucketURL}/landings/spinner/spinner-image-building.webp`
const spinner_thick_tree = `${bucketURL}/landings/spinner/spinner-image-thick-tree.webp`
const spinner_dog = `${bucketURL}/landings/spinner/spinner-image-dog.webp`
const spinner_house = `${bucketURL}/landings/spinner/spinner-image-house.webp`
const spinner_lamp_post = `${bucketURL}/landings/spinner/spinner-image-lamp-post.webp`
const spinner_park = `${bucketURL}/landings/spinner/spinner-image-park.webp`
const spinner_city_hall = `${bucketURL}/landings/spinner/spinner-image-city-hall.webp`
const spinner_tree = `${bucketURL}/landings/spinner/spinner-image-tree.webp`

const getCoords = radius => {
  const popDistance = radius * 0.42
  const offset = popDistance / Math.sqrt(2)
  return [
    { y: -popDistance, x: 0, image: spinner_building },
    { y: -offset, x: offset, image: spinner_thick_tree },
    { y: 0, x: popDistance, image: spinner_dog },
    { y: offset, x: offset, image: spinner_house },
    { y: popDistance, x: 0, image: spinner_lamp_post },
    { y: offset, x: -offset, image: spinner_park },
    { y: 0, x: -popDistance, image: spinner_city_hall },
    { y: -offset, x: -offset, image: spinner_tree },
  ]
}

const Spinner = ({ size = '300px', margin }) => {
  const regexpMatch = size.match(/^(\d+)(\D+)$/)
  const sizeAmount = parseInt(regexpMatch[1])
  const sizeUnit = regexpMatch[2]
  const radius = sizeAmount / 2
  const coords = getCoords(radius)

  return (
    <Wrapper size={size} margin={margin}>
      <World size={size} />
      {coords.map((ele, i) => (
        <House
          index={i}
          key={i}
          coordinates={coords}
          sizeUnit={sizeUnit}
          height={`${radius}${sizeUnit}`}
          width={Math.floor(radius * 0.5334)}>
          <img loading="lazy" src={ele.image} alt={`spinner-element-${i}`} />
        </House>
      ))}
    </Wrapper>
  )
}

export default Spinner
