import Button from 'goplaceit-components-library/lib/Button'
import SmallSpinner from 'goplaceit-components-library/lib/SmallSpinner'
import TextInput from 'goplaceit-components-library/lib/TextInput'
import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { toggleModal } from 'store/actions/app/creators'
import { CLEAR_REDIRECTION } from 'store/actions/app/types'
import { authUserWithFacebook, getUser, recoverUserPassword, registerUser } from 'store/actions/user/creators'
import { forgotPasswordRequestSelector, getUserAuthState } from 'store/selectors/user'

import { ReactComponent as BrandLogo } from '../../assets/icons/gpi-icon-navbar-brand-logo.svg'
import useTranslation from '../../i18n/useTranslation'
import { capitalize } from '../../utils/string'
import { scriptsContext } from '../ScriptInitializers/ScriptInitializers'
import { ErrorText, Separator, SignInText, StyledLogin, Subtitle, TextField } from './styled-components'

const Login = ({ isLoading, sendButtonStyle }) => {
  const GA = useContext(scriptsContext).ReactGA
  const history = useHistory()
  const isLoggedIn = useSelector(getUserAuthState)
  const forgotReq = useSelector(forgotPasswordRequestSelector)
  const onCloseRedirectTo = useSelector(state => state.modal.to)
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [password, setPassword] = useState('')
  const [loginPath, setLoginPath] = useState('LOGIN')
  const [errorMsg, setErrorMsg] = useState(null)
  const [recoverMailSent, setRecoverMailSent] = useState(false)
  const t = useTranslation({})
  const dispatch = useDispatch()

  const handleRedirectAfterLogin = () => {
    if (onCloseRedirectTo) {
      history.push(onCloseRedirectTo)
      dispatch({ type: CLEAR_REDIRECTION })
    }
  }

  const handleFacebookAuth = () =>
    window.FB?.login(
      function (response) {
        if (response.status === 'connected') {
          dispatch(
            authUserWithFacebook({
              access_token: response.authResponse.accessToken,
              uid_facebook: response.authResponse.userID,
              GA,
            })
          )
        }
      },
      { scope: 'public_profile,email' }
    )

  const handleSubmit = e => {
    e.preventDefault()
    setErrorMsg(null)
    switch (loginPath) {
      case 'LOGIN':
        dispatch(getUser({ email, password, GA })).then(r => {
          if (r.hasError) {
            switch (r.status) {
              case 409:
                setErrorMsg('Email o contraseña inválidos')
                break

              default:
                setErrorMsg('Ha ocurrido un error inesperado.')
                break
            }
          }
        })
        break
      case 'REGISTER':
        dispatch(registerUser({ email, password, name, GA })).then(r => {
          if (r.hasError) {
            switch (r.status) {
              case 409:
                setErrorMsg('Email ya está en uso')
                break

              default:
                setErrorMsg('Ha ocurrido un error inesperado.')
                break
            }
          }
        })
        break
      case 'RECOVER_PASSWORD':
        dispatch(recoverUserPassword(email)).then(r => {
          if (r.hasError) {
            switch (r.status) {
              case 409:
                setErrorMsg('Error en la solicitud')
                break
              case 404:
                setErrorMsg('Email no se encuentra registrado')
                break
              default:
                setErrorMsg('Ha ocurrido un error inesperado.')
                break
            }
          } else {
            setRecoverMailSent(true)
          }
        })
        break
      default:
        break
    }
  }

  const handleInputChange = setter => e => {
    e.persist()
    setter(e.target.value)
  }

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(toggleModal())
      handleRedirectAfterLogin()
    }
  }, [isLoggedIn]) //eslint-disable-line

  const fields = {
    subtitle: () => {
      switch (loginPath) {
        case 'LOGIN':
          return `${capitalize(t('inicia'))} sesión para ingresar`

        case 'REGISTER':
          return `${capitalize(t('ingresa'))} tus datos para completar el registro`

        case 'RECOVER_PASSWORD':
          return recoverMailSent
            ? `¡Listo! te hemos enviado un correo para que puedas cambiar tu contraseña`
            : `${capitalize(
                t('ingresa')
              )} tu email y te enviaremos un correo con las instrucciones para recuperar tu contraseña`
        default:
          return undefined
      }
    },
    signInText: () => {
      switch (loginPath) {
        case 'LOGIN':
          return (
            <SignInText>
              O {t('registrate')} <span onClick={() => setLoginPath('REGISTER')}>aquí</span>
            </SignInText>
          )

        case 'REGISTER':
          return (
            <SignInText>
              O {t('inicia')} sesión <span onClick={() => setLoginPath('LOGIN')}>aquí</span>
            </SignInText>
          )

        case 'RECOVER_PASSWORD':
          return (
            <SignInText>
              <span onClick={() => setLoginPath('LOGIN')}>Volver</span>
            </SignInText>
          )
        default:
          return undefined
      }
    },
    additionalFields: () => {
      switch (loginPath) {
        case 'LOGIN':
          return null
        case 'REGISTER':
          return (
            <TextField>
              <TextInput
                id="name"
                name="name"
                disabled={isLoading}
                placeholder="Nombre"
                value={name}
                onChange={handleInputChange(setName)}
              />
            </TextField>
          )

        case 'RECOVER_PASSWORD':
          return null
        default:
          return null
      }
    },
  }

  return (
    <StyledLogin {...sendButtonStyle}>
      <BrandLogo />
      <Subtitle>{fields.subtitle(t)}</Subtitle>
      {fields.signInText()}
      <form onSubmit={handleSubmit}>
        {fields.additionalFields()}
        <TextField>
          <TextInput
            id="email"
            name="email"
            placeholder="Email"
            type="email"
            disabled={isLoading}
            value={email}
            onChange={handleInputChange(setEmail)}
          />
        </TextField>
        {loginPath !== 'RECOVER_PASSWORD' && (
          <TextField>
            <TextInput
              id="password"
              placeholder="Contraseña"
              name="password"
              type="password"
              disabled={isLoading}
              value={password}
              onChange={handleInputChange(setPassword)}
            />
          </TextField>
        )}
        {errorMsg && <ErrorText>{errorMsg}</ErrorText>}
        <Button className="submit-btn" type="submit" disabled={isLoading || forgotReq} width="100%">
          {isLoading || forgotReq ? (
            <SmallSpinner color="white" />
          ) : loginPath === 'LOGIN' ? (
            'Inicia sesión'
          ) : loginPath === 'RECOVER_PASSWORD' ? (
            'Recuperar'
          ) : (
            'Regístrate'
          )}
        </Button>
      </form>
      <Separator />
      <Button width="100%" onClick={handleFacebookAuth}>
        {capitalize(t('ingresa'))} con facebook
      </Button>
      {loginPath === 'LOGIN' && (
        <p onClick={() => setLoginPath('RECOVER_PASSWORD')} className="gpi-login-modal-recover-password">
          Olvidé mi contraseña
        </p>
      )}
    </StyledLogin>
  )
}

export default Login
