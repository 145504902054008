import footerBackground from 'assets/images/footer-background.svg'
import footerBackgroundResponsive from 'assets/images/footer-background-responsive.svg'
import styled from 'styled-components'
import { TABLET_RESPONSIVE_BREAK } from 'utils/constants'

export const StyledFooter = styled.footer`
  background: url(${footerBackground}) no-repeat;
  background-size: cover;
  color: ${({ theme: { color } }) => color('notBlack')};
  padding: 50px 50px 10px 50px;
  position: relative;
  z-index: 9999;
  @media (max-width: ${TABLET_RESPONSIVE_BREAK}) {
    background: url(${footerBackgroundResponsive}) no-repeat;
    background-size: cover;
    padding: 100px 20px 10px 20px;
  }
`

export const Line = styled.div`
  background: ${({ theme: { color } }) => color('graphite2')};
  height: 1px;
`

export const Bottom = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  > span {
    color: ${({ theme: { color } }) => color('graphite2')};
    font-family: 'Red Hat Text', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.3;
  }
  @media (max-width: ${TABLET_RESPONSIVE_BREAK}) {
    flex-direction: column;
  }
`

export const MainContent = styled.div`
  display: flex;
  justify-content: space-between;
  .logo {
    width: 200px;
  }
  .titles {
    color: ${({ theme: { color } }) => color('notBlack')};
    font-family: 'Red Hat Text', sans-serif;
    font-size: 12px;
    font-weight: 700;
    line-height: 130%;
    margin-bottom: 10px;
    @media (max-width: ${TABLET_RESPONSIVE_BREAK}) {
      font-size: 15px;
    }
  }
  @media (max-width: ${TABLET_RESPONSIVE_BREAK}) {
    flex-direction: column;
  }
`

export const Block = styled.div`
  display: flex;
  flex-direction: column;
`

export const SocialNetworks = styled.div`
  display: flex;
  transform: all 0.3s ease;
  > a:hover {
    transform: scale(1.2);
  }
`

export const StyledLinks = styled.div`
  display: flex;
  flex-direction: column;
  > a {
    font-family: 'Red Hat Text';
    font-size: 12px;
    font-weight: 400;
    line-height: 1.3;
    margin-bottom: 10px;
    &:focus,
    &:hover {
      text-decoration: underline;
    }
    @media (max-width: ${TABLET_RESPONSIVE_BREAK}) {
      font-size: 15px;
    }
  }
`

export const LinksGroup = styled.div`
  display: flex;
  gap: 30px;
  margin-top: 15px;
  @media (max-width: ${TABLET_RESPONSIVE_BREAK}) {
    flex-direction: column;
  }
`

export const PopularLinks = styled.div`
  display: flex;
  gap: 30px;
  @media (max-width: ${TABLET_RESPONSIVE_BREAK}) {
    flex-direction: column;
    gap: 0px;
  }
`
