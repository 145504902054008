import { Box, HStack, useDisclosure } from '@chakra-ui/react'
import { ReactComponent as Hamburger } from 'assets/icons/gpi-icon-mobile-menu.svg'
import { ReactComponent as BrandLogoMap } from 'assets/icons/gpi-map-navbar-logo.svg'
import { ReactComponent as BrandLogo } from 'assets/images/gpi-navbar-logo.svg'
import { Drawer } from 'components/Drawer'
import Login from 'components/Login'
import Modal from 'goplaceit-components-library/lib/Modal'
import { SearchBar } from 'pages/MainMap/components/Filters/Filters.elements'
import React from 'react'
import { IoMdClose } from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { toggleModal } from 'store/actions/app/creators'
import { getResponsiveState } from 'store/selectors/app'
import { getModalVisibility } from 'store/selectors/modal'
import { getLoginStatus } from 'store/selectors/user'
import colorSchema from 'styles/color-schema'

import { FooterMenu, OptionsMenu, ResponsiveMenu, UserMenu } from './components'

export const Navbar = ({ isInMap = false, inputContainer = null }) => {
  const dispatch = useDispatch()
  const { push } = useHistory()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const loginStatus = useSelector(getLoginStatus)
  const isResponsive = useSelector(getResponsiveState)
  const isModalOpen = useSelector(getModalVisibility('LOGIN_MODAL'))

  const isLogged = loginStatus.loginStatus === 'AUTHENTICATED'
  const isLoading = Boolean(loginStatus.isLoading)

  const handleLoginModal = () => dispatch(toggleModal('LOGIN_MODAL'))

  if (isResponsive) {
    return (
      <nav style={{ zIndex: 9999, width: isOpen ? '80%' : '100%' }}>
        <HStack w={isOpen ? '80%' : 'full'} justify="space-between" p="0 20px" h="50px" gap="5px">
          {isInMap ? (
            <Box minW="30px" zIndex={1401}>
              <BrandLogoMap onClick={() => push('/')} style={{ cursor: 'pointer' }} />
            </Box>
          ) : (
            <BrandLogo onClick={() => push('/')} style={{ cursor: 'pointer' }} />
          )}
          {isInMap && inputContainer ? (
            <SearchBar>
              <div ref={inputContainer} />{' '}
            </SearchBar>
          ) : null}
          {!isOpen && (
            <Box minW="25px">
              <Hamburger size="25px" color={colorSchema.gpi} style={{ cursor: 'pointer' }} onClick={onOpen} />
            </Box>
          )}
        </HStack>
        <Drawer
          isOpen={isOpen}
          onClose={onClose}
          headerContent={
            <HStack w="full" justify="end">
              <IoMdClose size="25px" color={colorSchema.gpi} style={{ cursor: 'pointer' }} onClick={onClose} />
            </HStack>
          }
          bodycontent={<ResponsiveMenu isLogged={isLogged} handleLoginModal={handleLoginModal} onClose={onClose} />}
          footerContent={<FooterMenu />}
          bodyProps={{ padding: '0 20px' }}
        />
        <Modal isOpen={isModalOpen} closeModal={handleLoginModal} overlayBackground="black">
          <Login />
        </Modal>
      </nav>
    )
  }

  return (
    <nav style={{ zIndex: 1399 }}>
      <HStack w="full" justify="space-between" p="0 50px" h="50px" gap="20px">
        <Box minW="235px">
          <BrandLogo onClick={() => push('/')} style={{ cursor: 'pointer' }} />
        </Box>
        <OptionsMenu />
        <UserMenu isLogged={isLogged} isLoading={isLoading} handleLoginModal={handleLoginModal} />
      </HStack>
      <Modal isOpen={isModalOpen} closeModal={handleLoginModal} overlayBackground="black">
        <Login />
      </Modal>
    </nav>
  )
}
