import React, { createContext, useEffect, useRef } from 'react'
import ReactGA from 'react-ga'
import { useHistory, useLocation } from 'react-router-dom'

export const scriptsContext = createContext()

const ScriptInitializers = ({ children }) => {
  const prevPath = useRef(window.location.pathname)
  const location = useLocation()
  const history = useHistory()
  // Google analytics initializer
  useEffect(() => {
    ReactGA.initialize('UA-34121212-1')
    ReactGA.pageview(location.pathname + location.search)
    history.listen(location => {
      // if previous location has same pathname than current, do nothing
      if (prevPath.current === location.pathname) return
      // if navigated, update previous pathname
      prevPath.current = location.pathname
      // and register pageview to Google Analytics
      ReactGA.pageview(location.pathname + location.search)
    })
  }, []) //eslint-disable-line

  // Facebook initializer
  // Asynchronously download FB SDK
  useEffect(() => {
    if (document.getElementById('facebook-jssdk')) return
    let script = document.createElement('script')
    script.defer = true
    script.id = 'facebook-jssdk'
    script.src = `https://connect.facebook.net/en_US/sdk.js#appId=${process.env.REACT_APP_FB_APP_ID}&xfbml=true&version=v6.0&autoLogAppEvents=true`
    document.getElementsByTagName('head')[0].appendChild(script)
  }, [])

  return <scriptsContext.Provider value={{ ReactGA }}>{children}</scriptsContext.Provider>
}

export default ScriptInitializers
