import {
  Drawer as ChakraDrawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
} from '@chakra-ui/react'
import { isNil } from 'utils/helpers'

export const Drawer = ({
  isOpen,
  onClose,
  btnRef,
  isFullHeight = false,
  placement = 'right',
  size = 'md',
  closeOnOverlayClick = true,
  withOverlay = true,
  withCloseBtn = false,
  footerContent = undefined,
  bodycontent = undefined,
  headerContent = undefined,
  contentProps = undefined,
  bodyProps = {},
  ...props
}) => (
  <ChakraDrawer
    onEsc={onClose}
    isOpen={isOpen}
    placement={placement}
    onClose={onClose}
    finalFocusRef={btnRef}
    size={size}
    isFullHeight={isFullHeight}
    closeOnOverlayClick={closeOnOverlayClick}
    {...props}>
    {withOverlay && <DrawerOverlay />}
    <DrawerContent {...contentProps}>
      {withCloseBtn && <DrawerCloseButton />}
      {!isNil(headerContent) && <DrawerHeader>{headerContent}</DrawerHeader>}
      {!isNil(bodycontent) && <DrawerBody {...bodyProps}>{bodycontent}</DrawerBody>}
      {!isNil(footerContent) && <DrawerFooter>{footerContent}</DrawerFooter>}
    </DrawerContent>
  </ChakraDrawer>
)
