import styled from 'styled-components'

import { TABLET_RESPONSIVE_BREAK } from '../../utils/constants'

const FACEBOOK_COLOR = 'rgb(64, 99, 179)'
const FACEBOOK_HOVER_COLOR = '#355293'

export const StyledLogin = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-height: 80vh;
  overflow-y: auto;
  width: 400px;
  > svg {
    height: 70px;
    width: 180px;
  }

  form {
    width: 100%;
    > button {
      background-color: ${props =>
        props.backgroundColor ? props.backgroundColor : undefined};
      border-color: ${props =>
        props.borderColor ? props.borderColor : undefined};
      > div {
        transform: translateX(-50%);
        margin-left: 50%;
      }
    }
    .submit-btn {
      margin-top: 40px;
    }
  }

  > button {
    background-color: ${FACEBOOK_COLOR};
    border-color: ${FACEBOOK_COLOR};
    color: ${({ theme: { color } }) => color('artic')};
    &:focus,
    &:hover {
      background-color: ${FACEBOOK_HOVER_COLOR};
      border-color: ${FACEBOOK_HOVER_COLOR};
    }
  }

  > .gpi-login-modal-recover-password {
    font-size: 12px;
    font-weight: 700;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  @media (max-width: ${TABLET_RESPONSIVE_BREAK}) {
    width: 80vw;
  }
  @media (max-width: 768px) {
    width: 90vw;
  }
`
export const Subtitle = styled.p`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
  text-align: center;
`

export const SignInText = styled.p`
  font-size: 18px;
  margin: 0 0 40px 0;
  text-align: center;
  & span {
    text-decoration: underline;
    font-weight: 600;
    cursor: pointer;
    transition: color 0.3s ease;
    &:hover {
      color: ${({ theme: { color } }) => color('gpi')};
    }
  }
`

export const Label = styled.label`
  ${({ theme }) => theme.fontSize('small')};
  display: block;
  margin-bottom: 10px;
  padding-left: 21px;
`
export const TextField = styled.div`
  margin-bottom: 20px;
  > input {
    width: 100%;
    border-color: ${({ theme: { color } }) => color('siberia')};
  }
`
export const Separator = styled.div`
  width: 100%;
  height: 10px;
  background: transparent;
`

export const ErrorText = styled.p`
  margin: 0;
  text-align: center;
  text-style: italic;
  color: ${({ theme: { color } }) => color('gpi')};
`
