//According new UI KIT
const pink = '#FF9B99'
const salmon = '#ff4b50'
const salmonHover = '#EC393E'
const gpiHover = '#E30007'
const gpi = '#FC0444'
const darkGpi = '#CF1117'
const night = '#444242'
const nightSoft = '#385B64'
const notBlack = '#202020'
const notBlackMedium = '#353535'
const white = '#fff'
const artic = '#F8F8F9'
const siberia = '#E7E7E7'
const graphite = '#C4C4C4'
const graphite2 = '#AAA9AA'
const graphite3 = '#D9D9D9'
const wolf = '#7C7C7C'
const leaf = '#00AD5A'
const forest = '#008D49'
const pine = '#0d7355'
const sea = '#4482fc'
const seaMedium = '#006DE9'
const blue = '#3066d1'
const deepBlue = '#0163D2'
const zaphire = '#114cc0'
const allyViolet = '#6454C2'
//Old Colors
const purple = '#9534D1'
const navyBlue = '#114b5f'

const colorSchema = {
  deepBlue,
  allyViolet,
  artic,
  blue,
  darkGpi,
  forest,
  gpi,
  gpiHover,
  graphite,
  graphite2,
  graphite3,
  leaf,
  navyBlue,
  notBlack,
  notBlackMedium,
  night,
  nightSoft,
  pine,
  pink,
  purple,
  salmon,
  salmonHover,
  sea,
  seaMedium,
  siberia,
  white,
  wolf,
  zaphire,
}

export default colorSchema
