import { CLEAR_REDIRECTION, TOGGLE_MODAL } from '../actions/app/types'

const initialState = { isVisible: false, id: null, to: undefined }

const modal = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_MODAL:
      return {
        isVisible: !state.isVisible,
        id: action.id,
        to: action.to,
      }
    case CLEAR_REDIRECTION:
      return {
        isVisible: !state.isVisible,
        id: action.id,
        to: undefined,
      }
    default:
      return state
  }
}

export default modal
