import {
  CLEAR_NAVIGATION,
  COUNTRY_CHANGE_FROM_NAVBAR,
  getAllCurrenciesTypes,
  getRegionPolygonsTypes,
  SAVE_UTM,
  SET_COUNTRY_CODE,
  SET_IN_MAP_VIEW,
  SET_MOBILE_STATE,
  SET_RESPONSIVE_STATE,
  SET_WILL_PRINT,
  TOGGLE_NAVIGATION,
} from '../actions/app/types'

const [GET_ALL_CURRENCIES_REQUEST, GET_ALL_CURRENCIES_SUCCESS, GET_ALL_CURRENCIES_REJECT] = getAllCurrenciesTypes

const [GET_REGION_POLYGONS_REQUEST, GET_REGION_POLYGONS_SUCCESS, GET_REGION_POLYGONS_REJECT] = getRegionPolygonsTypes

const initialState = {
  GET_ALL_CURRENCIES_REQUEST: false,
  GET_ALL_CURRENCIES_SUCCESS: false,
  GET_ALL_CURRENCIES_REJECT: false,
  GET_REGION_POLYGONS_REQUEST: false,
  GET_REGION_POLYGONS_SUCCESS: false,
  GET_REGION_POLYGONS_REJECT: false,
  isResponsive: false,
  isMobile: false,
  willPrint: null,
  inMapView: false,
  country: null,
  currencies: [],
  regionPolygons: [],
  countrySelected: false,
  timestamp: null,
  utm: null,
}

const app = (state = initialState, action) => {
  switch (action.type) {
    case SET_RESPONSIVE_STATE:
      return {
        ...state,
        isResponsive: action.payload,
      }
    case SET_MOBILE_STATE:
      return {
        ...state,
        isMobile: action.payload,
      }
    case SET_WILL_PRINT:
      return {
        ...state,
        willPrint: action.id,
      }
    case SET_IN_MAP_VIEW:
      return {
        ...state,
        inMapView: action.payload,
      }
    case SET_COUNTRY_CODE:
      return {
        ...state,
        country: action.payload,
      }
    case COUNTRY_CHANGE_FROM_NAVBAR:
      return {
        ...state,
        countrySelected: action.payload,
      }
    case GET_ALL_CURRENCIES_REQUEST:
      return {
        ...state,
        [action.type]: true,
      }
    case GET_ALL_CURRENCIES_SUCCESS:
      return {
        ...state,
        GET_ALL_CURRENCIES_REQUEST: false,
        currencies: action.payload,
        timestamp: action.timestamp,
        [action.type]: true,
      }
    case GET_ALL_CURRENCIES_REJECT:
      return {
        ...state,
        GET_ALL_CURRENCIES_REQUEST: false,
        [action.type]: true,
      }
    case GET_REGION_POLYGONS_REQUEST:
      return {
        ...state,
        [action.type]: true,
      }
    case GET_REGION_POLYGONS_SUCCESS:
      return {
        ...state,
        GET_REGION_POLYGONS_REQUEST: false,
        regionPolygons: action.payload,
        [action.type]: true,
      }
    case GET_REGION_POLYGONS_REJECT:
      return {
        ...state,
        GET_REGION_POLYGONS_REQUEST: false,
        [action.type]: true,
      }
    case TOGGLE_NAVIGATION:
      return {
        ...state,
        navigateTo: action.payload,
      }
    case CLEAR_NAVIGATION:
      return {
        ...state,
        navigateTo: null,
      }
    case SAVE_UTM:
      return {
        ...state,
        utm: action.payload,
      }
    default:
      return state
  }
}

export default app
