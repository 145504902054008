import { useSelector } from 'react-redux'
import { countryCodeSelector } from 'store/selectors/app'

import commons from './commons'

const useTranslation = dictionary => {
  const jointDictionary = { ...commons, ...dictionary }
  const availableKeys = Object.keys(jointDictionary)
  const currentCountry = useSelector(countryCodeSelector) || 'cl'

  return (key, params) => {
    if (!availableKeys.includes(key)) {
      throw new Error(`Translation key does not exist in created dictionary. 
      Invalid key: '${key}'
      Available keys: ${availableKeys}
      `)
    }

    if (typeof jointDictionary[key] === 'function') {
      if (!params && process.env.NODE_ENV === 'development') {
        console.warn(
          'Warning, detected call to translation function using a key which corresponds to a function but no params were passed. This warning will show only in development environment'
        )
      }
      return jointDictionary[key](params)[currentCountry]
    }

    if (jointDictionary[key][currentCountry] === undefined) {
      throw new Error(`Translation key does not have entry for current country. 
      Key: '${key}'
      Current country: ${currentCountry}
      `)
    }

    return jointDictionary[key][currentCountry]
  }
}

export default useTranslation
