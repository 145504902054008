const sortType = {
  featured: 'Mejor información',
  newest: 'Más nuevas',
  older: 'Más antiguas',
  cheapest: 'Más económicas',
  costliest: 'Más costosas',
}

const propertyTypes = {
  1: 'casa',
  2: 'departamento',
  3: 'oficina',
  4: 'propiedad',
  5: 'terreno',
  6: 'estacionamiento',
  7: 'bodega',
}

const propertyTypesDev = {
  1: 'house',
  2: 'apartment',
  3: 'office',
  4: 'land',
  5: 'parking',
  6: 'storage',
}

const searchPropertyTypes = {
  1: 'casa',
  2: 'departamento',
  3: 'oficina',
  4: 'terreno',
  5: 'estacionamiento',
  6: 'bodega',
}

const propertyArticles = {
  1: 'de la',
  2: 'del',
  3: 'de la',
}

const countryCodeToName = {
  cl: 'Chile',
  mx: 'Mexico',
  ar: 'Argentina',
  pe: 'Perú',
  co: 'Colombia',
  ve: 'Venezuela',
  uy: 'Uruguay',
}

const abbreviatedPropertyTypes = {
  1: 'casa',
  2: 'depto.',
  3: 'oficina',
  4: 'terreno',
  5: 'estacionamiento',
  6: 'bodega',
}

const pluralAbbreviatedPropertyTypes = {
  1: 'casas',
  2: 'deptos.',
  3: 'oficinas',
  4: 'terrenos',
  5: 'estac.',
  6: 'bodegas',
}

const publicationTypes = {
  1: 'venta',
  2: 'arriendo',
}

const modeIdToCurrencyType = {
  1: 'saleCurrency',
  2: 'rentCurrency',
}

const countryIdToCode = {
  1: 'cl',
  2: 'mx',
  3: 'ar',
  4: 'pe',
  5: 'co',
  6: 've',
  7: 'uy',
}

const countryCodeToId = {
  cl: 1,
  mx: 2,
  ar: 3,
  pe: 4,
  co: 5,
  ve: 6,
  uy: 7,
}

const countryCodeToCurrencies = {
  cl: { rentCurrency: 1, saleCurrency: 2 },
  mx: { rentCurrency: 3, saleCurrency: 3 },
  ar: { rentCurrency: 4, saleCurrency: 7 },
  pe: { rentCurrency: 5, saleCurrency: 5 },
  co: { rentCurrency: 6, saleCurrency: 6 },
  ve: { rentCurrency: 8, saleCurrency: 8 },
  uy: { rentCurrency: 9, saleCurrency: 9 },
}

const countryCodeToPublicationCurrencies = {
  cl: [
    ['1', { label: 'CLP', symbol: '$' }],
    ['2', { label: 'UF', symbol: 'UF' }],
  ],
  mx: [
    ['3', { label: 'MXN', symbol: '$' }],
    ['7', { label: 'USD', symbol: '$' }],
  ],
  ar: [
    ['4', { label: 'ARS', symbol: '$' }],
    ['7', { label: 'USD', symbol: '$' }],
  ],
  pe: [
    ['5', { label: 'PEN', symbol: 'S/' }],
    ['7', { label: 'USD', symbol: '$' }],
  ],
  co: [
    ['6', { label: 'COP', symbol: '$' }],
    ['7', { label: 'USD', symbol: '$' }],
  ],
  ve: [
    ['8', { label: 'VEF', symbol: 'Bs.' }],
    ['7', { label: 'USD', symbol: '$' }],
  ],
  uy: [
    ['9', { label: 'UYU', symbol: '$' }],
    ['7', { label: 'USD', symbol: '$' }],
  ],
}

const placeTypeToZoomLevel = {
  region: 9,
  postcode: 10,
  district: 11,
  place: 12,
  locality: 14,
  neighborhood: 15,
  address: 16,
  poi: 17,
}

const miniSiteFiltersLabels = (t, capitalize) => ({
  region: 'Región',
  commune: `${capitalize(t('comuna'))}`,
  type: `Tipo de ${t('propiedad')}`,
  mode: 'Modalidad',
  sort: 'Orden',
  from_price: 'Precio',
  to_price: 'Precio',
  from_to_price: 'Precio',
  from_price_sell: 'Precio',
  to_price_sell: 'Precio',
  from_to_price_sell: 'Precio',
  banos_desde: 'Baños desde',
  habitaciones_desde: 'Habitaciones desde',
  from_area: `m2 útiles`,
  to_area: `m2 útiles`,
  from_to_area: `m2 útiles`,
  from_area_total: `m2 totales`,
  to_area_total: `m2 totales`,
  from_to_area_total: `m2 totales`,
  from_parking: 'Estacionamientos desde',
})

const serviceIdToFileName = {
  1: 'ic_gasolinera',
  2: 'ic_colegio',
  3: 'ic_universidad',
  4: 'ic_farmacia',
  5: 'ic_hospital',
  6: 'ic_supermercado',
  7: 'ic_multitienda',
  8: 'ic_minimarket',
  9: 'ic_metro',
  10: 'ic_paradero',
  11: 'ic_bar',
  12: 'ic_restaurant',
  13: 'ic_cine',
  14: 'ic_carabineros',
  15: 'ic_bomberos',
  16: 'ic_iglesia',
  17: 'ic_banco',
  18: 'ic_ferreteria',
}

const serviceIdsToIconName = {
  1: 'fuelStation',
  2: 'closeToSchool',
  3: 'university',
  4: 'pharmacy',
  5: 'hospital',
  6: 'supermarket',
  7: 'multiStore',
  8: 'closeToCommerces',
  9: 'metro',
  10: 'closeToTransport',
  11: 'bar',
  12: 'restaurant',
  13: 'cinema',
  14: 'police',
  15: 'alarm',
  16: 'church',
  17: 'bank',
  18: 'hardwareStore',
}

const featureIdToIconName = {
  1: 'greenAreas',
  2: 'balcony',
  3: 'skylight',
  4: 'fireplace',
  5: 'thermowindow',
  6: 'serviceRoom',
  7: 'serviceBathroom',
  8: 'heater',
  9: 'closeToMainStreet',
  10: 'closeToTransport',
  11: 'elevator',
  12: 'closeToSchool',
  13: 'closeToCommerces',
  14: 'greenAreas',
  15: 'alarm',
  16: 'multiroom',
  17: 'pool',
  18: 'sauna',
  19: 'jacuzzi',
  20: 'poolTable',
  21: 'barbecue',
  22: 'gym',
  23: 'security',
  24: 'condominium',
}

const featureIdToLabel = {
  1: 'Patio / Áreas verdes comunes',
  2: 'Balcón / terraza',
  3: 'Lucarna / claraboya',
  4: 'Chimenea',
  5: 'Ventanas termopanel',
  6: 'Cuarto de servicio',
  7: 'Baño de servicio',
  8: 'Calefacción central',
  9: 'Cercano a vía principal',
  10: 'Transporte urbano cercano',
  11: 'Elevador / ascensor',
  12: 'Cercano a colegios',
  13: 'Cercano a comercios',
  14: 'Cercano a áreas verdes',
  15: 'Sistema de alarma',
  16: 'Sala de usos múltiples',
  17: 'Piscina',
  18: 'Sauna',
  19: 'Jacuzzi',
  20: 'Mesa de pool',
  21: 'Quincho / parrilla',
  22: 'Gimnasio',
  23: 'Seguridad 24 horas',
  24: 'Condominio',
}

const nonFeatureKeyToIconName = {
  byOwner: 'owner',
  parkings: 'parking',
  storage: 'storage',
  withFurniture: 'withFurniture',
  floor: 'building',
  orientation: 'aorientation',
  year: 'elevator',
}

const polygonTypeToId = {
  POLYGON: 1,
  CIRCLE: 2,
  RECTANGLE: 3,
}

const drawModeToPolygonType = {
  draw_polygon: 'polygon',
  draw_circle: 'circle',
  draw_freehand: 'polygon',
  draw_rectangle: 'rectangle',
}

const countryCodeToCapitalCoordinates = {
  cl: { lat: -33.437889, lng: -70.650533 },
  mx: { lat: 19.432613, lng: -99.133239 },
  ar: { lat: -34.603748, lng: -58.381624 },
  pe: { lat: -12.046042, lng: -77.030625 },
  co: { lat: 4.711111, lng: -74.072222 },
  ve: { lat: 10.4683612, lng: -67.0304536 },
  uy: { lat: -34.821018, lng: -56.3765244 },
}

const countryCodeToCapitalNames = {
  cl: 'Santiago',
  mx: 'Ciudad de México',
  ar: 'Buenos Aires',
  pe: 'Lima',
  co: 'Bogotá',
  ve: 'Caracas',
  uy: 'Montevideo',
}

const locationTypesToRequestType = {
  cl: {
    region: 'region',
    provincia: 'province',
    comuna: 'commune',
    barrio: 'neighborhood',
    propiedad: 'property',
  },
  mx: {
    estado: 'region',
    municipio: 'province',
    colonia: 'commune',
    barrio: 'neighborhood',
    inmueble: 'property',
  },
  ar: {
    provincia: 'region',
    departamento: 'province',
    localidad: 'commune',
    barrio: 'neighborhood',
    propiedad: 'property',
  },
  pe: {
    region: 'region',
    provincia: 'province',
    distrito: 'commune',
    urbanizacion: 'neighborhood',
    inmueble: 'property',
  },
  co: {
    departamento: 'region',
    municipio: 'province',
    localidad: 'commune',
    zona: 'neighborhood',
    inmueble: 'property',
  },
  ve: {
    estado: 'region',
    municipio: 'province',
    parroquia: 'commune',
    barrio: 'neighborhood',
    inmueble: 'property',
  },
  uy: {
    departamento: 'region',
    ciudad: 'province',
    colonia: 'commune',
    zona: 'neighborhood',
    propiedad: 'property',
  },
}

const locationTypesToPlurals = {
  cl: {
    regiones: 'region',
    provincias: 'province',
    comunas: 'commune',
    barrios: 'neighborhood',
    propiedades: 'property',
  },
  mx: {
    estados: 'region',
    municipios: 'province',
    colonias: 'commune',
    barrios: 'neighborhood',
    inmuebles: 'property',
  },
  ar: {
    provincias: 'region',
    departamentos: 'province',
    localidades: 'commune',
    barrios: 'neighborhood',
    propiedades: 'property',
  },
  pe: {
    regiones: 'region',
    provincias: 'province',
    distritos: 'commune',
    urbanizaciones: 'neighborhood',
    inmuebles: 'property',
  },
  co: {
    departamentos: 'region',
    municipios: 'province',
    localidades: 'commune',
    zonas: 'neighborhood',
    inmuebles: 'property',
  },
  ve: {
    estados: 'region',
    municipios: 'province',
    parroquias: 'commune',
    barrios: 'neighborhood',
    inmuebles: 'property',
  },
  uy: {
    departamentos: 'region',
    ciudades: 'province',
    colonias: 'commune',
    zonas: 'neighborhood',
    propiedades: 'property',
  },
}

const invertDictionary = dict => {
  const keys = Object.keys(dict)
  const result = {}
  keys.forEach(k => {
    result[dict[k]] = k
  })
  return result
}

// PUBLICATION
module.exports = {
  propertyTypesDev,
  abbreviatedPropertyTypes,
  countryCodeToCapitalCoordinates,
  countryCodeToCapitalNames,
  countryCodeToCurrencies,
  countryCodeToId,
  countryCodeToPublicationCurrencies,
  countryIdToCode,
  drawModeToPolygonType,
  featureIdToIconName,
  featureIdToLabel,
  countryCodeToName,
  invertDictionary,
  locationTypesToPlurals,
  locationTypesToRequestType,
  miniSiteFiltersLabels,
  modeIdToCurrencyType,
  nonFeatureKeyToIconName,
  placeTypeToZoomLevel,
  polygonTypeToId,
  propertyArticles,
  propertyTypes,
  searchPropertyTypes,
  publicationTypes,
  serviceIdToFileName,
  serviceIdsToIconName,
  pluralAbbreviatedPropertyTypes,
  sortType,
}
