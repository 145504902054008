import { RESET_PUBLICATION_FORM, SET_SLICE } from '../../actions/publication/types'

export const slicesInitialState = {
  lastButtonPressed: '',
  publicationType: {
    type: '',
  },
  propertyType: {
    type: '',
  },
  contactInfo: {
    name: '',
    email: '',
    phone: '',
    useProfileData: true,
  },
  location: {
    address: '',
    by: undefined,
    region: 'unset',
    commune: 'unset',
    communeName: '',
    province: 'unset',
    neighborhood: 'unset',
    rol: '',
    validROL: false,
  },
  description: {
    title: '',
    description: '',
  },
  features: [],
  priceInfo: {
    currency: undefined,
    contributionsCurrency: undefined,
    price: '',
    commonExpenses: '',
    contributions: '',
  },
  propertyInfo: {
    apartmentType: '',
    condo: undefined,
    rooms: 0,
    bathrooms: 0,
    parkingSpaces: 0,
    storageRoom: 0,
    usableSize: '',
    totalSize: '',
    videoUrl: '',
    matterport: '',
  },
  generalInfo: {
    floor: '',
    facingTowards: [],
    builtAt: '',
    totalFloors: '',
    kitchenType: '',
    kitchenId: null,
  },
  photos: [],
}

const formSlices = (state = slicesInitialState, action) => {
  switch (action.type) {
    case SET_SLICE:
      return { ...state, ...action.payload }
    case RESET_PUBLICATION_FORM:
      return slicesInitialState
    default:
      return state
  }
}

export default formSlices
