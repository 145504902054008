import { Avatar, Box, Button, Popover, PopoverContent, PopoverTrigger, Text, VStack } from '@chakra-ui/react'
import Link from 'components/PrefixedLink'
import { Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { signOutUser } from 'store/actions/user/creators'
import { getUserAvatar, getUserEmail, getUserName } from 'store/selectors/user'
import colorSchema from 'styles/color-schema'

export const Item = ({ label, action, to }) => {
  if (to) {
    return (
      <Link to={to} style={{ marginTop: 0, width: '100%' }}>
        <Text
          fontSize="15px"
          lineHeight="48px"
          as="button"
          textAlign="left"
          p="4px 8px"
          borderRadius="16px"
          w="full"
          _hover={{
            bg: colorSchema.artic,
          }}>
          {label}
        </Text>
      </Link>
    )
  }
  return (
    <Text
      fontSize="15px"
      lineHeight="48px"
      as="button"
      onClick={action}
      textAlign="left"
      p="4px 8px"
      borderRadius="16px"
      w="full"
      _hover={{
        bg: colorSchema.artic,
      }}>
      {label}
    </Text>
  )
}

export const LoggedMenu = () => {
  const dispatch = useDispatch()

  const avatar = useSelector(getUserAvatar)
  const userName = useSelector(getUserName)
  const email = useSelector(getUserEmail)

  const firstName = userName.split(' ')[0]

  const options = [
    { label: 'Mi cuenta', to: '/perfil' },
    {
      label: 'Mis zonas',
      to: {
        pathname: '/mapa',
        state: {
          actionType: 'selectUserFirstZone',
          referrer: window.location.href,
        },
        search: '',
      },
    },
    { label: 'Propiedades favoritas', to: '/perfil/mis-favoritos' },
    { label: 'Cerrar sesión', action: () => dispatch(signOutUser()) },
  ]

  return (
    <Popover variant="navbar">
      <PopoverTrigger>
        <Button
          variant="unstyled"
          borderWidth={1}
          borderColor={colorSchema.gpi}
          borderRadius="30px"
          h="30px"
          minW="165px"
          bg={colorSchema.artic}
          _focus={{ outline: 'none' }}>
          <Avatar
            name={userName}
            boxSize="30px"
            src={avatar}
            borderWidth={1}
            borderColor={colorSchema.gpi}
            pos="absolute"
            top="-1px"
            left="-1px"
          />
          <Text
            textAlign="left"
            pl="45px"
            fontFamily="Poppins"
            fontSize="12px"
            lineHeight="12px"
            fontWeight="700"
            color={colorSchema.gpi}>
            {`Hola, ${firstName}`}
          </Text>
        </Button>
      </PopoverTrigger>
      <PopoverContent w="max-content">
        <VStack align="flex-start" gap="8px">
          <Box>
            <Text fontSize="15px" fontWeight="700" lineHeight="20px">{`Hola, ${firstName}`}</Text>
            <Text fontSize="12px" lineHeight="15px">
              {email}
            </Text>
          </Box>
          {options.map(({ label, to, action }) => (
            <Fragment key={label}>
              <Item key={label} label={label} to={to} action={action} />
            </Fragment>
          ))}
        </VStack>
      </PopoverContent>
    </Popover>
  )
}
