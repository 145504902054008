import { combineReducers } from 'redux'

import { SIGN_OUT_USER } from '../actions/user/types'
import app from './app'
import contact from './contact'
import inversion from './inversion'
import landing from './landing'
import locationLanding from './locationLanding'
import mainMap from './mainMap'
import miniSite from './miniSite'
import modal from './modal'
import project from './project'
import property from './property'
import publication from './publication'
import user from './user'

const appReducer = combineReducers({
  app,
  contact,
  landing,
  locationLanding,
  mainMap,
  miniSite,
  modal,
  project,
  property,
  publication,
  inversion,
  user,
})

const rootReducer = (state, action) => {
  if (action.type === SIGN_OUT_USER) {
    state = undefined
  }

  return appReducer(state, action)
}

export default rootReducer
